import Repository from '@/api/core'
import utils from '@/helpers/utils.js'
const baseURL = process.env.VUE_APP_API_PAYMENT + '/bill'

const fetch = params => {
	return Repository(baseURL).fetch({ ...utils.cleanObject(params) })
}

const fetchOne = id => {
	return Repository(baseURL).fetchOne(id)
}

const create = params => {
	return Repository(baseURL).create(params)
}

const update = (id, params) => {
	return Repository(baseURL).update(id, params)
}

const remove = id => {
	return Repository(baseURL).delete(id)
}

const count = params => {
	return Repository(baseURL + '/count').fetch({ ...utils.cleanObject(params) })
}

const approve = (id, option) => {
	return Repository(baseURL + '/approve').update(id, {}, option)
}

const statistic = params => {
	return Repository(baseURL + '/statistic').fetch({ ...params })
}

const report = params => {
	return Repository(baseURL + '/report').fetch({ ...params })
}


export default {
	fetch,
	fetchOne,
	create,
	update,
	remove,
	count,
	approve,
	statistic,
	report
}
