<template>
	<div class="d-flex">
		<BaseInput
			style="max-width: 360px"
			prepend-inner-icon="mdi-magnify"
			v-model="textSearch"
			:label="label"
			:placeholder="placeholder"
			@keyup.native.enter="$emit('onSearch', textSearch)"
		/>
		<BaseButton
			class="ml-3"
			icon="mdi-magnify"
			style="margin-top: 2px"
			@click="$emit('onSearch', textSearch)"
			:text="buttonText || $vuetify.lang.t('$vuetify.BUTTON_SEARCH')"
		/>
	</div>
</template>
<script>
export default {
	props: {
		value: String,
		label: String,
		placeholder: String,
		buttonText: {
			type: String
		}
	},
	data() {
		return {
			textSearch: ''
		}
	}
}
</script>
