<template>
	<div class="d-flex align-center">
		<BaseUploadDragger
			class="cursor-pointer full-width"
			:type="type"
			:maxFilesize="maxFilesize"
			@onUploadSuccess="data => onUploadSuccess(data)"
		>
			<div class="wrapper">
				<span class="select-file text-body-2">
					{{ $vuetify.lang.t('$vuetify.COURSE.LABEL_SELECT_FILE') }}
				</span>
				<span class="file-name" ref="file-name">
					<BaseFileName :name="name" v-if="name" />
					<span v-else style="opacity: 0.6" class="text-body-2">{{
						placeholder
					}}</span>
				</span>
			</div>
		</BaseUploadDragger>
		<v-tooltip color="grey darken-3" top v-if="isDownloadAllowed && isBtnDownloadShown">
			<template v-slot:activator="{on, attrs}">
				<v-btn icon outlined v-bind="attrs" v-on="on" class="ml-2 action-hover rounded" @click="onDownloadFile">
					<v-icon>mdi-download</v-icon>
				</v-btn>
			</template>
			<span>{{$vuetify.lang.t('$vuetify.COURSE.BUTTON_DOWNLOAD')}}</span>
		</v-tooltip>
	</div>
</template>
<script>
import get from 'lodash-es/get'
export default {
	data() {
		return {
			name: '',
			isBtnDownloadShown: false
		}
	},
	props: {
		maxFilesize: {
			type: Number,
			default: 3750
		},
		type: String,
		fileName: {
			type: String,
			default: ''
		},
		placeholder: {
			type: String,
			default: ''
		},
		file: {
			type: Object
		},
		isDownloadAllowed: {
			type: Boolean,
			default: false,
		}
	},
	methods: {
		onUploadSuccess(data) {
			this.$emit('onUploadSuccess', data)
		},
		onDownloadFile() {
			let fileLink = get(this.file, 'link', '')
			if (typeof fileLink === 'string' && fileLink.length) {
				fileLink = this.$utils.convertURL(this.$utils.combineLinkWithBucket(fileLink))
				window.open(fileLink, '_blank', 'noopener noreferrer')
			} else {
				this.$message.error(this.$vuetify.lang.t('$vuetify.MSG_NOTICATION_NOT_DOWNLOAD_FILE'))
			}
		}
	},
	watch: {
		fileName: {
			handler(val) {
				this.name = val
			},
			immediate: true
		},
		file: {
			handler(val) {
				if (val && (val._id || val.id)) {
					this.isBtnDownloadShown = true
				} else {
					this.isBtnDownloadShown = false
				}
			},
			immediate: true,
			deep: true
		}
	}
}
</script>
<style lang="scss" scoped>
.wrapper {
	width: 100%;
	display: flex;
	border: 1px solid #dddddd;
	border-radius: 4px;
}
.select-file {
	padding: 8px 12px;
	border-radius: 4px;
	background: #eeeeee;
	border-right: 1px solid #dddddd;
}
.file-name {
	padding: 8px 12px;
	flex: 1;
	width: 1px;
}
</style>
