const AUTH_ROUTES={
  TITLE:"Đăng nhập",
}
const HIDDENT_ROUTES={
  TITLE:"Trang quản lý",
  LABEL_EVENT_DETAIL:"Chi tiết sự kiện",
  LABEL_EBOOK_DETAIL:"Chi tiết sách điện tử",
  LABEL_TOPIC_DEAIL:"Chi tiết danh mục thể loại",
  LABEL_DEPARTMENT_DETAIL:"Chi tiết cơ sở",
  LABEL_MANAGE_ZOOM:"Quản lý phòng học Zoom",
  LABEL_CREATE_ZOOM:"Tạo phòng học Zoom",
  LABEL_AFFILIATE_DETAIL:"Trang chi tiết Affiliate",
  LABEL_CREATE_TEACHER: "Tạo giảng viên mới",
  LABEL_CREATE_STUDENT:"Tạo học viên mới",
  LABEL_TEACHER_DETAIL: "Chi tiết giảng viên",
  LABEL_STUDENT_DETAIL:"Chi tiết học viên",
  LABEL_ACTIVE_CODE_DETAIL:"Chi tiết mã kích hoạt",
  LABEL_ACTIVE_CODE_CREATE:"Tạo mới mã kích hoạt",
  LABEL_COUPON_DETAIL:"Chi tiết coupon",
  LABEL_CREATE_COUPON:"Tạo mới coupon",
  LABEL_COMBO_DETAIL:"Chi tiết Combo",
  LABEL_BLOG_DETAIL:"Chi tiết bài viết",
  LABEL_GROUP_STUDENT_DETAILL:"Chi tiết nhóm học viên",
  LABEL_CREATE_CETIFICATE: "Tạo Chứng chỉ",
  LABEL_CETIFICATE_DETAIL: "Chi tiết chứng chỉ",
  LABEL_COURSE_PROGRESS_DETAIL: "Chi tiết báo cáo tiến độ",
  LABEL_LEARNING_PATH_DETAIL:"Chi tiết lộ trình",
}
const NAVIGATION_ROUTES={
  //COURSE_CONTENT
  COURSE_CONTENT:{
    TITLE:"Nội dung khoá học",
    LINK_TO_ONLINE_COURSE_LIST:"Khoá học Online",
    LINK_TO_OFFLINE_COURSE_LIST:"Khoá học Offline",
    LINK_TO_LEARNING_PATH_LIST:"Lộ trình học",
    LINK_TO_CERTIFICATE_LIST:"Chứng chỉ",
    LINK_TO_EVENT_LIST:"Sự kiện",
    LINK_TO_EBOOK_LIST:"Sách điện tử",
    LINK_TO_TOPIC_LIST:"Danh mục - Thể loại",
    LINK_TO_FILE_STORAGE_LIST:"Tập tin lưu trữ",
    LINK_TO_QUESTION_BANK_LIST:"Ngân hàng câu hỏi",
    LINK_TO_STORE_EXAM_QUESTION_LIST:"Kho đề thi",
    LINK_TO_DEPARTMENT_LIST:"Cơ sở",
    LINK_TO_ASSIGNED_HOMEWORK_LIST:"Bài kiểm tra được phân công",
  },
  PHYSICAL_PRODUCT: {
    TITLE: "Sản phẩm khác",
    LINK_TO_PRODUCT_LIST: "Sản phẩm vật lý",
    LINK_TO_PRODUCT_CATEGORY_LIST: "Ngành hàng"
  },
  //USER_MANAGE
  USER_MANAGE:{
    TITLE:"Học sinh - Người dùng",
    LINK_TO_STUDENT_LIST:"Học viên",
    LINK_TO_STUDENT_GROUP_LIST:"Nhóm học viên",
    LINK_TO_TEACHER_LIST:"Giảng viên",
    LINK_TO_COMMENT_LIST:"Thảo luận",
    LINK_TO_RATING_LIST:"Đánh giá",
  },
  //FINANCE_MANAGE
  FINANCE_MANAGE:{
    TITLE:"Quản lí tài chính",
    LINK_TO_ACTIVATION_CODE_CAMPAIGN_LIST:"Mã kích hoạt",
    LINK_TO_COUPON_CAMPAIGN_LIST:"Coupon",
    LINK_TO_COMBO_CAMPAIGN_LIST:"Combo",
    LINK_TO_BILL_MANAGE_LIST:"Quản lí đơn hàng",
    LINK_TO_SALES_LIST:"Doanh số",
    LINK_TO_SALES_TEACHER_LIST:"Doanh số giáo viên",
    LINK_TO_CONTACT_LIST:"Contact",
    LINK_TO_AFFILIATE_LIST:"Affiliate",
  },
  //WEBSITE_DISPLAY
  WEBSITE_DISPLAY:{
    TITLE:"Giao diện website",
    LINK_TO_MENU_SETTING:"Cài đặt menu",
    LINK_TO_SITE_BUILD:"Cấu hình trang",
    LINK_TO_BLOG_LIST:"Tin tức - Blog",
  },
  //APP_DISPLAY
  APP_DISPLAY:{
    TITLE:"Giao diện app",
    LINK_TO_SCREEN_BIULD:"Cấu hình màn hình",
  },
  //SESTING_SYSTEM
  SESTING_SYSTEM:{
    TITLE:"Cài đặt chung",
    LINK_TO_BASIC_SETTING:"Cài đặt cơ bản",
    LINK_TO_ADVANCED_SETTING:"Cài đặt nâng cao",
  },
  //MY_ACCOUNT
  MY_ACCOUNT:{
    TITLE:"Tài khoản",
    LINK_TO_CHANGE_PASSWORD_SETTING:"Đổi mật khẩu",
    LINK_TO_CONTACT_INFO_SETTING:"Thông tin liên hệ"
  },
  SIGN_OUT:"Đăng xuất",
}
const HIDDEN_WITHOUT_MENU_ROUTES={
  LABEL_ONLINE_COURSE_DETAIL:"Khóa Học Online Chi Tiết",
  LABEL_OFFLINE_COURSE_DETAIL:"Khóa Học Offline Chi Tiết",
  LABEL_TESTING: "Testing",
  LABEL_ASSIGNED_HOMEWORK_DETAIL:"Chi tiết bài kiểm tra",
}
export default{
  AUTH_ROUTES,
  HIDDENT_ROUTES,
  HIDDEN_WITHOUT_MENU_ROUTES,
  NAVIGATION_ROUTES,
  AVATAR:"Ảnh đại diện trên thẻ",
  MAX:"Tối đa",
  KEY_WORD:"Kí tự",
  EVALUETE_OF_STUDENT:"Đánh giá của học sinh",
  HERE:"Tại đây",

  BASIC:"Cơ bản",
  BACKGROUND:"Ảnh nền trong trang chi tiết",

  COURSE:"Khoá học",
  CREATED_LIST:"Tạo thêm khoá học",
  CHOOSE_COURSE:"Chọn khoá học",
  COURSE_NOT_SETTING_AUTO:"Khoá học chưa cài đặt tự động tính thời gian",

  DISCRIPTION_SHORT:"Mô tả ngắn trên thẻ",
  DISCRIPTION_DETAIL:"Mô tả chi tiết",
  DISCRIPTION_DETAIL_HINT:"Hiển thị thông tin trong trang chi tiết về lộ trình",

  ROUTE_NAME:"Tên lộ trình",
  PLACEHOLDER_ROUTE_NAME:"Tìm kiếm theo tên lộ trình",
  ROUTE_LIST:"Danh sách lộ trình",

  INFORMATION:"Thông tin",
  INFO_BASIC:"Thông tin cơ bản",
  INFO_BASIC_HINT:"Thiết lập thông tin chung nhất cho lộ trình",
  
  LEVEL_DIFFICUL:"Mức độ khó",
  LIST:"Danh mục",
  LISH_COURSE:"Danh sách khoá học",

  TIME:"Thời lượng",
  TIME_COURSE:"Thời lượng khoá học",
  TIME_HINT:"Thiết lâp mô tả thời lượng khoá học",
  
  PICK_AUTO_ENTER:"Tự nhập mô tả",
  PICK_ALLOW_SYSTEM:"Cho phép hệ thông tự tính toán dựa trên các khoá học",
  PICK_ALLOW_USE_CERTIFICATE:"Cho phép sử dụng chứng chỉ cho lộ trình học",
  PICK_ALLOW_USE_CERTIFICATE_HINT:"Bạn đã có chứng chỉ cho lộ trình học này. Xem chi tiết chứng chỉ",
  PICK_ALLOW_STUDENT_EVALUATE:"Cho phép học sinh đánh giá và hiển thị đánh giá tương ứng với lộ trình",
  PICK_OBLIGATORY_BROW_EVALUATE:"Bắt buộc duyệt đánh giá trước khi được đăng công khai trên trang",
  PUBLISH:"Xuất bản",
  PUBLISH_ROUTE_STUDY:"Xuất bản lộ trình học",
  PUBLISH_ROUTE_STUDY_HINT:"Lộ trình này sẽ được công khai trên trang web và có thể được tìm thấy trên các thanh công cụ tìm kiếm.",
  PUBLISHED:"Đã xuất bản",
  
  NOTE:"Lưu ý",
  NOTE_HINT:"Các khóa học chưa cài đặt tự động tính thời lượng sẽ không được tính vào tổng thời lượng của lộ trình.",

  SETTING_CERTIFICATE:"Chứng chỉ",
  SETTING_CERTIFICATE_HINT:"Sử dụng chứng chỉ cho lộ trình học. Chứng chỉ sẽ được cấp khi học sinh hoàn thành lộ trình học.",
  STOP_PUBLISH:"Ngừng xuất bản",

  REMOVE_ROUTE_STUDY:"Xoá lộ trình học",
  REMOVE_ROUTE_STUDY_HINT:"Lộ trình học bị xóa sẽ không thể khôi phục",
  REMOVE_ROUTE_STUDY_OUT_SYSTEM:" Xoá lộ trình học khỏi hệ thống ",
  REMOVE_ROUTE_STUDY_OUT_SYSTEM_HINT:"Dữ liệu sau khi xóa không thể khôi phục",

  ENTER_SEARCH:"Nhập để tìm kiếm",

  TITLE_EXAM_ASSIGNED:"Bài kiểm tra được phân công",
  TITLE_SALES_TEACHER:"Doanh số giáo viên",

  TITLE_ONLINE_COURSE_DETAIL:"Khóa Học Online Chi Tiết",
  TITLE_MARK_MULTIPLE_CHOICE:"Điểm kiểm tra trắc nghiệm",
  TITLE_EXERCISE:"Bài tập",
  TITLE_STUDY_REPORT:"Báo cáo học tập",
  TITLE_ONLINE_COURSE:"Khoá học OnLine",
  TITLE_COURSE_CONTENT:"Nội dung khoá học",
  TITLE_FINANCIAL_MANAGEMENT:"Quản lí tài chính",
  TITLE_AFFILIATE_DETAIL:"Trang chi tiết Affiliate",
}