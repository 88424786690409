<template>
	<div class="navbar-spacing pa-5">
		<EditSiteInfo
			v-if="selected === 'common'"
			:viewData="viewData"
			ref="common"
		/>
		<EditPageInfo v-else :viewData="viewData" :ref="`${selected}`" :key="selected" />
	</div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import differenceWith from 'lodash-es/differenceWith'
import isEqual from 'lodash-es/isEqual'
import clone from 'lodash-es/clone'
import EditPageInfo from '@/modules/SiteBuilder/components/EditPageInfo'
import EditSiteInfo from '@/modules/SiteBuilder/components/EditSiteInfo'
export default {
	props: {
		selected: String,
		reloadIframe: Function,
	},
	computed: {
		...mapGetters({
			viewHomePage: 'vendor/getViewHomepage',
			viewAboutUs: 'vendor/getViewAboutus',
			viewPrivacyPolicy: 'vendor/getViewPrivacyPolicy',
			viewRecruitment: 'vendor/getViewRecruitment',
			themeData: 'vendor/getThemedata',
			getMessageError: 'sitebuilder/getMessageError'
		}),
	},
	components: {
		EditSiteInfo,
		EditPageInfo,
	},
	data() {
		return {
			viewData: null,
		}
	},
	methods: {
		...mapActions({
			updateSetting: 'vendor/updateSettingsVendor',
		}),
		setViewData() {
			if (this.selected === 'homepage') {
				this.viewData = clone(this.viewHomePage, true)
			} else if (this.selected === 'about-us') {
				this.viewData = clone(this.viewAboutUs, true)
			} else if (this.selected === 'privacy-policy') {
				this.viewData = clone(this.viewPrivacyPolicy, true)
			} else if (this.selected === 'recruitment') {
				this.viewData = clone(this.viewRecruitment, true)
			} else if (this.selected === 'common') {
				this.viewData = clone(this.themeData, true)
			}
		},
		getCurrentViewData() {
			if (this.selected === 'homepage') {
				return this.viewHomePage
			} else if (this.selected === 'about-us') {
				return this.viewAboutUs
			} else if (this.selected === 'privacy-policy') {
				return this.viewPrivacyPolicy
			} else if (this.selected === 'recruitment') {
				return this.viewRecruitment
			} else if (this.selected === 'common') {
				return this.themeData
			}
		},
		async checkChangeData() {
			const newData = await this.$refs[`${this.selected}`].getData()
			const oldData = await this.getCurrentViewData()
			const diff = await differenceWith(newData, oldData, isEqual)
			if (!this.viewData || !diff.length) {
				return false
			} else {
				return true
			}
		},
		async onSave() {
			const data = await this.$refs[`${this.selected}`].getData()
			let payload = {}
			if (this.selected === 'homepage') {
				payload = {viewData: data}
			} else if (this.selected === 'about-us') {
				payload = {aboutMe: data}
			} else if (this.selected === 'privacy-policy') {
				payload = {privacyPolicy: data}
			} else if (this.selected === 'recruitment') {
				payload = {recruitment: data}
			} else if (this.selected === 'common') {
				payload = {themeData: data}
			}
			try {
				if(this.getMessageError.length === 0){
					await this.updateSetting(payload)
					this.$message.success(this.$vuetify.lang.t('$vuetify.SITE_BUILDER.MSG_UPDATE_SUCCESS'))
					this.reloadIframe()
				}else{
					this.$message.error(this.getMessageError);
				}
			} catch (error) {
				this.$message.error(error)
			}
		},
	},
	watch: {
		selected: {
			handler() {
				this.setViewData()
			},
			immediate: true,
		},
	},
}
</script>
<style lang="scss" scoped>
.navbar-spacing {
	margin-top: 72px;
}
</style>