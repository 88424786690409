import Vue from 'vue'
import { sync } from 'vuex-router-sync'
import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import global from '@/global/index.js'
import helpers from '@/helpers/index.js'
import pluginHelper from '@/helpers/pluginHelper.js'
import utils from '@/helpers/utils.js'
import vuetify from '@/plugins/vuetify'
import message from '@/plugins/message'
import confirm from '@/plugins/confirm'
import confirmSupport from '@/plugins/confirmSupport'
import inputRules from '@/plugins/inputRules'
import sitebuilder from '@/modules/SiteBuilder/index.js'
import screenbuilder from '@/modules/ScreenBuilder/index.js'
import '@/registerServiceWorker'
import '@/assets/scss/main.scss'
import VueKonva from 'vue-konva'
import Konva from 'konva'
import VuetifyDraggableTreeview from 'izteach-vuetify-draggable-treeview'

global.import()
Vue.config.productionTip = false
Vue.config.performance = true
Vue.config.devtools = true

sync(store, router, { moduleName: '$route' })
Vue.use(
	pluginHelper.create({
		$utils: utils,
		$helpers: helpers,
		$message: message,
		$rules: inputRules,
		$confirm: confirm,
		$confirmSupport: confirmSupport
	})
)

Vue.use(sitebuilder, {
	router,
	routerConfig: {
		route: {
			type: 'item',
			path: '/sitebuilder',
			name: 'Site builder'
		}
	}
})

Vue.use(screenbuilder, {
	router,
	routerConfig: {
		route: {
			type: 'item',
			path: '/screenbuilder',
			name: 'Screen builder'
		}
	}
})

Konva.showWarnings = false
Konva.pixelRatio = 1
Vue.use(VueKonva)
Vue.use(Konva)
Vue.use(VuetifyDraggableTreeview)

new Vue({
	vuetify,
	router,
	store,
	render: h => h(App)
}).$mount('#app')
