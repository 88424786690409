import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const Class = RepositoryFactory.get('classOffline')
const namespaced = true;

const state = {
	class: {},
	classes: [],
};

const actions = {
	async createClass({ commit }, data) {
		const res = await Class.create(data);
		commit("addClass", res.data);
		return res.data;
	},
	async fetchClasses({ commit }, params = {}) {
		const res = await Class.fetch({
			...params,
		});
		commit("setClasses", res.data || []);
		return res.data;
	},
	async fetchClassById({ commit }, id) {
		const res = await Class.fetchOne(id);
		commit("setClass", res.data || {});
	},
	async updateClass({ commit }, { id, ...classInfo }) {
		const res = await Class.update(id, classInfo)
		commit('setClass', res.data)
		return res.data
	},
	async removeClass({ commit }, id) {
		await Class.remove(id)
		return commit('removeClass', id)
	},
};

const mutations = {
	addClass(state, newClass) {
		return state.classes.unshift(newClass)
	},
	setClasses(state, classes) {
		state.classes = classes
	},
	setClass(state, newClass) {
		state.classes = state.classes.map((item) => {
			if (item.id === newClass.id) {
				return newClass
			} else return item
		})
	},
	removeClass(state, id) {
		state.classes = state.classes.filter(classInfo => classInfo.id !== id)
	}
};

const getters = {
	getAllClasses: (state) => {
		return state.classes
	}
};

export default {
	namespaced,
	state,
	actions,
	mutations,
	getters,
};
