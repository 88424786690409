<template>
	<div class="navbar-spacing">
		<div
			@click="onSelectEditPart(navigation)"
			:class="
				selectedNavigation === navigation.id
					? 'color--white box-icon box-icon__active cursor-pointer'
					: 'color--white box-icon cursor-pointer'
			"
			v-for="navigation in listNavigations"
			:key="navigation.title"
		>
			<v-icon color="white">{{ navigation.icon }}</v-icon>
			<div class="mt-3">{{ navigation.title }}</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		selected: String,
		onSave: Function,
		checkChangedData: Function,
	},
	data() {
		return {
			selectedNavigation: 'common',
			listNavigations: [
				{
					id: 'common',
					icon: 'mdi-palette-outline',
					title: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.NAVIGATION.NS_COMMON')
				},
				{
					id: 'homepage',
					icon: 'mdi-monitor-cellphone-star',
					title: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.NAVIGATION.NS_HOMEPAGE')
				},
				{
					id: 'about-us',
					icon: 'mdi-monitor-cellphone-star',
					title: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.NAVIGATION.NS_ABOUT_US')
				},
				{
					id: 'privacy-policy',
					icon: 'mdi-monitor-cellphone-star',
					title: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.NAVIGATION.NS_PRIVACY_POLICY')
				},
				{
					id: 'recruitment',
					icon: 'mdi-monitor-cellphone-star',
					title: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.NAVIGATION.NS_RECRUITMENT')
				},
			],
		}
	},
	methods: {
		async onSelectEditPart(navigation) {
			const checkChangedData = await this.checkChangedData()
			if (checkChangedData) {
				this.$confirm.show({
					title: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.NAVIGATION.CONFIRM_CHANGE_NAVIGATION_TITLE'),
					text: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.NAVIGATION.CONFIRM_CHANGE_NAVIGATION_DESCRIPTION'),
					confirmColor: 'primary',
					okText: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.BUTTON_SAVE_AND_CONTINUE'),
					cancelText: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.BUTTON_CANCEL_TEXT'),
					done: async () => {
						await this.onSave()
						this.$emit('onSelectNavigation', navigation.id)
					},
				})
			} else this.$emit('onSelectNavigation', navigation.id)
		},
	},
	watch: {
		selected: {
			handler(value) {
				this.selectedNavigation = value
			},
			immediate: true,
		},
	},
}
</script>
<style lang="scss" scoped>
.navbar-spacing {
	margin-top: 72px;
}
.box-icon {
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	height: 100px;
	&__active {
		background: grey;
	}
}
.box-icon:hover {
	background: grey;
}
</style>