import { RepositoryFactory } from '@/api/factory/repositoryFactory'
import bill from '@/store/modules/bill'
const Bill = RepositoryFactory.get('bill')
const state = {
  bills: [],
  bill: {},
  count: 0,
  statistic: {},
  lastEvaluatedKey: null,
  report: [],
  purchasingProviders: [],
  reports: [],
}

const actions = {
  async createBill({ commit }, data) {
    const bill = await Bill.create(data)
    commit('addBill', bill.data)
    return bill.data
  },
  async fetchBills({ commit }, params = {}) {
    const res = await Bill.fetch({
      ...params,
      _sort: 'createdAt:desc'
    })
    commit('setBills', res.data || [])
    return res.data || []
  },
  async fetchBillsIndependent(_, params = {}) {
    const res = await Bill.fetch({
      ...params,
      _sort: 'createdAt:desc'
    })
    return res.data || []
  },

  async statisticBills({ commit }, params = {}) {
    const res = await Bill.statistic({
      ...params,
    })
    commit('setStatistic', res.data || {})
    commit('setPurchasingProviders', res.data.purchasingProviders || [])
    return res.data
  },
  async reportBills({ commit }, params = {}) {
    const res = await Bill.report({
      ...params,
      vendorId: bill.state.vendorId
    })
    commit('setReport', res.data || [])
    commit('setBillsStatistic', res.data || [])
    commit('setBills', res.data.bills || [])
    return res.data
  },



  async countBills({ commit }, params = {}) {
    const res = await Bill.count({
      ...params
    })
    commit('setCount', res.data || [])
    return res.data
  },
  async fetchBill({ commit }, id) {
    const res = await Bill.fetchOne(id)
    commit('setBillData', res || [])
    return res
  },
  async approveBill({ commit }, id) {
    const res = await Bill.approve(id)
    commit('addOrUpdateBill', res.data || {})
    return res.data
  },
  async updateBill({ commit }, { id, ...bill }) {
    const res = await Bill.update(id, bill)
    return commit('addOrUpdateBill', res.data || {})
  },
  async removeBill({ commit }, item) {
    await Bill.remove(item.id, {
      vendorId: item.vendorId
    })
    return commit('removeBill', item.id)
  },
}

const mutations = {
  setBill(state, bill) {
    return (state.bills = {
      ...state.bills,
      [bill.id]: bill
    })

  },
  setCount(state, count) {
    return (state.count = count)
  },
  addBill(state, bill) {
    state.count = state.count + 1
    return (state.bills = {
      [bill.id]: bill,
      ...state.bills
    })
  },
  setBillData(state, bill) {
    return (state.bill = bill)
  },
  appendBills(state, bills) {
    return (state.bills = {
      ...state.bills,
      ...bills.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {})
    })
  },
  setStatistic(state, statistic) {
    state.statistic = statistic
  },
  setBills(state, bills) {
    state.bills = bills
    return state.bills
  },
  removeBill(state, id) {
    delete state.bills[id]
    state.bills = { ...state.bills }
  },
  setLastEvaluatedKey(state, key) {
    state.lastEvaluatedKey = key
  },
  setPurchasingProviders(state, purchasingProviders) {
    return (state.purchasingProviders = purchasingProviders)
  },
  addOrUpdateBill(state, item) {
    if (item && item.id) {
      let foundIndex = state.bills.findIndex(listItem => listItem && listItem.id === item.id)
      if (foundIndex >= 0) {
        state.bills.splice(foundIndex, 1, item)
      } else {
        state.bills.unshift(item)
      }
    }
  },

  setReport(state, report) {
    return state.report = report
  },
  setBillsStatistic(state, statisticbills) {
    return state.statisticbills = statisticbills
  }
}

const getters = {
  getBills: state => {
    return state.bills
  },
  getStatistic: state => {
    return state.statistic
  },
  key: state => {
    return state.lastEvaluatedKey
  },
  count: state => {
    return state.count
  },
  bill: state => {
    return state.bill
  },
  getPurchasingProviders: state => {
    return state.purchasingProviders
  },
  getReport: state => {
    return state.report
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
