import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const UnitRepository = RepositoryFactory.get('unit')

const state = {
	units: {},
	count: 0,
	isExternalLink: false,
	assignedUnits: [],
	currentUnitId: '',
	totalScore: 0,
	unit: {}
}

const actions = {
	async createUnit({ commit }, data) {
		const unit = await UnitRepository.create(data)
		commit('addUnit', unit.data)
		return unit.data
	},
	async fetchUnits({ commit }, params = {}) {
		const res = await UnitRepository.fetch({
			...params
		})
		commit('setUnits', res.data || [])
		return res.data
	},
	async fetchUnitsIndependent(_, params = {}) {
		const res = await UnitRepository.fetch({
			...params
		})
		return res.data
	},
	async countUnits({ commit }, params = {}) {
		const res = await UnitRepository.count(params)
		commit('setCount', res.data || 0)
	},
	async updateUnit({ commit }, { id, ...unit }) {
		const res = await UnitRepository.update(id, unit)
		commit('setUnit', res.data)
		return res.data
	},
	async sendNoti(_, id) {
		const res = await UnitRepository.sendNoti(id)
		return res.data
	},
	async updateUnitOrder({ commit }, units) {
		for (let i = 0; i < units.length; i++) {
			units[i] = {
				...units[i],
				index: i
			}
			await UnitRepository.update(units[i].id, { index: i })
		}
		commit('changeUnitsOrder', units)
	},

	async removeUnit({ commit }, item) {
		await UnitRepository.remove(item.id, {
			vendorId: item.vendorId
		})

		return commit('removeUnit', item.id)
	},
	async setUnit({ commit }, unit) {
		commit('setUnitData', unit)
	},
	async addUnit({ commit }, unit) {
		return commit('setUnit', unit)
	},
	async assignTeacherToHomeworkUnit({ commit }, { unitId, courseTitle = '', unitTitle = '', assignee }) {
		const res = await UnitRepository.assignTeacherToHomeworkUnit(unitId, { assignee })
		commit('addAssignedUnit', {
			...(res.data || {}),
			courseTitle,
			unitTitle,
		})
		return res.data || {}
	},
	async removeAssignTeacherFromHomeworkUnit({ commit }, { unitId, assigneeId }) {
		const res = await UnitRepository.removeAssignTeacherFromHomeworkUnit(unitId, { assigneeId })
		commit('removeAssignedUnit', unitId)
		return res.data || {}
	},
	async fetchAssignedHomework(_, params) {
		const res = await UnitRepository.fetchAssignedHomeworkForTeacher(params)
		return res.data || []
	},
	async fetchUnitIndependent(_, id) {
		const res = await UnitRepository.fetchOne(id)
		return res.data || {}
	},
	async listAssignedHomework({ commit }, params) {
		const res = await UnitRepository.fetchAssignedHomeworkForAdmin(params)
		commit('setAssignedUnits', res.data || [])
		return res.data || []
	},
	async updateTotalScore({ state }, totalScore) {
		state.totalScore = totalScore
	}
}

const mutations = {
	setUnit(state, unit) {
		return (state.units = {
			...state.units,
			[unit.id]: unit
		})
	},
	setCount(state, count) {
		return (state.count = count)
	},
	addUnit(state, unit) {
		state.count = state.count + 1
		state.units = {
			[unit.id]: unit,
			...state.units
		}
	},
	updateUnits(state, units) {
		state.units = {
			...units.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}),
			...state.units
		}
	},
	setUnitData(state, unit) {
		return state.unit = unit
	},
	changeUnitsOrder(state, units) {
		state.units = units
	},
	appendUnits(state, units) {
		return (state.units = {
			...units.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}),
			...state.units
		})
	},
	setUnits(state, units) {
		return (state.units = units.reduce(
			(acc, cur) => ({ ...acc, [cur.id]: cur }),
			{}
		))
	},
	setIsExternalLink(state, isExternalLink) {
		return state.isExternalLink = isExternalLink
	},
	removeUnit(state, id) {
		delete state.units[id]
		state.units = { ...state.units }
	},
	setAssignedUnits(state, units) {
		state.assignedUnits = units
	},
	addAssignedUnit(state, unit) {
		state.count += 1
		state.assignedUnits.push(unit)
	},
	removeAssignedUnit(state, unitId) {
		state.assignedUnits = state.assignedUnits.filter(x => x.id !== unitId)
	},
	setCurrentUnitId(state, currentUnitId) {
		state.currentUnitId = currentUnitId
	},
}

const getters = {
	units: state => {
		return Object.values(state.units).sort((a, b) => {
			const aIndex = a.index ? a.index : 0
			const bIndex = b.index ? b.index : 0
			return aIndex - bIndex
		})
	},
	getUnits: state => sectionId => {
		return Object.values(state.units)
			.filter(a => a.section === sectionId)
			.sort((a, b) => {
				const aIndex = a.index ? a.index : 0
				const bIndex = b.index ? b.index : 0
				return aIndex - bIndex
			})
	},
	objectUnits: state => {
		return state.units
	},
	getUnitById: state => unitId => {
		return Object.values(state.units).find(unit => unit.id === unitId)
	},
	count: state => {
		return state.count
	},
	unit: state => {
		return state.unit
	},
	isExternalLink: (state) => {
		return state.isExternalLink
	},
	getAssignedUnits: (state) => {
		return state.assignedUnits
	},
	getCurrentUnitId: (state) => {
		return state.currentUnitId
	},
	getTotalScore: (state) => {
		return state.totalScore
	},
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}
