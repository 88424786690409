import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const ActiveCode = RepositoryFactory.get('activeCode')

const state = {
	activeCodes: [],
	activeCode: {},
	count: 0
}

const actions = {
	async fetchActiveCodes({commit}, params = {}) {
		const res = await ActiveCode.fetch({
			...params
		})
		commit('setActiveCodes', res.data || [])
		return res.data
	},
	async countActiveCode({commit}, params = {}) {
		const res = await ActiveCode.count({
			...params
		})
		commit('setCount', res.data || 0)
		return res.data
	},
	async fetchActiveCodeById({commit}, id) {
		const res = await ActiveCode.fetchOne(id)
		commit('setOneActiveCode', res.data)
		return res.data
	},
	async createActiveCode({commit, rootState}, data) {
		const res = await ActiveCode.createMany({
			...data,
			userId: rootState.auth.auth.userId
		})
		commit('setActiveCode', res.data)
		return res.data
	},
	async deleteActiveCode({commit}, id) {
		await ActiveCode.remove(id)
		return commit('removeActiveCode', id)
	},
	async updateActiveCode({commit}, {id, activeCode}) {
		const res = await ActiveCode.update(id, activeCode)
		commit('setActiveCode', res.data)
		return res.data
	}
}

const mutations = {
	setActiveCodes(state, activeCodes) {
		return (state.activeCodes = activeCodes)
	},
	setCount(state, count) {
		return (state.count = count)
	},
	removeActiveCode(state, id) {
		state.activeCodes = state.activeCodes.filter(code => code.id !== id)
	},
	setActiveCode(state, newActiveCode) {
		state.activeCodes = state.activeCodes.map(activeCode => {
			if (activeCode._id === newActiveCode._id) {
				return newActiveCode
			} else return activeCode
		})
		return state.activeCodes
	},
	setOneActiveCode(state, activeCode) {
		state.activeCode = activeCode
	}
}

const getters = {
	getActiveCodes: state => {
		return state.activeCodes
	},
	getActiveCode: state => {
		return state.activeCode
	},
	getCount: state => {
		return state.count
	}
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}
