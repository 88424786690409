const DOMAIN_SETTING_FORM = {
	TITLE: 'ページのドメイン名',
	SUBTITLE: 'ドメイン名を変更するには、カスタマーサポートにご連絡ください'
}
const BASIC_SETTINGS = {
	TITLE: '基本設定',

	TAB_BRANDING: 'ブランディング',
	TAB_EMAIL: "Cấu hình email",
	TAB_TRANSACTION_PAYMENT: 'トランザクションと支払い',

	//TRADE_MARK
	TM_TITLE_DOMAIN_NAME: 'ドメイン名',
	TM_SUBTITLE_DOMAIN_NAME:
		'ドメイン名を変更するには、カスタマーサポートにご連絡ください',

	TM_TITLE_ADDRESS_EMAIL: 'メールアドレス',
	TM_LABEL_EMAIL_PAGE: 'ページのメールアドレス',
	TM_PLACEHOLDER_EMAIL_PAGE: '例： tuannhhe@gmail.com',
	TM_HINT_EMAIL_PAGE:
		'学生はこのメールアドレスからアクティベーションコードと通知を受け取ります。',
	TM_LABEL_PASSWORD: 'パスワード',
	TM_PLACEHOLDER_PASSWORD: '例：******',
	TM_HINT_PASSWORD:
		'システムにアクセスして自動送信機能を設定するには、電子メールパスワードが必要です。セキュリティについて質問がある場合は、カスタマーサポートにご連絡ください。',

	TM_TITLE_EMAIL_PAGE: 'ページのメールアドレス',
	TM_SUBTITLE_EMAIL_PAGE:
		'学生はこのメールアドレスからアクティベーションコードと通知を受け取ります。',

	TM_TITLE_CHANGE_ADMIN_PAGE_NAME: '管理者ページの名称変更',
	TM_LABEL_LABEL_PAGE_NAME: 'ページ名',
	TM_HINT_PAGE_NAME: '推奨事項：ページ名は20文字を超えてはなりません',

	TM_TITLE_SETTING_LINK_SOCIAL_NETWORK: 'ソーシャルメディアのリンク設定',
	TM_LABEL_LINK_FACEBOOK: 'Facebookのリンク',
	TM_LABEL_LINK_YOUTUBE: 'YouTubeのリンク',
	TM_LABEL_LINK_TIKTOK: 'TikTokのリンク',
	TM_LABEL_LINK_INSTAGRAM: 'Instagramのリンク',
	TM_PLACEHOLDER_ENTER_LINK: 'リンクの入力',

	TM_BUTTON_RESET: 'リセット',
	TM_BUTTON_ENTER_EXPORT_HTML: 'HTMLのインポート/エクスポート',
	TM_BUTTON_SAVE: '保存',

	//TRANSACTON AND PAY
	TP_TITLE_TRANSFER_INFO: '銀行振込情報',
	TP_SUBTITLE_TRANSFER_INFO:
		'銀行振込情報は銀行振込情報→コースの支払いで表示されます。',

	TP_LABEL_BANK_NAME: '銀行名',
	TP_PLACEHOLDER_BANK_NAME: '例：Example',
	TP_LABEL_ACCOUNT_NAME: '口座名義',
	TP_PLACEHOLDER_ACCOUNT_NAME: '例：Nguyễn Văn A',

	TP_LABEL_ACOUNT_NUMBER: '口座番号',
	TP_PLACEHOLDER_ACOUNT_NUMBER: '例：00123212312312',

	TP_LABEL_TRANSFER_CONTENT_FORM: '振込メッセージのテンプレート',
	TP_PLACEHOLDER_TRANSFER_CONTENT_FORM:
		'例：Nguyen Van A - 12D組 - 高校３年の幾何学',
	HINT_TRANSFER_CONTENT:
		'振込メッセージのテンプレートにより、ユーザーが振込確認用の情報を正しく入力できるようにします。',

	TP_TITLE_VNPAY_CONFIG: '構成',
	TP_SUBTITLE_VNPAY_CONFIG: 'VNPay支払いシステムの構成',
	TP_LABEL_VNP_TMNCODE: 'vnp_TmnCode',
	TP_PLACEHOLDER_VNP_TMNCOD: '例：Example',
	TP_LABEL_VNP_HASHSECRET: 'vnp_HashSecret',
	TP_PLACEHOLDER_VNP_HASHSECRET: '例：JKLWEUPOCXMNWHKSDJ',

	//TEMPLATE TITLE
	TT_REGISTER_EMAIL: 'アカウント登録メール',
	TT_FORGOT_PASSWORD_EMAIL: 'パスワード忘れメール',
	TT_CHANGE_PASSWORD_EMAIL: 'パスワード変更メール',
	TT_APPROVED_COURSE_EMAIL: 'コース所有権確認メール',

	//BUTTON
	BUTTON_RESET: 'リセット',
	BUTTON_SAVE: '保存',
	BUTTON_ENTER_EXPORT_HTML: 'HTMLのインポート/エクスポート',

	//CHECKBOX
	CHECKBOX_APPEAR: '表示',
	CHECKBOX_HIDE: '非表示',

	//RADIUS
	RADIUS_APPEAR: '表示',
	RADIUS_HIDE: '非表示',

	//メッセージ
	MSG_UPDATE_LOADING: '更新しています...',
	MSG_UPDATE_ACCOUNT_LOADING: 'アカウント更新中',
	MSG_UPDATE_PAGE_NAME_LOADING: 'ページ名更新中',
	MSG_CHECK_PAGE_NAME: 'ページ名を入力してください',
	MSG_CHECK_INFO_AGAIN: 'もう一度情報を確認してください！',
	MSG_CHECK_FULL_INFO_AGAIN: '必要な情報をすべて入力してください。',
	MSG_UPDATE_ACCOUNT_SUCCESS: 'アカウントの更新が完了しました。',
	MSG_UPDATE_SUCCESS: '更新に成功しました',
	MSG_UPDATE_FAILURE: '更新に失敗しました',
	MSG_UPDATE_DATA_FAILURE: 'データの更新に失敗しました',
	MSG_UPDATE_DATA_SUCCESS: 'データの更新に成功しました',
	MSG_UPDATE_DATA_FAIL: '更新に失敗しました',

	PLACEHOLDER_SELECT_FILE_UPLOAD: 'アップロードファイルを選択してください'
}
const ADVANCED_SETTING = {
	TITLE: '詳細設定',

	TAB_VIRTUAL_CLASS_ZOOM: '仮想教室（ZOOM）',
	TAB_USER_INTERACTION: 'ユーザーインタラクション',
	TAB_CODE_DEPOSIT_AND_FOLLOW: 'ユーザー操作監視用の埋め込みコード',

	LABEL_ZOOM_NOTE:
		'自分のZoom仮想教室をシステムに連携することができます。以下のボタンでZoom教室管理ページに移動します。',
	LABEL_ZOOM_ACTIVATED: 'Zoomを有効にしてホームページにZoomボタンを表示する',

	//VIRTUAL CLASS ZOOM
	VCZ_LABEL_NOTE_OF_ZOOM:
		'自分のZoom仮想教室をシステムに連携することができます。以下のボタンでZoom教室管理ページに移動します。',
	VCZ_LABEL_ACTIVE_ZOOM_AND_DISBPAY_BUTTON_ZOOM:
		'Zoomを有効にしてホームページにZoomボタンを表示する',
	BUTTON_MANAGE_ZOOM_CLASS: 'Zoom教室の管理',

	//DEPOSIT & FOLLOW
	DF_PLACEHOLDER_ENTER_CODE_NAME: 'コード入力',
	DF_TITLE_NEW_SCRIPT_CODE: '新規Javascriptコード',
	DF_SUBTITLE_NEW_SCRIPT_CODE:
		'埋め込みコードは潜在的なセキュリティリスクを引き起こす可能性があります。データの消失などについてこのシステムでは一切の責任を負いません。',
	DF_DROPDOWN_LABEL_PLACE_CODE: 'コード埋め込み箇所',

	//USER INTERACTION

	//BUTTON
	BUTTON_ADD_NEW_SCRIPT: 'スクリプト新規追加',
	BUTTON_REMOVE_SCRIPT_FORM: '削除',
	BUTTON_SAVE_SCRIPT_FORM: '保存',

	//RADIUS
	RADIUS_APPEAR: '表示',
	RADIUS_HIDE: '非表示',

	//MASSAGE
	MSG_UPDATE_DATA_LOADING: 'データ更新中',
	MSG_UPDATE_DATA_SUCCESS: 'データ更新が完了しました。'
}
const EMAIL_ADDRESS_SETTING_FORM = {
	TITLE: 'メールアドレス',
	LABEL_EMAIL_ADDRESS: 'ページのメールアドレス',
	PLACEHOLDER_EMAIL_ADDRESS: '例：tuannhhe@gmail.com',
	HINT_EMAIL_ADDRESS:
		'学生はこのメールアドレスからアクティベーションコードと通知を受け取ります。',
	LABEL_PASSWORD: 'パスワード',
	PLACEHOLDER_PASSWORD: '例：******',
	HINT_PASSWORD:
		'システムにアクセスして自動送信機能を設定するには、電子メールパスワードが必要です。セキュリティについて質問がある場合は、カスタマーサポートにご連絡ください。',
	MSG_UPDATING: 'アカウント更新中',
	MSG_UPDATED: 'アカウント更新が完了しました。',
	MSG__CHECK_FULL_INFO: '必要な情報をすべて入力してください。'
}
const EMAIL_CONTENT_SETTING_FORM = {
	TITLE: 'ページのメールアドレス',
	SUBTITLE:
		'学生はこのメールアドレスからアクティベーションコードと通知を受け取ります。',
	//LIST TEMPLATE EMAIL
	TITLE_REGISTTRATION: 'アカウント登録メール',
	TITLE_FORGOT_PASSWORD: 'パスワード忘れメール',
	TITLE_CHANGE_PASSWORD: 'パスワード変更メール',
	TITLE_COURSE_APPROVING: 'コース所有権確認メール',
	TEXT_HTML: 'HTML TEXT',
	BUTTON_UPDATE_BY_TEMPLATE: 'HTMLのインポート/エクスポート',
	BUTTON_IMPORT_TEMPLATE: 'Excelファイルのインポート',
	BUTTON_DOWNLOAD_TEMPLATE: 'テンプレートダウンロード',
	BUTTON_CANCEL: 'キャンセル',

	POPUP_TITLE_RESET_EMAIL: 'メールリセット',
	POPUP_SUBTITLE_RESET_EMAIL:
		'以下の設定されているメール内容を削除して、デフォルトのメール内容に戻してもよろしいですか？',
	MSG_UPDATING: 'メールテンプレート更新中'
}
const ADMIN_PAGE_NAME_SETTING_FORM = {
	TITLE: '管理者ページ名',
	SUBTITLE: 'Tên trên đường link truy cập',
	LABEL_PAGE_NAME: 'ページ名',
	PLACEHOLDER_PAGE_NAME: '例：HA NOI EDUCATION',
	HINT_PAGE_NAME: '※ページ名は20文字以内に設定してください。',
	MSG_UPDATING: 'ページ名更新中',
	MSG_PAGE_NAME_IS_REQUIRED: 'ページ名を入力してください。'
}
const SOCIAL_LINKS_SETTING_FORM = {
	TITLE: 'SNSのリンク',
	LABEL_FACEBOOK: 'Facebookのリンク',
	LABEL_YOUTUBE: 'youtubeのリンク',
	LABEL_TIKTOK: 'tiktokのリンク',
	LABEL_INSTAGRAM: 'instagramのリンク',
	PLACEHOLDER_LINK: 'パスを入力してください。'
}
const BANKING_INFOS_FORM = {
	TITLE: '銀行振込情報',
	SUBTITLE: '銀行振込情報は銀行振込情報→コースの支払いで表示されます。',
	LABEL_BANK_NAME: '銀行名',
	PLACEHOLDER_BANK_NAME: '例：IZTEACH',
	LABEL_ACCOUNT_HOLDER_NAME: '口座名義',
	PLACEHOLDER_ACCOUNT_HOLDER_NAME: '例：Nguyen Van A',
	LABEL_ACOUNT_NUMBER: '口座番号',
	PLACEHOLDER_ACOUNT_NUMBER: '例：00123212312312',
	LABEL_TRANSFER_CONTENT_SAMPLE: '振込メッセージのテンプレート',
	PLACEHOLDER_TRANSFER_CONTENT_SAMPLE:
		'例：Nguyen Van A - 12D組 - 高校３年の幾何学',
	HINT_TRANSFER_CONTENT_SAMPLE:
		'振込メッセージのテンプレートにより、ユーザーが振込確認用の情報を正しく入力できるようにします。',
	LABEL_WORKING_HINT: '支払いに関する注意',
	PLACEHOLDER_WORKING_HINT: 'このメモは、購入者が銀行振込オプションを選択したときに表示されます',
}
const ONLINE_PAYMENT_GATEWAY_SETTING_FORM = {
	TITLE: 'オンライン決済サービス',
	SUBTITLE: 'VNPay決済システムの設定',
	LABEL_VNP_TMNCODE: 'vnp_TmnCode',
	PLACEHOLDER_VNP_TMNCODE: '例：IZTEACH',
	LABEL_VNP_HASHSECRET: 'vnp_HashSecret',
	PLACEHOLDER_VNP_HASHSECRET: '例：JKLWEUPOCXMNWHKSDJ',
	LABEL_WORKING_HINT: '支払いに関する注意',
	PLACEHOLDER_WORKING_HINT: 'このメモは、購入者がVNPay支払いオプションを選択したときに表示されます',
	TOGGLE_SHOW_PAYMENT_OPTION: 'VNPay支払いオプションを表示する',
	TOGGLE_HIDE_PAYMENT_OPTION: 'VNPay支払いオプションを非表示',
}
const DIRECT_PAY_SETTING_FORM = {
	TITLE: '直接支払い設定',
	LABEL_WORKING_HINT: '支払いに関する注意',
	PLACEHOLDER_WORKING_HINT: 'このメモは、購入者が直接支払いオプションを選択したときに表示されます',
}
const ADVANCED_SETTINGS = {
	TITLE: '詳細設定',
	TAB_VIRTUAL_CLASS_ZOOM: '仮想教室(ZOOM)',
	TAB_EMBEDED_SCRIPT: 'ユーザー操作監視用の埋め込みコード',
	TAB_USER_INTERACTION: 'ユーザーインタラクション'
}
const VIRTUAL_CLASS_ZOOM_FORM = {
	SUBTITLE:
		'自分のZoom仮想教室を本システムに連携することができます。以下のボタンでZoom教室管理ページに移動します。',
	LABEL_ACTIVE_ZOOM: 'Zoomを有効にしてホームページにZoomボタンを表示する',
	TOGGLE_SHOW_ZOOM_ACCESS_BUTTON: '表示',
	TOGGLE_HIDE_ZOOM_ACCESS_BUTTON: '非表示',
	BUTTON_MANAGE_ZOOM_CLASS: 'Zoom教室の管理'
}
const VIRTUAL_CLASS_LIST = {
	TITLE: 'Zoom教室の管理',
	LINK_RETURN_TO_ADVANCED_SETTINGS: '詳細設定',
	BUTTUN_ADD_CLASS: 'Zoomレッスンの追加',
	LABEL_FILTER_BY_STATUS: '状態によるフィルタリング',
	PLACHOLDER_SELECT_STATUS: '状態を選択してください',
	LABEL_FILTER_BY_STUDENT_GROUP: 'グループによるフィルタリング',
	PLACEHOLDER_FILTER_BY_STUDENT_GROUP: 'ユーザーグループを選択してください',
	BUTTON_UPDATE_BY_TEMPLATE: 'Excelのインポート/エクスポート',
	BUTTON_IMPORT_TEMPLATE: 'Excelファイルのインポート',
	BUTTON_DOWNLOAD_TEMPLATE: 'テンプレートダウンロード',

	//EMPTY_DATA
	TITLE_EMPTY_DATA: '教室が1件も存在していません。',
	SUBTITLE_EMPTY_DATA: '教室を新規作成してください',

	//TABLE HEADER
	TH_TITLE: 'レッスンのタイトル',
	TH_TEACHER_NAME: '担当教師',
	TH_START_DATE: '学習日',
	TH_START_TIME: '学習時間',
	TH_STUDENT_GROUP: 'グループ',
	TH_STATUS: '状態',
	TH_ACTION: 'アクション',
	//TABLE HEADER DOWNLOAD FILE ZOOM EXCEL
	TH_END_TIME: '終了時間',
	TH_END_DATE: '終了日',
	TH_LINK_ZOOM: 'Zoomのリンク',
	TOOLTIP_EDIT_ZOOM: "レッスンの編集",

	TEXT_STATUS_IN_FUTURE: '待機中',
	TEXT_STATUS_IN_PROGRESS: '進行中',
	TEXT_STATUS_FINISHED: '終了',
	TEXT_CLASS: '教室',
	TEXT_ALL: '全て',
	TEXT_NO_GROUP: 'グループ無し',

	//POPUP REMOVE ZOOM CLASS
	TITLE_REMOVING_DIALOG: 'このレッスンを削除します。よろしいでしょうか。',
	SUBTITLE_REMOVING_DIALOG: '削除されたデータは回復できません。',
	MSG_REMOVE_CLASS_SUCCESS: 'レッスンを削除しました。',
	MSG_DOWLOAD_DATA_LOADING:
		'データを読み込んでいます。後でもう一度お試しください。'
}
const VIRTUAL_CLASS_ADDING_FORM = {
	TITLE: 'Zoomレッスン追加',
	LINK_TO_CLASS_LIST: 'Zoom教室一覧',
	TITLE_GENERAL_INFO: '基本情報',
	LABEL_TITLE: 'レッスンのタイトル',
	PLACEHOLDER_TITLE: 'レッスンタイトルの入力',
	LABEL_TEACHER_NAME: '担当教師',
	PLACEHOLDER_TEACHER_NAME: '担当教師を入力してください。',
	TITLE_DISPLAY_SETTINGS: '表示設定',
	LABEL_STUDENT_GROUP: 'ユーザーグループの選択',
	PLACEHOLDER_STUDENT_GROUP: '追加対象グループを選択してください。',
	//RADIO
	OPTION_RESTRICT_TO_STUDENT_GROUP: 'ユーザーグループ別に表示',
	OPTION_ALLOW_ALL_STUDENT: 'ユーザー全員に表示',

	TITLE_SCHEDULE: '講義スケジュール',
	SUBTITLE_SCHEDULE:
		'ユーザーは以下のレッスンスケジュールを確認でき、レッスンを受講できます。',
	LABEL_START_DATE: '開始日',
	LABEL_START_TIME: '開始時間',
	LABEL_END_DATE: '終了日',
	LABEL_END_TIME: '終了時間',

	TITLE_ZOOM_CONFIG: 'Zoom設定',
	SUBTITLE_ZOOM_CONFIG: '招待リンクをコピーし、こちらに貼り付けてください。',
	LABEL_LINK: '新しいリンク',
	PLACEHOLDER_LINK: 'リンクを入力してください。',
	//MASSAGE
	MSG_CREATE_CLASS_SUCCESS: 'レッスンを作成しました。',
	MSG_CLASS_SCHEDULE_INCORRECT:
		'開始時間を終了時間より過去に設定する必要があります。'
}
const EMBEDED_SCRIPT_FORM = {
	BUTTON_ADD_NEW_SCRIPT: 'スクリプト新規追加',
	TITLE_NEW_SCRIPT: '新規Javascriptコード',
	PLACEHOLDER_SCRIPT_NAME: 'コード名を入力してください。',
	SUBTITLE_NEW_JAVASCRIPT:
		'埋め込みコードは潜在的なセキュリティリスクを引き起こす可能性があります。データの消失などについて本システムでは一切の責任を負いません。',
	LABEL_SCRIPT_LOCATION: 'コード配置位置',
	PLACEHOLDER_SCRIPT_LOCATION: 'コード配置位置を選択してください。'
}
const USER_PROFILE_SETTING_FORM = {
	TITLE: 'ユーザープロフィール情報',
	SUBTITLE:
		'ユーザーが自分のプロフィール情報を編集できるように設定します。新しい情報がすぐにシステムに反映されます。',
	TOGGLE_ALLOW_EDITING_PROFILE: 'ユーザーにプロフィール情報の編集を許可する'
}
const REGISTRATION_SETTING_FORM = {
	TITLE: '登録操作',
	TOGGLE_RESTRICT_USER_REGISTRATION: 'ユーザーにホームページでの登録を許可しない'
}
const VIDEO_VIEW_LIMIT_SETTING_FORM = {
	TITLE: 'ユーザーの動画視聴回数制限',
	SUBTITLE: 'コース内の動画は1日の視聴回数を制限します。',
	TOGGLE_RESTRICT_VIDEO_VIEW_LIMIT: 'ユーザーの動画視聴回数を制限する',
	LABEL_VIDEO_VIEW_LIMIT: '制限回数',
	PLACEHOLDER_VIDEO_VIEW_LIMIT: '制限回数を入力してください'
}
const DEFAULT_NOTIFICATION_SETTING_FORM = {
	TITLE: '匿名ユーザ向けデフォルト通知設定',
	SUBTITLE: '匿名ユーザに通知を表示します。通知を最大2つまで設定してください。',
	TOGGLE_ACTIVE_DEFAULT_NOTIFICATION: 'デフォルト通知を表示する',
	BUTTON_ADD_DEFAULT_NOTICE: '通知追加',
	TH_TITLE: 'タイトル',
	TH_CONTENT: '本文',
	TH_LINK: 'リンク',
	TH_ACTION: 'アクション',
	TOOLTIP_EDIT_DEFAULT_NOTIFICATION: '通知を編集する',
}
const DEFAULT_NOTIFICATION_ADDING_FORM = {
	TITLE_NEW_NOTIFICATION: 'デフォルト通知作成',
	LABEL_TITLE: 'タイトル',
	PLACEHOLDER_TITLE: '通知のタイトルを入力してください',
	LABEL_CONTENT: '本文',
	PLACEHOLDER_CONTENT: '通知の本文を入力してください',
	LABEL_LINK: 'リンク',
	PLACEHOLDER_LINK: '通知のリンクを入力してください',
	LABEL_LAST_UPDATE_AT: '更新時間',
	PLACEHOLDER_LAST_UPDATE_AT: '最終更新時間をにゅしてください',
	POPUP_TITLE_REMOVE_NOTIFICATION: 'デフォルト通知削除',
	POPUP_SUBTITLE_REMOVE_NOTIFICATION: '削除されたデータは回復できません。'
}
const LANGUAGE_SETTING_FORM = {
	TITLE: 'E-ラーニングウェブサイトの言語設定',
	SUBTITLE: '表示言語を設定します。ベトナム語・英語・日本語に対応しています。',
	LABEL_LANGUAGE: '言語',
	PLACEHOLDER_LANGUAGE: '言語選択',
	TEXT_VIETNAMESE: 'ベトナム語',
	TEXT_ENGLISH: '英語',
	TEXT_JAPANESE: '日本語'
}
export default {
	DOMAIN_SETTING_FORM,
	BASIC_SETTINGS,
	EMAIL_ADDRESS_SETTING_FORM,
	EMAIL_CONTENT_SETTING_FORM,
	ADMIN_PAGE_NAME_SETTING_FORM,
	SOCIAL_LINKS_SETTING_FORM,
	BANKING_INFOS_FORM,
	ONLINE_PAYMENT_GATEWAY_SETTING_FORM,
	EMBEDED_SCRIPT_FORM,
	VIRTUAL_CLASS_ADDING_FORM,
	VIRTUAL_CLASS_LIST,
	VIRTUAL_CLASS_ZOOM_FORM,
	ADVANCED_SETTINGS,
	DEFAULT_NOTIFICATION_SETTING_FORM,
	VIDEO_VIEW_LIMIT_SETTING_FORM,
	REGISTRATION_SETTING_FORM,
	USER_PROFILE_SETTING_FORM,
	DEFAULT_NOTIFICATION_ADDING_FORM,
	LANGUAGE_SETTING_FORM,
	ADVANCED_SETTING,
	DIRECT_PAY_SETTING_FORM,
	BUTTON_SAVE: '保存',
	BUTTON_CANCEL: 'キャンセル',
	BUTTON_CONFIRM: '確認',
	BUTTON_RESET: 'リセット',
	BUTTON_REMOVE: '削除',
	BUTTON_ENTER_FILE_HTML: 'HTMLファイル取り込み',
	BUTTON_DOWNLOAD_FILE_SAMPLE: 'テンプレートダウンロード',
	BUTTON_ENTER_EXPORT_HTML: 'HTMLのインポート/エクスポート',

	TITLE_EDIT: '編集',
	TITLE_CREATE: '作成',
	TITLE_NOTIFICATION_DEFAULT: 'デフォルト通知',

	MSG_UPDATING: '更新中...',
	MSG_UPDATED: '更新しました。',
	MSG_UPDATE_FAILED: '更新に失敗しました。',
	MSG_INPUT_INCORRECT: '入力情報を再度ご確認ください。',
	MSG_RECHECK_INVALID_FIELD: '入力フィールドを再確認してください。',
	MSG_UPDATING_DATA: 'データが更新されています。',
	MSG_DATA_SUCCESS: 'データ更新が完了しました。',
	MSG_DATA_FAIL: 'データ更新に失敗しました。'
}
