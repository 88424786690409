const BASE_URL = process.env.VUE_APP_API_NOTIFY + '/notify'
const VENDOR_URL = process.env.VUE_APP_API_VENDOR

import Repository from '@/api/core'
import auth from '@/store/modules/auth'

const sendToUser = params => {
	return Repository(BASE_URL + '/user').create(params)
}

const sendToGroup = params => {
	return Repository(BASE_URL + '/group').create(params)
}

const sendToInterest = params => {
	return Repository(BASE_URL + '/interest').create(params)
}
const getCredentialSetting = () => Repository(
	VENDOR_URL + `/vendor-credential/${auth.state.auth.vendorId}`
).fetch()
const saveNotiSetting = params => Repository(VENDOR_URL + `/vendor-credential`).update(auth.state.auth.vendorId, params)

export default {
	sendToUser,
	sendToGroup,
	sendToInterest,
	saveNotiSetting,
	getCredentialSetting
}
