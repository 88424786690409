<template>
  <v-dialog width="500" v-model="dialog">
    <v-card>
      <v-card-title class="headline">
        {{
          $vuetify.lang.t("$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.BUTTON_SEND_EMAIL")
        }}
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5 pb-0">
        <v-form v-model="form" ref="form-send-email">
          <BaseInput
            class="mt-2"
            :label="
              $vuetify.lang.t(
                '$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.LABEL_EMAIL_SUBJECTS'
              )
            "
            :rules="[$rules.required]"
            :placeholder="
              $vuetify.lang.t(
                '$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.PLACEHOLDER_EMAIL_SUBJECTS'
              )
            "
            v-model="title"
          />
          <BaseDropdown
            :label="
              $vuetify.lang.t(
                '$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.LABEL_EMAIL_TEMPLATE'
              )
            "
            :placeholder="
              $vuetify.lang.t(
                '$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.BUTTON_EMAIL_HTML_TEMPLATE'
              )
            "
            :rules="[$rules.required]"
            v-model="selectedType"
            :items="selectedChoices"
          />
          <EditTemplateEmail
            notSave
            :template="{
              title: $vuetify.lang.t(
                '$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.TITLE_SEND_EMAIL_IN_GROUP'
              ),
              value: 'emailInGroup',
              html: '',
            }"
            ref="template-email"
            v-if="selectedType === 'template'"
          />
          <BaseEditor
            :rules="[$rules.required]"
            @text-change="(html) => (editorHtml = html)"
            v-if="selectedType === 'editor'"
          />
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <BaseButton
          @click="dialog = false"
          isButtonText
          :text="$vuetify.lang.t('$vuetify.USER_MANAGE.BUTTON_CANCEL_TEXT')"
        />
        <BaseButton
          @click="onSubmitEmail"
          :text="$vuetify.lang.t('$vuetify.USER_MANAGE.BUTTON_SEND')"
          :dark="false"
          :disabled="!form"
        />
      </v-card-actions>
    </v-card>
    <BaseProgressBar ref="progress-bar" entity="email" />
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import EditTemplateEmail from "@/modules/AdvanceSettings/components/EditTemplateEmail";
export default {
  components: {
    EditTemplateEmail,
  },
  data() {
    return {
      title: "",
      dialog: false,
      selectedType: "",
      templateHtml: "",
      editorHtml: "",
      selectedStudents: [],
      selectedChoices: [
        {
          text: this.$vuetify.lang.t(
            "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.BUTTON_EMAIL_HTML_TEMPLATE"
          ),
          value: "template",
        },
        {
          text: this.$vuetify.lang.t(
            "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.BUTTON_IMPORT_FROM_EDITOR"
          ),
          value: "editor",
        },
      ],
      form: false,
    };
  },
  computed: {
    ...mapGetters({
      group: "group/getGroup",
    }),
  },
  methods: {
    ...mapActions({
      sendMailToGroup: "mail/sendMailToGroup",
      sendMail: "mail/sendMail",
    }),
    openDialog(value) {
      if (value) this.selectedStudents = value;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    refresh() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    async runProgressSendMail() {
      const progressBar = this.$refs["progress-bar"];
      progressBar.openLoading();
      progressBar.changeCustomStatus(
        this.$vuetify.lang.t("$vuetify.USER_MANAGE.MSG_UPDATING")
      );
      for await (const [index, student] of this.selectedStudents.entries()) {
        try {
          progressBar.changePercentage("create", index, this.selectedStudents.length);
          await this.sendMail({
            to: student.email,
            subject: this.title,
            text: "",
            html: this.selectedType === "template" ? this.templateHtml : this.editorHtml,
          });
          await this.$utils.delay(300);
        } catch (e) {
          this.$message.error(e.message || "Internal server error!");
        }
      }
      progressBar.closeLoading();
    },
    async onSubmitEmail() {
      this.$message.loading("Đang gửi email ...", 20000);
      const validate = this.$refs["form-send-email"].validate();
      if (this.$refs["template-email"])
        this.templateHtml = this.$refs["template-email"].getHTMLData();
      if (validate) {
        if (this.selectedType === "template" ? !this.templateHtml : !this.editorHtml)
          return this.$message.warning(
            this.$vuetify.lang.t(
              "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.MESSAGE_WARNING_INFOS"
            )
          );
        try {
          if (!this.selectedStudents.length) {
            await this.sendMailToGroup({
              group: this.$route.params.id,
              subject: this.title,
              text: "Hello",
              html:
                this.selectedType === "template" ? this.templateHtml : this.editorHtml,
            });
          } else await this.runProgressSendMail();
          this.$message.success(
            this.$vuetify.lang.t(
              "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.MESSAGE_SEND_EMAIL_SUCCESS"
            )
          );
          this.refresh();
        } catch (error) {
          this.$message.error(error);
        }
      } else
        this.$message.warning(
          this.$vuetify.lang.t("$vuetify.USER_MANAGE.MSG_RECHECK_INVALID_FIELD")
        );
    },
  },
};
</script>
