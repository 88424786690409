<template>
  <span class="outlined">
    <v-autocomplete
      class="body-2"
      v-bind="$attrs"
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      :label="
        label ||
          $vuetify.lang.t(
            '$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.LABEL_FILTER_COURSE_ASSIGNED'
          )
      "
      :placeholder="
        placeholder ||
          $vuetify.lang.t(
            '$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.PLACEHOLDER_FILTER_COUSRE'
          )
      "
      :item-text="itemText"
      :item-value="itemValue"
      :value="value"
      :multiple="isMultipleSelect"
      :chips="isMultipleSelect"
      return-object
      dense
      outlined
      @change="value => onSelected(value)"
      :clearable="isClearable"
    >
      <template v-if="isMultipleSelect" v-slot:selection="data">
        <v-chip
          small
          v-if="!data.item.disabled"
          v-bind="data.attrs"
          :input-value="data.selected"
        >
          {{ data.item.title }}
        </v-chip>
      </template>
    </v-autocomplete>
  </span>
</template>

<script>
import debounce from 'lodash-es/debounce'
import get from 'lodash-es/get'
import {mapActions, mapGetters} from 'vuex'
export default {
  props: {
    type: {
      type: String,
      default: 'online'
    },
    isPublish: Boolean,
    itemText: String,
    itemValue: String,
    isMultipleSelect: {
      type: Boolean,
      default: false
    },
    value: [Array, String],
    isClearable: {
      type: Boolean,
      default: false
    },
    isReturnObject: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      listSelected: [],
      isLoading: false,
      search: '',
      items: []
    }
  },
  mounted() {
    this.fetchCourses()
  },
  methods: {
    ...mapActions({
      fetch: 'onlineCourse/fetchCourses',
      fetchAuthorsIndependent: 'author/fetchAuthorsIndependent'
    }),
    async refreshItems() {
      await this.$utils.delay(300)
      this.handlerData(this.items)
    },
    onSelected(selected) {
      if (this.isMultipleSelect) {
        this.listSelected = selected
      } else {
        this.listSelected = selected ? [selected] : []
      }
      this.$emit(
        'change',
        this.isMultipleSelect
          ? this.listSelected
          : this.isReturnObject
          ? selected
          : get(selected, this.itemValue, '')
      )
      this.$emit(
        'input',
        this.isMultipleSelect
          ? this.listSelected
          : this.isReturnObject
          ? selected
          : get(selected, this.itemValue, '')
      )
    },
    async fetchCourses(searchString) {
      try {
        let params = {
          type: this.type,
          _limit: 10,
          _skip: 0,
          tags_contain: searchString || ''
        }
        if (this.$utils.isTeacher()) {
          const authors = await this.fetchAuthorsIndependent({
            profileId: this.authUser.id
          })
          if (authors && authors.length) {
            params.authors_in = authors[0].id
          }
        }
        if (this.isPublish) params.status = 'published'
        this.items = await this.fetch(params)
        if (this.listSelected && this.listSelected.length) {
          this.items = this.items.concat(
            this.listSelected ? this.listSelected : []
          )
        }
        this.handlerData(this.items)
      } catch (error) {
        this.$message.error(error)
      }
      this.isLoading = false
    },
    handlerData(items) {
      if (this.isMultipleSelect) {
        this.items = items.map(course => {
          let courseSelected = this.value.find(v => v.id === course.id)
          return {
            ...course,
            disabled: courseSelected ? courseSelected.disabled : false
          }
        })
      }
      this.items.sort((a, b) => {
        let nameA = a.title.toUpperCase()
        let nameB = b.title.toUpperCase()
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
    },
    debounce: debounce(function() {
      this.fetchCourses(this.tags)
    }, 1000)
  },
  computed: {
    ...mapGetters({
      authUser: 'auth/getAuthUser'
    }),
    tags() {
      return this.search ? this.$utils.clearUnicode(this.search) : ''
    }
  },
  watch: {
    tags() {
      if (this.isLoading) return
      this.isLoading = true
      this.debounce()
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .v-list-item--disabled {
  opacity: 0.5;
}
::v-deep .v-chip--select {
  margin: 4px !important;
}
</style>
