import helpers from '../../helpers/index.js'
import uuid from 'uuid'
import isInteger from 'lodash-es/isInteger'
import max from 'lodash-es/max'
import min from 'lodash-es/min'
import sum from 'lodash-es/sum'
import dayjs from 'dayjs'
import stringUtils from './stringUtils'
import vuetify from '@/plugins/vuetify'

const caculateTotalTimeAndScore = (questions) => {
  let totalTime = 0
  let totalScore = 0
  let count = 0
  questions.map(question => {
    if (!question.type.includes('child')) {
      totalScore += Number(question.factor)
      totalTime += Number(question.config ? question.config.time : 0)
    }
    if (!question.type.includes('group')) count++
  })
  return { totalTime, totalScore: totalScore.toFixed(2), count }
}

const convertChildrenToArray = (children) => {
  if (!children) return [];
  return Object.values(children).map((item) => {
    if (item.children) {
      return {
        ...item,
        children: convertChildrenToArray(item.children),
      };
    } else return item;
  }).sort((a, b) => a.index - b.index);
}

const getPathOfKnowledge = (knowledge) => {
  const parents = knowledge.parents;
  let path = "";
  parents.map((parentId, index) => {
    path += `${parentId}${index === parents.length - 1 ? `.children.${knowledge.id}` : ".children."
      }`;
  });
  return path;
}

const flattenQuestion = (questions) => {
  let childs = []
  let flatQuestions = questions
  flatQuestions.forEach(q => {
    if (q.type === 'group') childs = childs.concat(q.children)
  })
  flatQuestions = flatQuestions.concat(childs)
  return flatQuestions
}

const caculateTimeAndScorePart = (parts, isFlatten) => {
  let allQuestions = []
  Object.keys(parts).map(key => {
    let questionsOfPart = parts[key].questions
    if (isFlatten)
      questionsOfPart = flattenQuestion(questionsOfPart)
    allQuestions = allQuestions.concat(questionsOfPart)
  })
  return caculateTotalTimeAndScore(allQuestions)
}

const validateOneQuestion = (question, type) => {
  if (type !== 'group') if (!question.correct) return false
  if (type !== 'exercise' && !isInteger(Number(question.config.time))) return false
  // if (question.factor) {
  //   let numberNDecimal = String(question.factor).split('.')
  //   if (numberNDecimal.length > 1) return numberNDecimal[1].length <= 3
  // } else return false
  return true
}

const validateMark = (questions, isPartition, settings) => {
  if (settings.selectedTypeScore === '10-mark') {
    let allQuestions = []
    let totalScore = 0
    if (!isPartition) {
      allQuestions = questions
    } else questions.map(q => allQuestions = allQuestions.concat(q.questions))
    const allFactor = allQuestions.map(q => {
      return parseFloat(String(q.factor || 0))
    })
    totalScore = Math.round((sum(allFactor)) * 100) / 100
    return totalScore === 10
  } else return true
}

const validateQuestion = (questions, isPartition, type) => {
  let allQuestions = []
  if (!isPartition) {
    allQuestions = questions
  } else questions.map(q => allQuestions = allQuestions.concat(q.questions))
  let qInvalid = []
  allQuestions.map(q => {
    if (q.type === 'group') {
      if (!validateOneQuestion(q, 'group')) qInvalid.push(q)
      q.children.map(child => {
        if (!validateOneQuestion(child)) qInvalid.push(child)
      })
    } else if (!validateOneQuestion(q, type)) qInvalid.push(q)
  })
  return qInvalid.map(q => {
    if (q.type === 'group') return `${vuetify.framework.lang.translator('$vuetify.TEXT_GROUP')} ${q.index + 1}`
    else if (q.type.includes('child')) return `${vuetify.framework.lang.translator('$vuetify.TEXT_QUESTION_CHILD')} ${q.index + 1}`
    else return `${vuetify.framework.lang.translator('$vuetify.TEXT_QUESTION')} ${q.index + 1}`
  })
}

const getDataQuestion = (exam, question, type, currentUnit) => {
  return {
    questions: question.questions || undefined,
    course: currentUnit.course,
    unit: currentUnit.id,
    vendorId: currentUnit.vendorId,
    exam: exam._id,
    type: question.type,
    index: question.index,
    correct: question.correct,
    factor: question.factor || 1,
    answers:
      type === 'test'
        ? question.answers
        : question.selections,
    html: question.html || null,
    config: question.config || null
  }
}

const convertTestAnswers = answers => {
  let newAnswers = {}
  if (!answers) return undefined
  answers.forEach(answer => {
    newAnswers[answer.key] = {
      type: 'html',
      value: answer.value,
      label: answer.label,
      key: answer.key
    }
  })
  return newAnswers
}

const convertTestQuestions = questions => {
  return questions.map(v => {
    let newAnswers = convertTestAnswers(v.answers)
    return {
      ...v,
      html: v.description,
      answers: newAnswers,
      correct: v.answer
    }
  })
}

const getIdsQuestionWithChild = (questions) => {
  let listIds = []
  questions.forEach(i => {
    if (i.type === 'group') {
      listIds.push(i.id)
      listIds = listIds.concat(i.questions)
    } else listIds.push(i.id)
  })
  return listIds
}

const getQuestionWillDelete = (beforeQuestions, afterQuestions) => {
  const listIdsBefore = getIdsQuestionWithChild(beforeQuestions)
  const listIdsAfter = getIdsQuestionWithChild(afterQuestions)
  if (afterQuestions.length === 0) return listIdsBefore
  let deleteQuestions = []
  listIdsBefore.forEach(id => {
    if (listIdsAfter.indexOf(id) < 0) deleteQuestions.push(id)
  })
  return deleteQuestions
}

const getSelections = (numberSelections) => {
  let selections = {}
  for (let i = 0; i < numberSelections; i++) {
    const newKey = uuid.v4()
    selections[newKey] = {
      type: 'html',
      value: '',
      label: helpers.DEFAULT_LABELS[i],
      key: newKey,
    }
  }
  return selections
}

const getCorrect = (type) => {
  return type === helpers.TYPES_OF_QUESTIONS.MULTIPLE_CHOICES ? [] : ''
}

const getNewQuestion = (index, settings, isGroup) => {
  const type = getTypeQuestions(settings)
  const { selectedType, numberQuestions, factor } = settings
  return {
    index: index,
    type: isGroup === 'group' ? 'group' : type,
    answers: getSelections(selectedType),
    correct: getCorrect(type),
    html: '',
    id: uuid.v4(),
    config: {
      time: settings.time
    },
    factor: isGroup === 'group' ? Number(numberQuestions) * factor : factor
  }
}

const getTypeQuestions = (settings) => {
  const typeOfQuestion = helpers.TYPES_OF_QUESTIONS
  if (settings.isEnableMultipleCorrect) {
    return typeOfQuestion[settings.isGroup ? 'MULTIPLE_CHOICES_CHILD' : 'MULTIPLE_CHOICES']
  } else {
    if (settings.selectedType === typeOfQuestion.FILL_BLANK) {
      return typeOfQuestion[settings.isGroup ? 'FILL_BLANK_CHILD' : 'FILL_BLANK']
    } else return typeOfQuestion[settings.isGroup ? 'SINGLE_CHOICE_CHILD' : 'SINGLE_CHOICE']
  }
}

const mapQuestionsGroup = (questions) => {
  const listTypeGroup = ['group', 'single-choice-child', 'multiple-choice-child', 'fill-blank-child']
  let rawQuestions = questions.filter(qe => listTypeGroup.indexOf(qe.type) < 0)
  let listGroups = questions.filter(q => q.type === 'group')
  listGroups = listGroups.map(group => {
    group.children = group.questions.map(item => {
      let questionId = typeof item === 'string' ? item : item.id
      const question = questions.find(qe => qe.id === questionId)
      return {
        ...question,
        parentId: group.id
      }
    }).filter(item => item.id)
    return group
  })
  rawQuestions = rawQuestions.concat(listGroups)
  return rawQuestions.sort((a, b) => a.index - b.index)
}

const transformQuestionPart = (questionsInPart) => {
  let currentIndex = 0
  let newQuestions = questionsInPart.map(q => {
    if (q.type === 'group') {
      let newQuestion = { ...q, index: currentIndex }
      let child = q.children.map(child => {
        let newChild = { ...child, index: currentIndex }
        currentIndex++
        return newChild
      })
      newQuestion = { ...newQuestion, children: child }
      return newQuestion
    } else {
      let newQuestion = { ...q, index: currentIndex }
      currentIndex++
      return newQuestion
    }
  })
  return newQuestions
}

const setIndexRandomQuestions = (allQuestions) => {
  let questions = allQuestions.filter(q => q && !q.type.includes('child'))
  let randomQuestions = []
  let currentIndex = 0
  questions.forEach(q => {
    if (q.type === 'group') {
      randomQuestions.push({ ...q, index: currentIndex })
      q.questions.forEach(child => {
        const childQuestion = allQuestions.find(i => i.id === child.id)
        randomQuestions.push({ ...childQuestion, index: currentIndex })
        currentIndex++
      })
    } else {
      randomQuestions.push({ ...q, index: currentIndex })
      currentIndex++
    }
  })
  return randomQuestions
}

const getFromToQuestionGroup = (question) => {
  if (question.children && question.children.length > 1) {
    const listIndex = question.children.map(child => Number(child.index))
    return `${min(listIndex) + 1}-${max(listIndex) + 1}`
  }
  return 'đơn'
}

const mapQuestionsForPart = (part, initQues) => {
  Object.keys(part).forEach(key => {
    const questionsOfPart = []
    part[key].questions.map(id => {
      const existedItem = initQues.find(q => q.id === id)
      if (existedItem) {
        if (existedItem.type === 'group') {
          existedItem.questions.forEach(childId => {
            const existedChild = initQues.find(q => q.id === childId)
            if (existedChild) questionsOfPart.push(existedChild)
          })
          questionsOfPart.push(existedItem)
        } else questionsOfPart.push(existedItem)
      }
    })
    part[key].questions = questionsOfPart
  })
  return part
}

const getMaxIdx = (questions) => {
  return questions && questions.length
    ? Math.max.apply(
      Math,
      questions.map((question) => {
        return Number(question.index)
      })
    ) + 1
    : 0
}

const getTypeOfChild = (type) => {
  if (type.indexOf('child') !== -1) return type
  return `group-${type}-child`
}

const setIndexQuestions = (tree) => {
  let index = 0
  return tree.map(q => {
    let idx = index
    if (q.type === 'group') {
      q.children = q.children.map(childQ => {
        let childIdx = index
        index++
        return { ...childQ, index: childIdx }
      })
    } else index++
    return { ...q, index: idx }
  })
}

const getLengthQuestions = (questions) => {
  let count = 0
  questions.forEach(question => {
    count++
    if (question.type === 'group') {
      question.children.forEach(child => {
        if (child) count++
      })
    }
  })
  return count
}

const transformExportSubmission = (submissions, unit, currentCourse) => {
  return submissions.map(submission => {
    const startTime = dayjs(submission.startTime).valueOf()
    const endTime = dayjs(submission.submitTime).valueOf()
    const timeDoTest = submission.submitTime ? endTime - startTime : 0
    if (submission.name) {
      return {
        ...submission,
        submitCount: submission.submitCount,
        duration: timeDoTest
          ? stringUtils.secondsToHms(timeDoTest / 1000)
          : '',
        unitInfo: unit,
        currentCourse: currentCourse
      }
    } else {
      return {
        ...submission,
        submitCount: submission.submitCount,
        duration: timeDoTest
          ? stringUtils.secondsToHms(timeDoTest / 1000)
          : '',
        name: submission.user ? submission.user.name : vuetify.framework.lang.translator('$vuetify.TEXT_NOT_NAME'),
        email: submission.user ? submission.user.email : vuetify.framework.lang.translator('$vuetify.TEXT_NOT_EMAIL'),
        unitInfo: unit,
        currentCourse: currentCourse
      }
    }
  })
}

const getBase64Image = async (imgLink) => {
  if (imgLink && imgLink) {
    const data = await fetch(imgLink);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  }
  return "";
}

const preprocessQuestions = async (questions = []) => {
  // duyệt qua nội dung của các câu hỏi, nếu câu hỏi có chứa ảnh chuyển sang base64 để in ra pdf
  const imgRegex = /<img[^>]+src="([^">]+)"/gm;
  const promises = questions.map(async (question) => {
    let description = question.description || question.html || "";
    if (description) {
      const testImageRegex = imgRegex.exec(description);
      if (testImageRegex && testImageRegex.length) {
        const imgSrc = testImageRegex[1];
        const base64Src = await getBase64Image(imgSrc);
        description = description.replace(/src="([^">]+)"/gm, `src="${base64Src}"`);
      }
    }
    return {
      ...question,
      description,
    };
  });
  return Promise.all(promises);
}

export default {
  caculateTotalTimeAndScore, getDataQuestion,
  getQuestionWillDelete, convertTestQuestions,
  getNewQuestion, getTypeQuestions, getMaxIdx, getTypeOfChild,
  mapQuestionsGroup, setIndexQuestions, getLengthQuestions, flattenQuestion, mapQuestionsForPart,
  caculateTimeAndScorePart, validateQuestion, validateMark, setIndexRandomQuestions, getFromToQuestionGroup,
  transformExportSubmission, transformQuestionPart, getPathOfKnowledge, convertChildrenToArray,
  getBase64Image, preprocessQuestions
}