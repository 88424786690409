import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const Mail = RepositoryFactory.get('mail')
const namespaced = true;

const state = {
  account: {},
  template: {},
  vnpay: {},
  sendedMail: null
};

const actions = {
  async setupAccountMail({ commit }, params = {}) {
    const res = await Mail.setupAccountMail(params);
    if (res.data.gmail) commit('setAccount', res.data.gmail)
    return res.data;
  },
  async getAccountMail({ commit }) {
    const res = await Mail.getAccountMail();
    if (res.data && res.data.gmail) commit('setAccount', res.data.gmail)
    if (res.data && res.data.vnpay) commit('setAccountVnpay', res.data.vnpay)
    return res.data;
  },
  async createOrUpdateTemplate({ commit }, params = {}) {
    const res = await Mail.createOrUpdateTemplate(params);
    commit('setTemplate', [res.data])
    return res.data
  },
  async getTemplates({ commit }, params = {}) {
    const res = await Mail.getTemplates(params)
    commit('setTemplate', res.data)
    return res.data
  },
  async sendMail({ commit }, params = {}) {
    const res = await Mail.sendMail(params);
    commit('saveMail', res.data)
  },
  async sendMailToGroup({ commit, state }, params = {}) {
    const res = await Mail.sendMailToGroup(params);
    commit('saveMail', [res.data, ...state.sendedMail])
  },
  async getMail({ commit }, params = {}) {
    const res = await Mail.getMail(params);
    commit('saveMail', res.data)
  }
};

const mutations = {
  setAccount(state, account) {
    return state.account = account;
  },
  setAccountVnpay(state, account) {
    return state.vnpay = account;
  },
  setTemplate(state, templates) {
    templates.forEach(template => {
      state.template = {
        ...state.template,
        [template.name]: template.template
      }
    })
  },
  saveMail(state, mail) {
    state.sendedMail = mail
  }
};

const getters = {
  getAccount: (state) => {
    return state.account;
  },
  getAccountVnpay: (state) => {
    return state.vnpay;
  },
  getTemplate: (state) => {
    return state.template;
  },
  getMail: (state) => {
    return state.sendedMail
  }
};

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};
