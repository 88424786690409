function lazyLoad(view) {
  return () => import(`@/pages/${view}.vue`)
}
export default [
  {
    icon: "mdi-content-copy",
    type: "group",
    path: "/account/learning-content",
    name: '$vuetify.ROUTE.NAVIGATION_ROUTES.COURSE_CONTENT.TITLE',
    children: [
      {
        type: "item",
        path: "/online",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.COURSE_CONTENT.LINK_TO_ONLINE_COURSE_LIST',
        component: lazyLoad('OnlineCourse'),
      },
      {
        type: "item",
        path: "/offline",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.COURSE_CONTENT.LINK_TO_OFFLINE_COURSE_LIST',
        component: lazyLoad('OfflineCourse'),
      },
      {
        type: "item",
        path: "/learning-path",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.COURSE_CONTENT.LINK_TO_LEARNING_PATH_LIST',
        component: lazyLoad('LearningPathList'),
      },
      {
        type: "item",
        path: "/certificate",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.COURSE_CONTENT.LINK_TO_CERTIFICATE_LIST',
        component: lazyLoad('Certificate'),
      },
      {
        type: "item",
        path: "/event",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.COURSE_CONTENT.LINK_TO_EVENT_LIST',
        component: lazyLoad('EventList'),
      },
      {
        type: "item",
        path: "/ebook",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.COURSE_CONTENT.LINK_TO_EBOOK_LIST',
        component: lazyLoad('EbookList'),
      },
      {
        type: "item",
        path: "/topic",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.COURSE_CONTENT.LINK_TO_TOPIC_LIST',
        component: lazyLoad('TopicList'),
      },
      {
        type: "item",
        path: "/resource",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.COURSE_CONTENT.LINK_TO_FILE_STORAGE_LIST',
        component: lazyLoad('Library'),
      },
      {
        type: "item",
        path: "/question-bank",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.COURSE_CONTENT.LINK_TO_QUESTION_BANK_LIST',
        component: lazyLoad('QuestionBank'),
      },
      {
        type: "item",
        path: "/question-generate",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.COURSE_CONTENT.LINK_TO_STORE_EXAM_QUESTION_LIST',
        component: lazyLoad('QuestionGenerate'),
      },
      {
        type: "item",
        path: "/department",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.COURSE_CONTENT.LINK_TO_DEPARTMENT_LIST',
        component: lazyLoad('DepartmentList'),
      },
      {
        type: "item",
        path: "/assigned-homework",
        name: '$vuetify.ROUTE.TITLE_EXAM_ASSIGNED',
        component: lazyLoad('AssignedHomeworkList'),
      }
    ],
  },
  {
    icon: "mdi-view-grid-plus",
    type: "group",
    name: "$vuetify.ROUTE.NAVIGATION_ROUTES.PHYSICAL_PRODUCT.TITLE",
    children: [
      {
        type: "item",
        path: "/product",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.PHYSICAL_PRODUCT.LINK_TO_PRODUCT_LIST',
        component: lazyLoad('ProductList'),
      },
      {
        type: "item",
        path: "/product-category",
        name: 'Ngành hàng',
        component: lazyLoad('ProductCategoryList'),
      },
      {
        type: "item",
        path: "/product-coupon",
        name: 'Coupon',
        component: lazyLoad('ProductCouponList'),
      },
    ]
  },
  {
    icon: "mdi-account-circle",
    type: "group",
    name: '$vuetify.ROUTE.NAVIGATION_ROUTES.USER_MANAGE.TITLE',
    children: [
      {
        type: "item",
        path: "/students",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.USER_MANAGE.LINK_TO_STUDENT_LIST',
        component: lazyLoad('Students'),
      },
      {
        path: "/student-group",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.USER_MANAGE.LINK_TO_STUDENT_GROUP_LIST',
        component: lazyLoad('Group')
      },
      {
        type: "item",
        path: "/teachers",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.USER_MANAGE.LINK_TO_TEACHER_LIST',
        component: lazyLoad('Teachers'),
      },
      {
        type: "item",
        path: "/comments",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.USER_MANAGE.LINK_TO_COMMENT_LIST',
        component: lazyLoad('Comment'),
      },
      {
        type: "item",
        path: "/ratings",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.USER_MANAGE.LINK_TO_RATING_LIST',
        component: lazyLoad('Rating'),
      },
    ],
  },
  {
    icon: "mdi-credit-card-outline",
    type: "group",
    name: '$vuetify.ROUTE.NAVIGATION_ROUTES.FINANCE_MANAGE.TITLE',
    children: [
      {
        type: "item",
        path: "/active-code",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.FINANCE_MANAGE.LINK_TO_ACTIVATION_CODE_CAMPAIGN_LIST',
        component: lazyLoad('ActiveCodeList'),
      },
      {
        type: "item",
        path: "/coupon-code",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.FINANCE_MANAGE.LINK_TO_COUPON_CAMPAIGN_LIST',
        component: lazyLoad('CouponList'),
      },
      {
        type: "item",
        path: "/combo",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.FINANCE_MANAGE.LINK_TO_COMBO_CAMPAIGN_LIST',
        component: lazyLoad('ComboList'),
      },
      {
        type: "item",
        path: "/bills",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.FINANCE_MANAGE.LINK_TO_BILL_MANAGE_LIST',
        component: lazyLoad('BillList'),
      },
      // {
      //   type: "item",
      //   path: "/event-statistic",
      //   name: "Thống kê sự kiện",
      //   component: lazyLoad(''),
      // },
      {
        type: "item",
        path: "/transactions",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.FINANCE_MANAGE.LINK_TO_SALES_LIST',
        component: lazyLoad('Transaction'),
      },
      {
        type: "item",
        path: "/teacher-sale",
        name: '$vuetify.ROUTE.TITLE_SALES_TEACHER',
        component: lazyLoad('TeacherSale'),
      },
      {
        type: "item",
        path: "/temp-user",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.FINANCE_MANAGE.LINK_TO_CONTACT_LIST',
        component: lazyLoad('UserTemp'),
      },
      {
        type: "item",
        path: "/affiliate",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.FINANCE_MANAGE.LINK_TO_AFFILIATE_LIST',
        component: lazyLoad('Affiliate'),
      }
    ],
  },
  {
    icon: "mdi-book-multiple",
    type: "group",
    name: '$vuetify.ROUTE.NAVIGATION_ROUTES.WEBSITE_DISPLAY.TITLE',
    children: [
      // {
      //   icon: "perm_contact_calendar",
      //   path: "/theme",
      //   name: "Chủ đề",

      // },
      {
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.WEBSITE_DISPLAY.LINK_TO_MENU_SETTING',
        icon: "settings",
        path: "/menu-settings",
        component: lazyLoad('MenuSettings'),
      },
      {
        type: "plugins",
        path: "/sitebuilder",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.WEBSITE_DISPLAY.LINK_TO_SITE_BUILD',
      },
      {
        icon: "perm_contact_calendar",
        path: "/blog",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.WEBSITE_DISPLAY.LINK_TO_BLOG_LIST',
        component: lazyLoad('Blog')
      }
    ],
  },
  {
    icon: "mdi-tablet-cellphone",
    type: "group",
    name: '$vuetify.ROUTE.NAVIGATION_ROUTES.APP_DISPLAY.TITLE',
    children: [
      {
        type: "plugins",
        path: "/screenbuilder",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.APP_DISPLAY.LINK_TO_SCREEN_BIULD',
      },
    ],
  },
  {
    icon: 'mdi-cog',
    type: "group",
    name: '$vuetify.ROUTE.NAVIGATION_ROUTES.SESTING_SYSTEM.TITLE',
    children: [
      {
        type: 'item',
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.SESTING_SYSTEM.LINK_TO_BASIC_SETTING',
        path: '/bacic-settings',
        component: lazyLoad('BasicSettings'),
      },
      {
        type: 'item',
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.SESTING_SYSTEM.LINK_TO_ADVANCED_SETTING',
        path: '/advance-settings',
        component: lazyLoad('AdvanceSettings'),
      }
    ]
  },
  {
    icon: "mdi-star-circle",
    type: "group",
    name: "$vuetify.ROUTE.NAVIGATION_ROUTES.MY_ACCOUNT.TITLE",
    children: [
      {
        type: 'item',
        path: "/admin-management-account",
        name: 'Quản lý tài khoản admin',
        component: lazyLoad('AdminManagementAccount'),
      },
      {
        type: 'item',
        path: "/change-password",
        name: '$vuetify.ROUTE.NAVIGATION_ROUTES.MY_ACCOUNT.LINK_TO_CHANGE_PASSWORD_SETTING',
        component: lazyLoad('ChangePassword'),
      },
    ],
  },
]