import dayjs from 'dayjs'
import get from 'lodash-es/get'
import helpers from '@/helpers'
import auth from '@/store/modules/auth'
import stringUtils from './components/stringUtils'
import examUtils from './components/examUtils'
import matrixUtils from './components/matrixUtils'
import imageEditorUtils from './components/imageEditorUtils'
import mimeTypes from 'mime-types'
import vuetify from '@/plugins/vuetify'

const onDownloadSyllabus = syllabus => {
	const file = syllabus
	if (!file) return
	const { origin = {} } = file
	let { link = '' } = origin
	if (!link) return
	if (!link.includes('http')) link = combineLinkWithBucket(link)
	window.open(convertURL(link), '_blank')
}

const combineLinkWithBucket = linkResource => {
	if (linkResource && linkResource.includes('http')) {
		return linkResource
	} else {
		return process.env.VUE_APP_AWS_BUCKET_S3 + '/' + linkResource
	}
}

const isAdmin = () => {
	return getRolePermission() && getRolePermission().name === 'admin'
}
const isKol = () => {
	return getRolePermission() && getRolePermission().name === 'kol'
}

const isTeacher = () => {
	return getRolePermission() && getRolePermission().name === 'teacher'
}

const exportFileExcel = async (data, fileName) => {
	const XLSX = await import('xlsx')
	let wb = XLSX.utils.book_new()
	let wsStudent = XLSX.utils.json_to_sheet(data)
	XLSX.utils.book_append_sheet(wb, wsStudent, 'result')
	XLSX.writeFile(wb, `${fileName}-${dayjs().format('DD/MM/YYYY')}.xlsx`)
}
const mapDataBillExportExcel = (bills) => {
	return bills.map((bill) => {
		const {
			customerName,
			customerEmail,
			date,
			time,
			content,
			price,
			priceBeforeDiscount,
			couponTitle,
			payment,
			currency,
			state,
		} = bill;
		let stateTrans = ''
		if (state === 'rejected') stateTrans = 'Từ chối'
		else if (state === 'returned') stateTrans = 'Đã trả'
		else if (state === 'success') stateTrans = 'Hoàn tất'
		else if (state === 'failed') stateTrans = 'Thất bại'
		else if (state === 'pending') stateTrans = 'Đang chờ'
		return {
			[`Tên khách hàng`]: customerName,
			[`Email khách hàng`]: customerEmail,
			[`Ngày tạo`]: date,
			[`Thời gian tạo`]: time,
			[`Nội dung`]: content,
			[`Giá tiền chính thức`]: price,
			[`Giá tiền trước khuyến mãi`]: priceBeforeDiscount,
			[`Tên Coupon`]: couponTitle,
			[`Hình thức thanh toán`]: payment,
			[`Đơn vị tiền`]: currency,
			[`Trạng thái`]: stateTrans,
		};
	});
}

const convertDuration = duration => {
	let tDuration = duration || 0
	if (isNaN(tDuration)) {
		return tDuration
	} else {
		let tH = Math.floor(tDuration / (60 * 60))
		if (tH > 9) {
			let tH2 = Math.round(tDuration / (60 * 60))
			if (tH2 > tH) {
				return `${vuetify.framework.lang.translator('$vuetify.TEXT_ALMOST', tH2)}`
			} else if (tH == tH2) {
				if (tH * 60 * 60 == tDuration) {
					return `${tH2} ${vuetify.framework.lang.translator('$vuetify.TEXT_HOUR')}`
				} else {
					return `${vuetify.framework.lang.translator('$vuetify.TEXT_MORE_THAN', tH2)}`
				}
			}
		} else if (tH > 0) {
			let tM = Math.round((tDuration - tH * 60 * 60) / 60)
			if (tM > 0) {
				return `${tH} ${vuetify.framework.lang.translator('$vuetify.TEXT_HOUR')} ${tM} ${vuetify.framework.lang.translator('$vuetify.TEXT_MINUTE')}`
			}
			return `${tH} ${vuetify.framework.lang.translator('$vuetify.TEXT_HOUR')}`
		} else {
			let tM = Math.round(tDuration / 60)
			if (tM > 0) return `${tM} ${vuetify.framework.lang.translator('$vuetify.TEXT_MINUTE')}`
			return `${tDuration} ${vuetify.framework.lang.translator('$vuetify.TEXT_SECOND')}`
		}
	}
}

const convertURL = (url, withoutCloudfront) => {
	if (!url) return url
	let convertedUrl = url
	if (!url.includes('https') && url.includes('http')) {
		let urlWithHttps = url.replace('http', 'https')
		convertedUrl = urlWithHttps
	}
	if (
		convertedUrl.includes(process.env.VUE_APP_AWS_BUCKET_S3) &&
		!withoutCloudfront
	) {
		let urlCloudfront = convertedUrl.replace(
			process.env.VUE_APP_AWS_BUCKET_S3,
			process.env.VUE_APP_AWS_BUCKET_CLOUD_FRONT
		)
		convertedUrl = urlCloudfront
	}
	if (
		convertedUrl.includes(process.env.VUE_APP_AWS_OLD_BUCKET_S3) &&
		!withoutCloudfront
	) {
		let urlCloudfront = convertedUrl.replace(
			process.env.VUE_APP_AWS_OLD_BUCKET_S3,
			process.env.VUE_APP_AWS_BUCKET_CLOUD_FRONT
		)
		convertedUrl = urlCloudfront
	}
	if (
		convertedUrl.includes(process.env.VUE_APP_AWS_OLD_BUCKET_S3_2) &&
		!withoutCloudfront
	) {
		let urlCloudfront = convertedUrl.replace(
			process.env.VUE_APP_AWS_OLD_BUCKET_S3_2,
			process.env.VUE_APP_AWS_BUCKET_CLOUD_FRONT
		)
		convertedUrl = urlCloudfront
	}
	return convertedUrl
}

const getTypeNameUnit = function (type) {
	switch (type) {
		case 'video':
		case 'vimeo':
			return vuetify.framework.lang.translator('$vuetify.LABEL_VIDEO')
		case 'pdf':
			return vuetify.framework.lang.translator('$vuetify.LABEL_PDF_SLIDE')
		case 'test':
			return vuetify.framework.lang.translator('$vuetify.LABEL_TEST')
		case 'exercise':
			return vuetify.framework.lang.translator('$vuetify.LABEL_EXERCISE')
		case 'homework':
			return vuetify.framework.lang.translator('$vuetify.LABEL_HOMEWORK')
		case 'matrix':
			return vuetify.framework.lang.translator('$vuetify.LABEL_MATRIX')
	}
}
const validateTimeForLesson = setting => {
	if (setting && setting.hasRangeTime) {
		const timeZone = dayjs().valueOf()
		const startTime = dayjs(
			`${setting.rangeTimeFromDate}T${setting.rangeTimeFromHour}:00.000+07:00`
		).valueOf()
		const endTime = dayjs(
			`${setting.rangeTimeToDate}T${setting.rangeTimeToHour}:00.000+07:00`
		).valueOf()
		return timeZone > startTime && timeZone < endTime
	} else return true
}

const getLinkWithoutCache = link => {
	const convertedLink = convertURL(link)
	return `${convertedLink}?time=${Date.now()}`
}

const getRolePermission = () => {
	const { PERMISSION } = helpers
	const role = get(auth.state.auth, 'roles.0')
	const rolePermissionKey = Object.keys(PERMISSION).find(key => {
		return PERMISSION[key] && PERMISSION[key].name === role
	})
	const rolePermission = PERMISSION[rolePermissionKey]
	return rolePermission || {}
}

const importFileExcelAndGetData = async (
	file,
	colRangeIndex,
	richCellRangeStart = 0,
	richCellRangeEnd = 0
) => {
	const XLSX = await import('xlsx')
	let dataExcel = []
	const reader = new FileReader()
	return new Promise((resolve, reject) => {
		reader.onload = async evt => {
			const bstr = evt.target.result
			const wb = XLSX.read(bstr, { type: 'binary' })
			wb.SheetNames.forEach(sheetName => {
				const currentSheet = wb.Sheets[sheetName]
				const colRange = get(currentSheet['!ref'], '3', '')
				if (colRange && colRange == helpers.DEFAULT_LABELS[colRangeIndex]) {
					const rows = XLSX.utils.sheet_to_json(currentSheet, { defval: '' })
					rows.forEach((row, index) => {
						dataExcel.push({
							...row,
							..._richCellExtract(
								row,
								index,
								currentSheet,
								richCellRangeStart,
								richCellRangeEnd
							),
							rowIndex: index
						})
					})
				} else {
					reject({
						type: 'wrong_template',
						message: vuetify.framework.lang.translator('$vuetify.MSG_FILE_FOMAT_FALSE')
					})
				}
			})
			resolve(dataExcel)
		}
		reader.readAsBinaryString(file)
	})
}

const _richCellExtract = (
	row,
	rowIndex,
	currentSheet,
	rangeStart = 0,
	rangeEnd = 0
) => {
	const rowKeys = Object.keys(row)
	const richCells = {}
	for (let i = rangeStart; i < rangeEnd; i++) {
		const cell = row[rowKeys[i]]
		if (cell) {
			richCells[rowKeys[i]] = get(
				currentSheet[helpers.DEFAULT_LABELS[i] + (rowIndex + 2)],
				'h',
				cell
			)
		}
	}
	return richCells
}

const getDateFromSetting = (settings, type) => {
	if (!settings.hasRangeTime) return
	if (type === 'start') {
		return new Date(
			settings.rangeTimeFromHour + ' ' + settings.rangeTimeFromDate
		)
	} else if (type === 'end') {
		return new Date(settings.rangeTimeToHour + ' ' + settings.rangeTimeToDate)
	}
}

const isImage = extension => {
	return /\.(gif|jpe?g|tiff?|png|webp|bmp)/i.test(`.${extension}`)
}

const isVideo = extension => {
	return /\.(mp4|mkv|m4v|mov)/i.test(`.${extension}`)
}

const delay = ms => new Promise(res => setTimeout(res, ms))

const getBillStatus = status => {
	switch (status) {
		case helpers.BILL_STATE.REJECTED:
			return vuetify.framework.lang.translator('$vuetify.REJECTED')
		case helpers.BILL_STATE.SUCCESS:
			return vuetify.framework.lang.translator('$vuetify.SUCCESS')
		case helpers.BILL_STATE.PENDING:
			return vuetify.framework.lang.translator('$vuetify.PENDING')
		case helpers.BILL_STATE.FAILED:
			return vuetify.framework.lang.translator('$vuetify.FAILED')
		case helpers.BILL_STATE.RETURNED:
			return vuetify.framework.lang.translator('$vuetify.RETURNED')
		default:
			return ''
	}
}

const getBillStatusColor = state => {
	switch (state) {
		case helpers.BILL_STATE.SUCCESS:
			return '#4BCA81'
		case helpers.BILL_STATE.PENDING:
			return '#FF1D30'
		case helpers.BILL_STATE.REJECTED:
			return '#9C9C9C'
		default:
			return '#9C9C9C'
	}
}

const getPaymentMethod = method => {
	switch (method) {
		case helpers.PAYMENT_METHOD.HAND_ON:
			return vuetify.framework.lang.translator('$vuetify.LABEL_TRANSFER')
		case helpers.PAYMENT_METHOD.BANK_TRANSFER:
			return vuetify.framework.lang.translator('$vuetify.LABEL_TRANSFER')
		case helpers.PAYMENT_METHOD.DIRECT_PAY:
			return vuetify.framework.lang.translator('$vuetify.LABEL_DIRECT_PAY')
		case helpers.PAYMENT_METHOD.VN_PAY:
			return vuetify.framework.lang.translator('$vuetify.LABEL_VN_PAY')
		case helpers.PAYMENT_METHOD.COD:
			return 'COD'
		case helpers.PAYMENT_METHOD.ADMIN:
			return vuetify.framework.lang.translator('$vuetify.LABEL_ADMIN_CREATED')
		case helpers.PAYMENT_METHOD.ACTIVE_CODE:
			return vuetify.framework.lang.translator('$vuetify.LABEL_ACTIVATION_CODE')
		default:
			return ''
	}
}

const getVimeoDuration = (courseUnits = [], currentUnit = {}) => {
	if (Array.isArray(courseUnits) && courseUnits.length) {
		return getAllVideoDurationFromUnits(
			courseUnits.filter(unit => unit.id !== currentUnit.id)
		)
	}
	return 0
}

const getExamDuration = (exams, currentUnit) => {
	return exams
		.filter(exam => {
			return (
				exam.id !== currentUnit.exam &&
				exam.settings &&
				(exam.settings.hasLimitedTime || exam.settings.isUseCountDownTime)
			)
		})
		.map(v => parseInt(v.settings.expiredTime))
		.reduce((a, b) => a + b, 0)
}

const isUpdateDuration = (type, course) => {
	return (
		(type === 'vimeo' ||
			type === 'video' ||
			type === 'test' ||
			type === 'matrix' ||
			type === 'exercise') &&
		course.data &&
		course.data.isCalculateDuration
	)
}

const convertDateToVietnamese = (
	date,
	isReturnToISO = false,
	format = 'DD/MM/YYYY'
) => {
	const customParseFormat = require('dayjs/plugin/customParseFormat')
	dayjs.extend(customParseFormat)
	if (date) {
		if (isReturnToISO) return dayjs(date, format).toISOString()
		return dayjs(date, format)
	}
	return ''
}

const getAllVideoDurationFromUnits = (units = []) => {
	if (Array.isArray(units) && units.length) {
		return units
			.filter(unit => {
				if (unit.vimeo && unit.vimeo.duration) return true
				if (unit.video && unit.video.provider === 'VSTORAGE') return true
				return false
			})
			.map(unit => {
				let duration = 0
				const unitVideo = unit.video
				const unitVimeo = unit.vimeo
				if (unitVideo && unitVideo.provider === 'VSTORAGE') {
					duration = get(unitVideo, 'metadata.duration') ||
						get(unitVideo, 'video.origin.duration') ||
						get(unitVideo, 'duration') ||
						0
				} else if (unitVimeo && unitVimeo.duration) {
					duration = unitVimeo.duration
				}
				return duration
			})
			.reduce((a, b) => a + b, 0)
	}
	return 0
}

const convertDateToDateTimeVietnamese = (date, hasSecondInTime = true) => {
	const displayedDate = dayjs(date).format('DD/MM/YYYY')
	const displayedTime = dayjs(date).format(hasSecondInTime ? 'HH:mm:ss' : 'HH:mm')
	return `${displayedDate}, ${vuetify.framework.lang.translator('$vuetify.TEXT_WHEN')} ${displayedTime}`
}

const parseFileSize = (size = 0, thres = 1024) => {
	if (isNaN(size) || Number.isNaN(size)) return ''
	const fileSizeTypes = ['B', 'KB', 'MB', 'GB', 'TB']
	let result = size, count = 0
	while (result >= thres) {
		result /= thres
		++count
	}
	return `${result.toFixed(2)} ${fileSizeTypes[count]}`
}

const getPartsOfName = (str = '') => {
	const formatted = stringUtils.clearUnicode(str)
	const nameArr = formatted.split('-')
	let firstName = '', middleName = '', lastName = ''
	if (nameArr.length > 2) {
		firstName = nameArr[nameArr.length - 1]
		middleName = nameArr.slice(1, nameArr.length - 1).join(' ')
		lastName = nameArr[0]
	} else if (nameArr.length > 1) {
		firstName = nameArr[nameArr.length - 1]
		lastName = nameArr[0]
	} else {
		firstName = nameArr[0]
	}
	return { firstName, middleName, lastName }
}

const sortByNameInAlphabetical = (acc = {}, cur = {}) => {
	const accName = getPartsOfName(acc.name)
	const curName = getPartsOfName(cur.name)
	const sortByFirstName = accName.firstName.localeCompare(curName.firstName)
	if (sortByFirstName !== 0) return sortByFirstName
	const sortByLastName = accName.lastName.localeCompare(curName.lastName)
	if (sortByLastName !== 0) return sortByLastName
	return accName.middleName.localeCompare(curName.middleName)
}

const checkAllFilesInHomeworkImage = (homeworkFiles = []) => {
	let result = false
	if (Array.isArray(homeworkFiles) && homeworkFiles.length) {
		const numberOfImages = homeworkFiles.filter(item => {
			if (item.link && item.link.length) {
				return isImage(getExtensionOfResource(item))
			}
			return false
		}).length
		result = numberOfImages === homeworkFiles.length
	}
	return result
}

const getExtensionOfResource = (item) => {
	return mimeTypes.extension(get(item, 'origin.mimeType', ''))
}

const sortWithType = (item, type) => {
	let newItem = item.sort((a, b) => {
		if (type === "alphabet") {
			let firstString = stringUtils.clearUnicode(a.title)
			let secondString = stringUtils.clearUnicode(b.title)
			return firstString.localeCompare(secondString);
		}
		else if (type === "createdAt")
			return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf();
		else return a.index - b.index;
	});
	return newItem.map((obj, index) => { return { ...obj, index: index } })
}

const renMathInElem = (elem) => {
	let rootElem = document.body
	if (elem) rootElem = elem
	window.renderMathInElement(rootElem, {
		delimiters: [
			{left: '$$', right: '$$', display: true},
			{left: '$', right: '$', display: false},
		],
		throwOnError : false,
		output: 'html',
		strict: false
	});
}

export default {
	sortWithType,
	combineLinkWithBucket,
	convertURL,
	onDownloadSyllabus,
	validateTimeForLesson,
	getLinkWithoutCache,
	getRolePermission,
	isAdmin,
	isTeacher,
	isKol,
	getTypeNameUnit,
	exportFileExcel,
	importFileExcelAndGetData,
	getDateFromSetting,
	convertDuration,
	isImage,
	delay,
	getBillStatus,
	getBillStatusColor,
	getPaymentMethod,
	getVimeoDuration,
	getExamDuration,
	isUpdateDuration,
	isVideo,
	dayjs,
	...stringUtils,
	...examUtils,
	...matrixUtils,
	...imageEditorUtils,
	convertDateToVietnamese,
	getAllVideoDurationFromUnits,
	convertDateToDateTimeVietnamese,
	parseFileSize,
	getPartsOfName,
	sortByNameInAlphabetical,
	checkAllFilesInHomeworkImage,
	getExtensionOfResource,
	mapDataBillExportExcel,
	renMathInElem,
}
