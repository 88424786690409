// LEARNING ROAD
const LEARNING_PATH_LIST = {
	TITLE: 'Learning Path',
	BUTTON_CREATE: 'Create route',
	LABEL_FILTER_BY_NAME: 'Route name',
	PLACEHOLDER_FILTER_BY_NAME: 'Search by route name',
	//TABLE HEADER
	TH_NAME: 'Route name',
	TH_CREATE_DATE: 'Date Created',
	TH_STATUS: 'Status',
	TH_ACTION: 'Action',
	TEXT_PUBLISHED: 'Published',
	TEXT_UNPUBLISH: 'Unpublished',
	TOOLTIP_EDIT_LEARNING_PATH: 'Edit learning path',
	//EMPTY_DATA
	TITLE_EMPTY_DATA: 'No learning path exists',
	SUBTITLE_EMPTY_DATA: 'Please create a new route to show on this listing',

	TOOLTIP_PUSH_TO_HIGHLIGHTS_LIST: 'Put this route in the featured route list',
	TOOLTIP_REMOVE_OUT_HIGHLIGHTS_LIST:
		'Remove this route from featured route list',

	//BASE_LEARNING_PATH_PICKER
	LABEL_SELECT_LEARNING_PATH: 'Select a learning route',
	PLACEHOLDER_FILTER_LEARNING_PATH: 'Search for learning routes'
}
const FORM_CREATE_NEW_LEARNING_PATH = {
	TITLE: 'New learning path',
	LABEL_NAME: 'Route name',
	PLACEHOLDER_INPUT_NAME: 'Enter course name',

	//MESSAGE
	MSG_CREATING: 'Creating a learning path...',
	MSG_CREATE_SUCCESS: 'Successfully created new learning path',
	MSG_CHECK_NAME_AGAIN: 'Route name already taken!',
	MSG_CREATE_FAIL: 'Create new learning path failed'
}
const LEARNING_PATH_DETAIL = {
	LINK_RETURN_TO_LEARNING_PATH_LIST: 'List of Learning Paths',
	//TAB
	TAB_INFO: 'Information',
	TAB_PUBLISH: 'Publish',
	TAB_COURSE: 'Course',
	TAB_DELETE: 'Delete the learning path',
	PATH_INFO: {
		BASIC_INFO: {
			TITLE: 'Basic Info',
			SUBTITLE: 'Setting the learning path name, category, and difficulty level',
			LABEL_NAME: 'Route name (Up to 120 characters)',
			PLACEHOLDER_INPUT_NAME: 'Enter route name',
			LAVEL_DIFICUL_LEVEL: 'Difficulty level',
			PLACEHOLDER_SELECT_TYPE: 'Select Type',
			TEXT_BEGINNER: 'Basic',
			OPTION_IMMEDIATE: 'Advanced',
			OPTION_EXPERT: 'Fluent',
			LABEL_CATEGORY: 'Topic',
			PLACEHOLDER_SELECT_TOPIC: 'Select topic',
			BUTTON_ADD_CATEGORY: 'Create more categories',
		},
		//DESCRIPTION_SHORT
		DESCRIPTION_SHORT: {
			TITLE: 'Short description',
			SUBTITLE: 'Setting short description',
			LABEL_DESCRIPTION_ON_CARD: 'Short description on card',
			PLACEHOLDER_INPUT_DESCRIPTION_CARD: 'Enter description (max 200 characters)'

		},
		//DESCRIPTION_INFO
		DESCRIPTION_INFO: {
			TITLE: 'Detailed Description',
			SUBTITLE: 'Provide a description so students can refer to and better understand the learning path'
		},
		COVER_IMAGE: {
			TITLE: 'Background image in detail page',
			SUBTITLE: 'Configure the background image displayed on the detail page of learning path. Recommended size: 1920x400px'
		},
		PREVIEW_IMAGE: {
			TITLE: 'Card avatar',
			SUBTITLE: 'Configure the thumbnail on the card. Recommended size: 240x360px'
		},
		DURATION_SETTING: {
			TITLE: 'Duration',
			SUBTITLE: 'Set course duration description.',
			RADIO_INPUT_DESCRIPTION: 'Input yourself',
			RADIO_ALLOW_SYSTEM_CACULATE:
				"Enable auto-calculate duration based on learning-path's courses",
			LABEL_COURSE_TIME: 'Course duration',
			PLACEHOLDER_INPUT_COURSE_TIME: 'Enter duration',
			HINT_COURSE_TIME:
				"Note: Courses that don't have auto-duration installed will not count towards the total route length."
		},
		CERTIFICATE_SETTING: {
			TITLE: 'Applied certificates',
			SUBTITLE:
				'Configure certificates to be issued to students upon completion of the learning path.',
			LABEL_ALLOW_USE: 'Enable',
			LABEL_VIEW_DETAIL:
				'You already have a certificate for this pathway. View certificate details',
			LINK_GO_TO_CERTIFICATE: 'Here',
			LABEL_CREATE_NEW:
				"Certificates haven't been set up yet. Please create a new certificate",
		},
		INTERACTION_SETTING: {
			TITLE: 'Students assessment',
			LABEL_ALLOW_AND_DISPLAY:
				'Allow students to evaluate and display the assessment corresponding to the route',
			LABEL_REQUIRED_BROWSER:
				'Review review required before being posted publicly on the site'
		}
	},
	PUBLISH_SETTING: {
		TITLE: 'Publish the learning curve',
		SUBTITLE:
			'Publish this learning path and displayed on listing pages (this change does not apply to learning path with limited display).',
		BUTTON_STOP: 'Stop publishing',
		BUTTON_PUBLISH: 'Publish',
		POPUP_CONFIRM_STOP_TITLE:
			'Are you sure you want to stop publishing this course?',
		POPUP_CONFIRM_STOP_MESSAGE:
			'The learning route will no longer be displayed on the website.'
	},
	COURSE_IN_PATH: {
		TITLE: 'Course List',
		POPUP_CONFIRM_SAVE_TITLE: 'Course save warning',
		POPUP_CONFIRM_SAVE_MESSAGE:
			'There exists a course that does not automatically calculate the duration. That course will not count towards the length of the route.',

		TITLE_TEMPTY_DATA: 'No courses yet, please add courses!',
		LABEL_PUBLISHED: 'Published',
		LABEL_NOT_PUBLISHED: 'Unpublished',

		TOOLTIP_AUTO_TIME_SETTINGS_NOT_SET_UP:
			'The course has not been set to automatically calculate the duration',
		TOOLTIP_REMOVE: 'Remove course',

		POPUP_CONFIRM_EXIT_TITLE: 'Unsaved changes',
		POPUP_CONFIRM_EXIT_MESSAGE:
			'You have unsaved settings. Please complete before leaving.',

		POPUP_CONFIRM_REMOVE_TITLE: 'Confirm removal of course',
		POPUP_CONFIRM_REMOVE_MESSAGE:
			'Are you sure you want to remove the course from this route?'
	},
	DELETION: {
		TITLE: 'Delete the learning path',
		SUBTITLE: 'Learning path will be deleted completely. Note: Deleted learning path cannot be restored',
		BUTTON: 'Delete route',
		MSG_DELETING: 'Deleting route',

		POPUP_CONFIRM_DELETE_TITLE: 'Delete the learning path from the system',
		POPUP_CONFIRM_DELETE_MESSAGE: 'Data after deletion cannot be recovered'
	},
	MSG_UPDATE_SUCCESS: 'Document update successful',
	MSG_USED_NAME: 'Route name already in use!',
	MSG_UPDATE_FAIL: 'Update failed!'
}
//EVENTS
const EVENT_LIST = {
	TITLE: 'Events',
	BUTTON_CREATE: 'Create event',
	LABEL_FILTER_BY_NAME: 'Event name',
	PLACEHOLDER_FILTER_BY_NAME: 'Search by event name',
	TITLE_EMPTY_DATA: 'No event exists',
	SUBTITLE_EMPTY_DATA: 'Please create a new event to show on this list',
	//TABLE HEADER
	TH_NAME: 'Event name',
	TH_AVAILABEL_TIME: 'Start time',
	TH_CREATE_DATE: 'Date Created',
	TH_ACTION: 'Action',
	TOOLTIP_EDIT_EVENT: 'Edit Event',
}
const FORM_CREATE_NEW_EVENT = {
	TITLE: 'New event',
	LABEL_NAME: 'Event name',
	PLACEHOLDER_INPUT_NAME: 'Enter event name',
	LABEL_DESCRIPTION: 'Event description',
	PLACEHOLDER_INPUT_DESCRIPTION: 'Enter event description',

	//MESSAGE
	MSG_CREATING: 'Creating a event ...',
	MSG_CREATE_SUCCESS: 'Successfully created new event',
	MSG_CHECK_NAME_AGAIN: 'Event name already taken!',
	MSG_CREATE_FAIL: 'Create new event failed',

	//TIME
	LABEL_AVAILABLE_DATE: 'Start Date',
	PLACEHOLDER_SELECT_AVAILABLE_DATE: 'Select start date',
	LABEL_AVAILABLE_HOUR: 'Start Time',
	PLACEHOLDER_SELECT_LABEL_AVAILABLE_HOUR: 'Select start time',
	LABEL_EXPIRED_DATE: 'End Date',
	PLACEHOLDER_SELECT_EXPIRED_DATE: 'Select end date',
	LABEL_EXPIRED_HOUR: 'Time End',
	PLACEHOLDER_SELECT_EXPIRED_HOUR: 'Select end time',

}
const EVENT_DETAIL = {
	TITLE: 'New Event',
	LINK_RETURN_TO_EVENT_LIST: 'Event List',
	//TAB
	TAB_BASIC_INFO: 'Basic Info',
	TAB_ADVANCE_INFO: 'Advanced Info',
	TAB_PRICE: 'Price',
	TAB_PUBLISH: 'Publish',
	TAB_DELETE: 'Delete event',
	BASIC_INFO: {
		ON_CARD: {
			TITLE: 'Basic Info',
			LABEL_HEADER: 'Event Name',
			PLACEHOLDER_INPUT_HEADER: 'Enter event title',
			TITLE_SHORT_DESCRIPTION: 'Short description',
			SUBTITLE_SHORT_DESCRIPTION: 'Provide a description for a quick introduction to the event on the listing page.',
			LABEL_SHORT_DESCRIPTION: 'Short description (Recommended length: 200 characters)',
			PLACEHOLDER_INPUT_SHORT_DESCRIPTION: 'Enter event description',
			HINT_DESCRIPTION: 'Recommended length: 200 characters',
			MSG_REQUEST_INPUT_HEADER: 'Please enter event title!'
		},
		ADDRESS: {
			TITLE: 'Location organization',
			RADIO_OUTSIDE: 'Other location',
			LABEL_INPUT_ADDRESS_OF_EVENT: 'Enter address',
			RADIO_TRAINING_DEPARTMENT: "The list of facility",
			LABEL_PICK_DEPARTMENT: 'Select facility'
		},
		TIME: {
			TITLE: 'Event time',
			LABEL_AVAILABLE_DATE: 'Start Date',
			PLACEHOLDER_SELECT_AVAILABLE_DATE: 'Select start date',
			LABEL_AVAILABLE_HOUR: 'Start Time',
			PLACEHOLDER_SELECT_LABEL_AVAILABLE_HOUR: 'Select start time',
			LABEL_EXPIRED_DATE: 'End Date',
			PLACEHOLDER_SELECT_EXPIRED_DATE: 'Select end date',
			LABEL_EXPIRED_HOUR: 'End Time',
			PLACEHOLDER_SELECT_EXPIRED_HOUR: 'Select end time',
			MSG_CHECK_INFO_AGAIN: 'Please check time information again!'
		},
		PREVIEW_IMAGE: {
			TITLE: 'Thumbnail',
			SUBTITLE: 'Configure the thumbnail on the card. Recommended size: {0}'
		},
		COVER_IMAGE: {
			TITLE: 'Event cover photo',
			SUBTITLE: 'Configure the picture displayed on top of the course detail page. Recommended size: {0}'
		}
	},
	INFO_ADVANCE_IMAGE: {
		TITLE: 'Event description photo (up to 6 photos)',
		SUBTITLE: 'Recommended size: 400x400px'
	},
	//INF_ADVANCE_DESCRIPTION
	TITLE_DESCRIPTION_DETAIL: 'Detail description',
	SUBTITLE_DESCRIPTION_DETAIL: 'Provide a description so students can refer to and better understand the event on a private introduction page',
	//INFO_PRICE
	PRICE_INFO: {
		TITLE: 'Price',
		SUBTITLE: 'Configure displayed price for this event.',
		RADIO_FREE: 'Free option',
		RADIO_PAY_ONCE: 'One-time pay option',
		LABEL_OFFICIAL: 'Final price',
		SUFFIX_CURRENT_UNIT: 'VND',
		LABEL_AFTER_DISCOUNT: 'Price before discount',
		HINT_AFTER_DISCOUNT: 'Leave blank if there is no discount',

		MSG_RECHECK_INFO: 'Please double check the information!'
	},
	PUBLISHING: {
		TITLE_STOP_PUBLISH: 'Stop publishing this event',
		SUBTITLE_STOP_PUBLISH:'Stop publishing the course to hide the course on listing pages.',
		TITLE_PUBLISH_COURSE: 'Publish event',
		SUBTITLE_PUBLISH:'Publish this event and displayed on listing pages (This change does not apply to course with limited display).',
		BUTTON_PUBLISH: 'Publish',
		BUTTON_STOP_PUBLISH: 'Stop publish',
		POPUP_CONFIRM_STOP_TITLE: 'Are you sure you want to stop publish this event?',
		POPUP_CONFIRM_STOP_MESSAGE:'Stop publishing the event to hide the event on listing pages.'
	},
	DELETION: {
		TITLE: 'Delete event',
		SUBTITLE: 'Delete the event. Note: Deleted event cannot be recovered',
		BUTTON: 'Delete event',
		POPUP_CONFIRM_TITLE: 'Delete event from system',
		POPUP_CONFIRM_MESSAGE: 'Data after deletion cannot be recovered',
		MSG_DELETING: 'Deleting event...',
		MSG_DELETE_SUCCESS: 'Delete event successful'
	},
	MSG_DOWNLOAD_EVENT_DETAIL_LOADING: 'Loading event details...',
	MSG_RECHECK_EVENT_TITLE: 'Please enter event title!',
	MSG_RECHECK_TIME_EVENT: 'Please check the event time again!',
	MSG_CREATING_EVENT: 'Creating event...',
	MSG_CREATE_EVENT_FAIL: 'Error generating event!',
	MSG_UPDATING_EVENT: 'Updating event...',
	MSG_UPDATE_EVENT_SUCCESS: 'Update event successful',
	MSG_UPDATE_EVENT_FAIL: 'Error updating event!'
}
//EBOOKS
const EBOOK_LIST = {
	TITLE: 'E-Book',
	BUTTON_CREATE: 'Create Book',
	LABEL_FILTER_BY_NAME: 'Document Name',
	PLACEHOLDER_FILTER_BY_NAME: 'Search by document name',
	//TABLE HEADER
	TH_NAME: 'Document Name',
	TH_AUTHOR: 'Author',
	TH_UPDATE: 'Update',
	TH_ACTION: 'Action',
	TOOLTIP_EDIT_EBOOK: 'Edit E-Book',
	//EMPTY_DATA
	TITLE_EMPTY_DATA: 'No eBooks yet',
	SUBTITLE_EMPTY_DATA: 'Please create a new eBook to show on this listing'
}
const EBOOK_DETAIL = {
	TITLE: 'E-Book List',
	TITLE_NEW_DOCUMENT: 'New Document',
	//TAB
	TAB_BASIC_INFO: 'Infomation',
	TAB_CONTENT: 'Content',
	TAB_PRICE_AND_OWNERSHIP: 'Price and ownership',
	TAB_SETTING_INTERACT: 'Interactive settings',
	TAB_DELETE: 'Delete eBook',
	//BASIC_INFO
	BASIC_INFO: {
		ON_CARD: {
			TITLE: 'Basic information',
			LABEL_NAME: 'Ebook name',
			PLACEHOLDER_INPUT_NAME: 'Insert eBook title',
			LABEL_TEACHER_UNDERTAKE: 'Author/Instructor',
			PLACEHOLDER_SELECT_TEACHER_UNDERTAKE: 'Select trainer',
			BUTTON_CREATE_TEACHER: 'Create more instructors',
			LABEL_CATEGORY: 'Topic',
			PLACEHOLDER_SELECT_TOPIC: 'Select topic',
			BUTTON_CREATE_CATEGORY: 'Create more categories',
		},
		SHORT_DESCRIPTION: {
			TITLE: 'Short description',
			SUBTITLE: 'Provide a description for a quick introduction to the eBook on the listing page',
			LABEL_DESCRIPTION: 'Short description (Recommended length: 200 characters)',
			PLACEHOLDER_INPUT_DESCRIPTION: 'Enter description',
		},
		DESCRIPTION_DETAIL: {
			TITLE: 'Detail description',
			SUBTITLE: 'Provide a description so students can refer to and better understand the ebook on a private introduction page'
		},
		PREVIEW_IMAGE: {
			TITLE: 'Thumbnail',
			SUBTITLE: 'Configure the thumbnail on the card. Recommended size: {0}'
		},
		COVER_IMAGE: {
			TITLE: 'Cover photo of eBook',
			SUBTITLE: 'Configure the picture displayed on top of the course detail page. Recommended size: {0}'
		}
	},
	//ADVANCE_INFO
	TITLE_UPLOAD_IMAGE_TYPE_PDF: 'Book content (PDF)',
	TITLE_DESCRIPTION_DETAIL: 'Detailed description',
	PRICE_INFO: {
		TITLE: 'Price and ownership',
		RADIO_FREE: 'Free option',
		RADIO_PAY_ONCE: 'One-Time pay option',
		LABEL_PRICE_OFFICIAL: 'Final price',
		LABEL_CURRENT_UNIT: 'VND',
		LABEL_VALUE_AFTER_DISCOUNT: 'Price before discount',
		HINT_PRICE_AFTER_DISCOUNT: 'Leave blank if there is no discount',
		RADIO_LOCK_PURCHASE:
			'Students cannot purchase this eBook. They can only enroll this eBook by using an active code or being granted permission.',
	},
	//INTERACTION_SETTING
	TITLE_INTERACTION_OF_STUDENT: 'Rating',
	SUBTITLE_INTERACTION_OF_STUDENT: 'Configure eBook rating rules',
	CHECKBOX_STUDENT_CAN_ENABLE_RATE:
		'Configuring eBook rating rules',
	CHECKBOX_MUST_APPROVE_RATE:
		'Only display approved ratings',
	
	
	//DISPLAY_SETTING
	TITLE_SETTING_DISPLAY: 'Display',
	SUBTITLE_SETTING_DISPLAY: 'Configure the display of eBook on the Learning profile page',
	CHECKBOX_VISIBLE_HIDDEN: 'Students who own eBook can see them on the Learning profile page',

	DELETION: {
		TITLE: 'Delete eBook',
		SUBTITLE: 'Delete the eBook. Note: Deleted eBook cannot be recovered',
		BUTTON: 'Delete eBook',
		MSG_LOADING: 'Deleting event...',
		MSG_SUCCESS: 'Delete successful',
		POPUP_CONFIRM_TITLE: 'Delete the document from the system',
		POPUP_CONFIRM_MESSAGE: 'Data after deletion cannot be recovered'
	},

	MSG_INPUT_TITLE_EVENT: 'Please enter a document title!',
	MSG_CHECK_INFO_PRICE:
		'The e-Payment Gateway cannot make transactions under 10.000VNĐ. Please set a value higher than !',
	MSG_CREATE_EBOOK_SUCCESS: 'Document creation successful',
	MSG_UPDATE_EBOOK_SUCCESS: 'Document update successful',
	MSG_CREATE_EBOOK_LOADING: 'Creating document...',

	TOPIC_DETAIL_EBOOK: {
		LINK_RETURN_TO_CATEGORY_LIST: 'Topic List',
		TITLE: 'Edit Topic',
		TITLE_MANAGE_EBOOK: 'Manage eBooks in Catalog',
		LABEL_EBOOK_NAME: 'E-book name',
		PLACEHOLDER_SELECT_EBOOK_NAME: 'Select eBooks to add to topic',
		MSG_ADDING: 'Add book to topic...',
		MSG_ADD_SUCCESS: 'Book added to catalog successfully!',
		MSG_REMOVING: 'Remove book from catalog...',
		MSG_REMOVE_SUCCESS: 'Book removal successful!',
		POPUP_CONFIRM_REMOVE_TITLE:
			'Are you sure you want to remove the book from this topic?'
	},
	TOPIC_TABLE_EBOOK: {
		TH_EBOOK: 'Ebook name',
		TH_UPDATE: 'Update',
		TH_ACTION: 'Action',
	},
}
// CATEGORY - CATEGORY
const CATEGORY_LIST = {
	TITLE: 'Topic',
	BUTTON_CREATE: 'Create Topic',
	LABEL_FILTER_BY_NAME: 'Topic Name',
	PLACEHOLDER_FILTER_BY_NAME: 'Search by topic name',
	//TAB
	TAB_ONLINE_COURSE: 'Online Course',
	TAB_OFFLINE_COURSE: 'Offline Course',
	TAB_EBOOK: 'Ebook',
	//TABEL HEADER
	TH_CATEGORY: 'Topic',
	TH_UPDATE: 'Update',
	TH_ACTION: 'Action',
	TOOLTIP_EDIT_CATEGORY: 'Edit Topic',
	SUBTITLE_EMPTY_DATA:
		'Please create a new topic to display on this listing',

	//TOGGLE_FEATURE_TOPIC
	TOOLTIP_ADD_CATEGORIES_TO_DISPLAY_LIST:
		'Add this topic to the list of categories displayed on the menu',
	TOOLTIP_REMOVE_CATEGORY_FROM_DISPLAY_LIST:
		'Remove this topic from the list of categories displayed on the menu',
	POPUP_CONFIRM_DELETE_TITLE: 'Are you sure to delete this topic?',
	//MESSAGE
	MSG_DELETE_SUCCESS: 'Delete the directory successfully',
	MSG_DELETE_FAIL: 'An error occurred during deletion',
	MSG_UPDATING_DATA: 'Updating data...',
	MSG_UPDATE_SUCCESS: 'Update successful!'
}
const CATEGORY_CREATE_NEW_FORM = {
	TITLE: 'Create New Topic',
	LABEL_NAME: '',
	PLACEHOLDER_INPUT_NAME: 'Enter a name for the topic',
	LABEL_TYTPE: 'Type',
	LABEL_SELECT_TYPE: 'Select type of topic',
	OPTION_ONLINE_COURSE: 'Online Course',
	OPTION_OFFLINE_COURSE: 'Offline Course',
	OPTION_EBOOK: 'Ebook',
	MSG_CREATING: 'In progress',
	MSG_CREATE_SUCCESS: 'Topic creation successful!'
}
const CATEGORY_DETAIL = {
	LINK_RETURN_TO_CATEGORY_LIST: 'Topic List',
	LINK_RETURN_TO_CATEGORY_PARENT_DETAIL: 'Parent Topic Details',
	TITLE_EDIT_CATEGORY: 'Edit Topic',
	TITLE_EDIT_CATEGORY_CHILD: 'Edit subcategory',
	EDIT_BASIC_INFO: {
		TITLE: 'Basic Info',
		LABEL_CATEGORY_NAME: 'Topic Name',
		MSG_CHECK_AND_INPUT_CATEGORY_NAME: 'Please enter a topic name!'
	},
	TABLE_CHILD: {
		TITLE: 'Manage subcategories',
		TH_CATEGORY: 'Topic',
		TH_UPDATE: 'Update',
		TH_ACTION: 'Action',
		TOOLTIP_EDIT_CHILD_CATEGORY: 'Edit subcategory',
		MSG_CREATE_SUCCESS: 'Topic creation successful!',
		MSG_DELETING_CATEGORY: 'Deleting topic...',
		MSG_DELETE_SUCCESS: 'Topic deletion successful',
		POPUP_CONFIRM_DELETE_CATEGORY_TITLE:
			'Are you sure you want to delete this subcategory?'
	},
	ADD_COURSE_TOPIC: {
		TITLE: 'Manage Courses in Catalog',
		LABEL_NAME: 'Course name',
		PLACEHOLDER_SELECT_COURSE: 'Select the course you want to add to the list',
		MSG_ADDING: 'Add Course...',
		MSG_REMOVING: 'Remove course...',
		POPUP_CONFIRM_DELETE_TITLE:
			'Are you sure you want to remove the course from this category?'
	},
	TABLE_COURSE: {
		TH_COURSE: 'Course name',
		TH_UPDATE: 'Update',
		TH_ACTION: 'Action',
	},
	MSG_PROCESSING: 'In progress...',
	MSG_UPDATE_COURSE_SUCCESS: 'Course update successful!'
}
// STORAGE FILE
const LIBRARY_LIST = {
	TITLE: 'Lecture library',
	TAB_VIDEO: 'Video',
	TAB_PDF: 'PDF',
	VIDEO_PANEL: {
		LABEL_NAME: 'Video Name',
		PLACEHOLDER_FILTER_BY_NAME: 'Search by name',
		CONFIRM_UPLOAD_MULTIPLE_FILES: 'Confirm upload these files',
	},
	PDF_PANEL: {
		LABEL_NAME: 'PDF Name',
		PLACEHOLDER_FILTER_BY_NAME: 'Search by name'
	},
	//UPLOAD_DIALOG
	BUTTON_UPLOAD: 'Upload',
	TITLE_UPLOAD_FILE: 'Upload file',
	TITLE_EDIT_DIALOG: {
		TITLE: 'Rename file',
		LABEL_FILE_NAME: 'Filename',
		PLACEHOLDER_INPUT_FILE_NAME: 'Enter file name'
	},
	TH_NAME: 'Name',
	TH_FORMAT: 'Format',
	TH_UPLOAD_AT: 'Loaded Date',
	TH_CAPACITY: 'Capacity',
	TH_ACTION: 'Action',
	TEXT_NOT_NAME: 'No name',
	TITLE_FILE_NOT_NAME: 'Untitled file',

	POPUP_CONFIRM_DELETE_DATA_TITLE: 'Are you sure to delete the data?',
	POPUP_CONFIRM_DELETE_DATA_MESSAGE:
		'Contents associated with this data will be deleted accordingly and cannot be recovered'
}
// QUESTION BANK
const QUESTION_BANK_LIST = {
	TITLE: 'Questions Bank',
	//TAB
	TAB_QUESTION_IN_SYSTEM: 'System Question',
	TAB_TOPIC_AND_KNOWLEDGE: 'Theme/Knowledge Framework',
	//STRUCTURE_KNOWLEDGE
	BUTTON_ADD_TOPIC: 'Add Subject',
	POPUP_CONFIRM_DELETE_TOPTIC_TITLE: 'Delete topic from system',
	POPUP_CONFIRM_DELETE_TOPTIC_MESSAGE:
		'Data after deletion cannot be recovered',
	MSG_CREATING_TOPIC: 'Threading...',
	MSG_CHECK_TOPIC_AGAIN: 'The topic name cannot be empty!',
	//EDIT_KNOWLEDGE
	TITLE_EDIT_TOPIC_INFO: 'Edit topic information',
	LABEL_TOPIC_NAME: 'Theme Name',
	PLACEHOLDER_INPUT_TOPIC_NAME: 'Enter topic name',
	//ADD_KNOWLEDGE
	LABEL_PARENT_TOPIC: 'Parent Thread',
	PLACHOLDER_PARENT_TOPIC: 'Select parent theme'
}
const LIST_QUESTION = {
	FILTER: {
		LABEL_FILTER_BY_TOPIC: 'Filter by topic',
		PLACEHOLDER_SELECT_TOPIC: 'Select topic',
		LABEL_FILTER_BY_CONTENT: 'Filter by content',
		PLACEHOLDER_FILTER_BY_CONTENT: 'Search questions by content'
	},
	LIST_ACTION_WITH_EXCEL: {
		LABEL_QUESTION: 'Question',
		LABEL_QUESTION_TOPIC: 'question topic',
		BUTTON_SELECT_FILE: 'Select file',
		OPTION_IMPORT_EXCEL: 'Import Excel file',
		OPTION_DOWNLOAD_FILE_EXCEL: 'Download sample file',
		LABEL_CONTENT: 'Content',
		LABEL_ANWSER: 'Answer',
		TH_TYPE:
		'Type ("single-choice": Single choice "multiple-choice": Multiple choice, "fill-blank": Fill blank)',
		TH_QUESTION_TYPE: 'Style',
		TH_MULTIPLE_CHOICE: 'Multiple choice test',
		TH_FILL_BLANK: 'Fill in the blank',
		TH_ANWSER_TRUE: 'Answer',
		TH_LEVELS: 'Level',
		MSG_CHECK_QUESTION_TYPE_AGAIN: 'Wrong question type, please check again',
		MSG_ANWSER_ERRO:
			'Optional question requires an answer, please check the file you just uploaded!'
	},
	TH_CONTENT: 'Content',
	TH_TOPIC: 'Theme',
	TH_LEVEL: 'Level',
	TH_TYPE: 'Form',
	TH_ACTION: 'Action',
	TOOLTIP_EDIT_QUESTION: 'Edit question',
	TEXT_QUESTIONS_ON_AVAILABLE_SUBJECTS: 'Available topic question',

	POPUP_CONFIRM_DELETE_TITLE: 'Delete Confirmation',
	POPUP_CONFIRM_DELETE_MESSAGE:
		'Are you sure you want to delete this question?',

	BUTTON_SINGLE_CHOICE_PDF: 'Single-choice test',
	BUTTON_MULTIPLE_CHOICE_PDF: 'Multiple Choice',
	BUTTON_FILL_BLANK: 'Fill in the blank',

	EDIT_QUESTION_INFO_DIALOG: {
		TITLE: 'Edit question information',
		LABEL_INPUT_DESCRIPTION: 'Enter a description...',
		LABEL_INPUT_CONTENT_OF_ANWSER: 'Enter answer...',
		LABEL_INPUT_ANWSER: 'Enter answer...',
		LABEL_LEVEL_QUESTION: 'Question level',
		PLACEHOLDER_INPUT_LEVEL_QUESTION: 'Enter question level',

		TAB_CONTENT: 'Content',
		TAB_ANSWER: 'Answers & Answers',
		TAB_ADVANCED: 'Advanced',
		TAB_EXPLAIN: 'Explain',

		POPUP_CONFIRM_TITLE: 'Edit confirmation',
		POPUP_CONFIRM_MESSAGE: "The question will be changed after confirmation. If you're sure, click the save button.",
		BUTTON_CONFIRM: 'Save',
		BUTTON_CANCEL: 'Cancel',
		MSG_UPDATE_SUCCESS: 'Update successful !'
	},
	EDIT_ALL_QUESTION_INFO_DIALOG: {
		TITLE_EDIT_ALL_QUESTION: 'Bulk edit question information',
		LABEL_LEVEL_QUESTION: 'Question level',
		LABEL_INPUT_LEVEL_QUESTION: 'Enter question level'
	},
	POPUP_CONFIRM_DELETE_MANY_QUESTION_TITLE: 'Delete multiple questions',
	POPUP_CONFIRM_DELETE_MANY_QUESTION_MESSAGE:
		'Data after deletion cannot be recovered. Are you sure you want to delete this lesson?'
}
// TEST STORE
const QUESTION_GENERATE = {
	TITLE: 'Question Generate',
	LABEL_SELECT_COURSE: 'Select a course',
	PLACEHOLDER_FILTER_COURSE: 'Course Search',

	LABEL_SELECT_MATRIX: 'Select matrix lesson',
	PLACEHOLDER_FILTER_MATRIX: 'Search matrix lecture',
	MSG_CHECK_LESSON_AGAIN: 'The lesson does not exist for the test',

	LABEL_EXAM_UNIT: 'Exam no',
	LABEL_EXAM_CODE: 'Code',
	TH_EXAM_NAME: 'Exam title',
	TH_ACTION: 'Action',
	POPUP_CONFIRM_DELETE_TITLE: 'Delete Exam',
	POPUP_CONFIRM_DELETE_MESSAGE:
		'This will remove the test from the system and the student will not receive it',
	MSG_DELETE_SUCCESS: 'Delete the exam successfully',
	POPUP_DETAIL: {
		TITLE: 'Exam details',
		LABEL_QUESTION_UNIT: 'Sentence'
	}
}
//BASIS
const DEPARTMENT_LIST = {
	TITLE: 'Department',
	BUTTON_CREATE: 'Create Department',
	LABEL_FILTER_BY_NAME: 'Base Name',
	PLACEHOLDER_FILTER_BY_NAME: 'Search by name',
	//TABLE
	TH_NAME: 'Base Name',
	TH_ACTION: 'Action',
	TOOLTIP_EDIT_DEPARTMENT: 'Edit Department',
	//EMPTY_DATA
	TITLE_EMPTY_DATA: 'No facility exists',
	SUBTITLE_EMPTY_DATA:
		'Please create a new facility to display on this listing',

	POPUP_CONFIRM_DELETE_TITLE: 'Are you sure to delete this facility?',
	MSG_DELETE_SUCCESS: 'Delete the base successfully'
}
const FORM_CREATED_NEW_DEPARTMENT = {
	TITLE: 'Create Department',
	LABEL_NAME: 'Department Name',
	PLACEHOLDER_INPUT_NAME: 'Enter department name',
	MSG_CREATING: 'Base creation...',
	MSG_CREATE_SUCCESS: 'Base creation successful!'
}
const DEPARTMENT_DETAIL = {
	LINK_RETURN_TO_DEPARTMENT_LIST: 'Base List',
	TITLE: 'Fix base',
	TITLE_INFOS: 'Basic Info',
	LABEL_NAME: 'Department Name',
	PLACEHOLDER_INPUT_NAME: 'Enter department name',
	TITLE_IMAGE: 'Displayed image',
	LABEL_SIZE_IMAGE: 'Recommended size: 300x225px',

	MSG_GETTING_DATA: 'Getting data',
	MSG_UPDATING_DATA: 'Updating data',
	MSG_UPDATE_SUCCESS: 'Data update successful'
}
// ASSIGNED TEST
const ASSIGNED_HOMEWORK_LIST = {
	TITLE: 'Test List',
	LABEL_SELECT_COURSE: 'Select a course',
	OPTION_ALL: 'All',
	LABEL_FILTER_TEST_BY_REQUEST: 'Select the required test',
	//TABLE_HEADER
	TH_COURSE: 'Course',
	TH_UNIT_TITLE: 'Test',
	TH_HOMEWORK_COUNT: 'Submissions',
	TH_IS_ALL_HOMEWORK_MARKER: 'Termed',
	TH_ACTION: 'Action'
}
const ASSIGNED_HOMEWORK_DETAIL = {
	LINK_RETURN_TO_EXAM_LIST: 'Test List',
	BUTTON_VIEW_REQUIMENT: 'View topic',
	FILTER: {
		LABEL_STATUS: 'Status',
		PLACEHOLDER_STATUS: 'Submission Status',
		OPTION_STATUST_ALL: 'All',
		OPTION_NO_SCORE: 'Undotted',
		OPTION_APPROVED: 'Approved',
		LABEL_SUBJECTS_CODE: 'Submission Code',
		PLACEHOLDER_INPUT_SUBJECTS_CODE: 'Enter submission code',
		BUTTON_FILTER_AND_VIEW: 'Filter and view'
	},
	BUTTON_DOWNLOAD_ALL_EXCEL: 'Download All',
	ENTITY_EXERCISE_STUDENT: 'Students assignment',
	MSG_GROUP_STUDENT_DOES_NOT_EXIST: 'Group does not exist',
	MSG_COMPRESSING: 'Compressing...',
	MSG_COMPRESSING_TO: "Compressing second student's work",

	BUTTON_DOWLOAD_FILE_SELECTED: 'Download selected file',
	TOOLTIP_TEACHER_ACCEPT_LAST_LESSON: 'Last time teacher',
	TOOLTIP_TIME_ACCEPT_LAST_LESSON: 'Last time of grading',
	//TABLE_HEADER
	TH_SUBMIT_CODE: 'Submission Code',
	TH_TIME_SUBMIT: 'Submission Time',
	TH_STATUS: 'Status',
	TH_MARK: 'Score',
	TH_TEACHER: 'Teacher',
	TH_POINTING_TIME: 'Pointing Time',
	TH_ACTION: 'Action',
	TEXT_NO_SCORE: 'Undotted',
	TEXT_SUBMITTED: 'Submitted',
	TEXT_APPROVED: 'Approved',
	TEXT_REFUSE: 'Deny',
	TEXT_UNSUBMIT: 'Unsubmitted',
	TOOLTIP_COPY_SUBJECTS: 'Copy Submission',
	MSG_COPY_SUCCESS: 'Copy successful',
	MSG_COPY_FAIL: 'Copy failed. Please try again',
	POPUP_VIEW_UNIT_REQUIREMENT: {
		TITLE: 'Title',
		TITLE_INTRODUCT: 'About',
		TITLE_REQUIREMENT_OF_EXERCISE: 'Assignment requirement'
	},
	ARCHIVE_BY_CHOICE: {
		ENTITY_EXERCISE_STUDENT: 'Students assignment'
	},
	MSG_ACCEPT_EXERCISE_SUCCESS: 'Accept assignment successfully',
	MSG_SAVE_EDIT_SUCCESS: 'Remedy saved successfully!'
}
//
const MODAL_CREATE_AUTHOR = {
	TITLE_ADD_NEW_TEACHER: 'Quickly add new trainer',
	LABEL_TEACHER_NAME: 'Teacher Name',
	PLACEHOLDER_INPUT_TEACHER_NAME: 'Enter teacher name',
	LABEL_EMAIL: 'Email',
	PLACEHOLDER_INPUT_TEACHER_EMAIL: 'Enter teacher email',
	TITLE_EDIT_TEACHER_IN: 'View and edit Instructor details at',
	LINK_TEACHER_LIST: 'List of Instructors',
	MSG_CREATEING_TEACHER: 'Creating trainer...',
	MSG_CREATED_TEACHER: 'New trainer created',
	MSG_RECHECK_EMAIL_TEACHER:
		'Teacher email already in use, please enter another email!',
	MSG_CREATE_TEACHER_FAIL: 'Trainer creation failed!'
}
//
const MODAL_CREATE_TOPIC = {
	TITLE_ADD_NEW: 'Quick Add New Theme',
	LABEL_CATEGORY_HEADER: 'Topic Title',
	LABEL_INPUT_CATEGORY_HEADER: 'Enter category title',
	TITLE_EDIT_CATEGORY_DETAIL_IN: 'View and edit category details at',
	LINK_GO_TO_CATEGORY_LIST: 'Topic List',
	MSG_CREATING_CATEGORY: 'Creating a directory...',
	MSG_CREATED_NEW_CATEGPRY: 'New topic created!',
	MSG_CREATE_CATEGORY_FAIL: 'Topic creation failed!'
}

export default {
	LEARNING_PATH_LIST,
	FORM_CREATE_NEW_LEARNING_PATH,
	LEARNING_PATH_DETAIL,
	EVENT_LIST,
	FORM_CREATE_NEW_EVENT,
	EVENT_DETAIL,
	EBOOK_LIST,
	EBOOK_DETAIL,
	CATEGORY_LIST,
	CATEGORY_CREATE_NEW_FORM,
	CATEGORY_DETAIL,
	LIBRARY_LIST,
	QUESTION_BANK_LIST,
	QUESTION_GENERATE,
	DEPARTMENT_LIST,
	FORM_CREATED_NEW_DEPARTMENT,
	DEPARTMENT_DETAIL,
	MODAL_CREATE_AUTHOR,
	MODAL_CREATE_TOPIC,
	ASSIGNED_HOMEWORK_LIST,
	ASSIGNED_HOMEWORK_DETAIL,

	LIST_QUESTION,
	PLACEHOLDER_SELECT_TOPIC: 'Select topic',
	LABEL_TOPIC: 'Theme',
	LABEL_QUESTION: 'Question',
	LABEL_SELECT_ALL: 'Select All',
	BUTTON_SEARCH: 'Search',
	BUTTON_CONFIRM: 'Confirm',
	BUTTON_CANCEL: 'Cancel',
	BUTTON_SAVE: 'Save',
	BUTTON_CANCEL_TEXT: 'Cancel',
	BUTTON_CREATE: 'Create',
	BUTTON_UPDATE: 'Update',
	BUTTON_DELETE: 'Delete',
	BUTTON_OK: 'OK',
	TITLE_ACTION: 'Operation',
	BUTTON_ADD: 'Add',

	//MESSAGE
	MSG_DELETING: 'Deleting...',
	MSG_UPDATING_DATA: 'Updating data...',
	MSG_UPDATE_DATA_SUCCESS: 'Data update successful!',
	MSG_UPDATE_DATA_FAIL: 'Data update failed',
	MSG_CREATE_SUCCESS: 'Successful creation',
	MSG_DELETE_DATA_SUCCESS: 'Data deletion successful!',
	MSG_DELETE_DATA_FAIL: 'Data deletion failed!',
	MSG_UPDATING: 'Updating...',
	MSG_UPDATE_SUCCESS: 'Update successful!',
	MSG_UPDATE_FAIL: 'Update failed!',
	MSG_DELETE_SUCCESS: 'Delete successful!',
	MSG_CHECK_INFO_AGAIN: 'Please check the information again!',
	MSG_GETTING_DATA:"Getting data...",

	TITLE_EMPTY_DATA: 'No category exists for {0} yet',
}
