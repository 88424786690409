const AUTH_ROUTES = {
	TITLE: 'ログイン'
}
const HIDDENT_ROUTES = {
	TITLE: '管理ページ',
	LABEL_EVENT_DETAIL: 'イベント詳細',
	LABEL_EBOOK_DETAIL: '電子書類詳細',
	LABEL_TOPIC_DEAIL: 'カテゴリ-ジャンル詳細',
	LABEL_DEPARTMENT_DETAIL: '学舎詳細',
	LABEL_MANAGE_ZOOM: 'ズーム教室の管理',
	LABEL_CREATE_ZOOM: 'ズーム教室の作成',
	LABEL_AFFILIATE_DETAIL: 'アフィリエイト詳細ページ',
	LABEL_CREATE_TEACHER: '講師の追加',
	LABEL_CREATE_STUDENT: 'ユーザーの追加',
	LABEL_TEACHER_DETAIL: '講師詳細',
	LABEL_STUDENT_DETAIL: 'ユーザー詳細',
	LABEL_ACTIVE_CODE_DETAIL: 'アクティベーションコード詳細',
	LABEL_ACTIVE_CODE_CREATE: 'アクティベーションコードの新規作成',
	LABEL_COUPON_DETAIL: 'クーポン詳細',
	LABEL_CREATE_COUPON: 'クーポンの新規作成',
	LABEL_COMBO_DETAIL: 'コンボ詳細',
	LABEL_BLOG_DETAIL: '記事詳細',
	LABEL_GROUP_STUDENT_DETAILL: 'ユーザーグループ詳細',
	LABEL_CREATE_CETIFICATE: '証明書の作成',
	LABEL_CETIFICATE_DETAIL: '証明書詳細',
	LABEL_COURSE_PROGRESS_DETAIL: 'コース進捗詳細',
	LABEL_LEARNING_PATH_DETAIL: 'ルート詳細'
}
const NAVIGATION_ROUTES = {
	//COURSE_CONTENT
	COURSE_CONTENT: {
		TITLE: 'コース内容',
		LINK_TO_ONLINE_COURSE_LIST: 'オンラインコース',
		LINK_TO_OFFLINE_COURSE_LIST: 'オフラインコース',
		LINK_TO_LEARNING_PATH_LIST: '学習ルート',
		LINK_TO_CERTIFICATE_LIST: '証明書',
		LINK_TO_EVENT_LIST: 'イベント',
		LINK_TO_EBOOK_LIST: '電子書類',
		LINK_TO_TOPIC_LIST: 'カテゴリ-ジャンル',
		LINK_TO_FILE_STORAGE_LIST: 'レッスンライブラリ',
		LINK_TO_QUESTION_BANK_LIST: 'テスト項目',
		LINK_TO_STORE_EXAM_QUESTION_LIST: '試験問題のライブラリ',
		LINK_TO_DEPARTMENT_LIST: '学舎',
		LINK_TO_ASSIGNED_HOMEWORK_LIST: '割り当てられているテスト'
	},
	PHYSICAL_PRODUCT: {
    TITLE: "その他の製品",
    LINK_TO_PRODUCT_LIST: "製品リスト",
    LINK_TO_PRODUCT_CATEGORY_LIST: "カテゴリー"
  },
	//USER_MANAGE
	USER_MANAGE: {
		TITLE: 'ユーザー管理',
		LINK_TO_STUDENT_LIST: 'ユーザー',
		LINK_TO_STUDENT_GROUP_LIST: 'ユーザーグループ',
		LINK_TO_TEACHER_LIST: '講師',
		LINK_TO_COMMENT_LIST: '議論',
		LINK_TO_RATING_LIST: '評価',
	},
	//FINANCE_MANAGE
	FINANCE_MANAGE: {
		TITLE: '財務管理',
		LINK_TO_ACTIVATION_CODE_CAMPAIGN_LIST: 'アクティベーションコード',
		LINK_TO_COUPON_CAMPAIGN_LIST: 'クーポン',
		LINK_TO_COMBO_CAMPAIGN_LIST: 'コンボ',
		LINK_TO_BILL_MANAGE_LIST: '注文管理',
		LINK_TO_SALES_LIST: '売上',
		LINK_TO_SALES_TEACHER_LIST: '講師別の売上',
		LINK_TO_CONTACT_LIST: '連絡先',
		LINK_TO_AFFILIATE_LIST: 'アフィリエイト'
	},
	//WEBSITE_DISPLAY
	WEBSITE_DISPLAY: {
		TITLE: 'ウェブサイトのUI',
		LINK_TO_MENU_SETTING: 'メニュー設定',
		LINK_TO_SITE_BUILD: 'ページ設定',
		LINK_TO_BLOG_LIST: 'ニュース・ブロッグ'
	},
	// APP_DISPLAY
	APP_DISPLAY: {
		TITLE: 'アプリのUI',
		LINK_TO_SCREEN_BIULD: '画面設定'
	},
	// SESTING_SYSTEM
	SESTING_SYSTEM: {
		TITLE: '一般設定',
		LINK_TO_BASIC_SETTING: '基本設定',
		LINK_TO_ADVANCED_SETTING: '詳細設定'
	},
	//MY_ACCOUNT
	MY_ACCOUNT: {
		TITLE: 'アカウント',
		LINK_TO_CHANGE_PASSWORD_SETTING: 'パスワード変更',
		LINK_TO_CONTACT_INFO_SETTING: '連絡情報'
	},
	SIGN_OUT: 'ログアウト'
}
const HIDDEN_WITHOUT_MENU_ROUTES = {
	LABEL_ONLINE_COURSE_DETAIL: 'オンラインコース詳細',
	LABEL_OFFLINE_COURSE_DETAIL: 'オフラインコース詳細',
	LABEL_TESTING: 'テスト',
	LABEL_ASSIGNED_HOMEWORK_DETAIL: 'テスト詳細'
}
export default {
	AUTH_ROUTES,
	HIDDENT_ROUTES,
	HIDDEN_WITHOUT_MENU_ROUTES,
	NAVIGATION_ROUTES,
	MAX: '最大',
	KEY_WORD: '文字',
	EVALUETE_OF_STUDENT: 'ユーザーによる評価',
	HERE: 'こちら',

	BASIC: '基本',
	BACKGROUND: '詳細ページにおけるプロファイル写真',

	COURSE: 'コース',
	CREATED_LIST: 'コースの作成',
	CHOOSE_COURSE: 'コース選択',
	COURSE_NOT_SETTING_AUTO: '時間自動測定が設定されていないコース',

	DISCRIPTION_SHORT: 'カードにおける簡単な説明',
	DISCRIPTION_DETAIL: '詳細説明',
	DISCRIPTION_DETAIL_HINT: '学習ルート詳細ページの情報を表示します。',

	ROUTE_NAME: 'ルート名',
	PLACEHOLDER_ROUTE_NAME: 'ルート名で検索',
	ROUTE_LIST: 'ルート一覧',

	INFORMATION: '情報',
	INFO_BASIC: '基本情報',
	INFO_BASIC_HINT: 'ルートの基本的な情報設定',

	LEVEL_DIFFICUL: 'レベル',
	LIST: 'トピック',
	LISH_COURSE: 'コース一覧',

	TIME: '時間',
	TIME_COURSE: 'コース時間',
	TIME_HINT: 'コース時間の設定',

	PICK_AUTO_ENTER: '手動入力',
	PICK_ALLOW_SYSTEM: '各コースによる自動計算',
	PICK_ALLOW_USE_CERTIFICATE: '学習ルートに証明書を使用します',
	PICK_ALLOW_USE_CERTIFICATE_HINT:
		'あなたはこの学習ルートの証明書を持っています。証明書の詳細はこちら。',
	PICK_ALLOW_STUDENT_EVALUATE:
		'ユーザーがルートを評価できるようにし、ルートに対する評価内容を表示します',
	PICK_OBLIGATORY_BROW_EVALUATE: 'ページに公開する前に、評価の承認を行います',
	PUBLISH: '公開',
	PUBLISH_ROUTE_STUDY: '学習ルートの公開',
	PUBLISH_ROUTE_STUDY_HINT:
		'この学習ルートはウェブサイトに公開され、検索エンジンで検索可能になります。',
	PUBLISHED: '公開済み',

	NOTE: '注意',
	NOTE_HINT:
		'時間自動測定が設定されていないコースが学習ルートの総時間に加算されません。',

	SETTING_CERTIFICATE: '証明書',
	SETTING_CERTIFICATE_HINT:
		'学習ルートに証明書を使用します。学習ルートが完了したら、証明書を与えます。',
	STOP_PUBLISH: '公開停止',

	REMOVE_ROUTE_STUDY: '学習ルートの削除',
	REMOVE_ROUTE_STUDY_HINT: '削除したら、学習ルートは回復できません。',
	REMOVE_ROUTE_STUDY_OUT_SYSTEM: ' 学習ルートを削除します。',
	REMOVE_ROUTE_STUDY_OUT_SYSTEM_HINT: '削除したら、データは回復できません。',

	ENTER_SEARCH: 'ここで入力し検索してください',

	TITLE_EXAM_ASSIGNED: '割り当てられているテスト',
	TITLE_SALES_TEACHER: '講師別の売上',

	TITLE_ONLINE_COURSE_DETAIL: 'オンラインコース詳細',
	TITLE_MARK_MULTIPLE_CHOICE: '選択式テストの点数',
	TITLE_EXERCISE: '問題',
	TITLE_STUDY_REPORT: '学習の報告',
	TITLE_ONLINE_COURSE: 'オンラインコース',
	TITLE_COURSE_CONTENT: 'コース内容',
	TITLE_FINANCIAL_MANAGEMENT: '財務管理',
	TITLE_AFFILIATE_DETAIL: 'アフィリエイト詳細ページ'
}
