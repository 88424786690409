import axios from 'axios'
import Repository from '@/api/core'
import queryString from 'query-string'
import vendor from '@/store/modules/vendor'
import auth from '@/store/modules/auth'
const baseURL = process.env.VUE_APP_API_GROUP + '/group'

const fetch = params => {
	return Repository(baseURL).fetch(params)
}
const count = params => {
	const query = queryString.stringify({
		...params,
		vendorId: vendor.state.vendor.vendorId
	})
	return Repository(baseURL + '/count' + `?${query}`).fetch()
}

const fetchOne = id => {
	return Repository(baseURL).fetchOne(id)
}

const create = params => {
	return Repository(baseURL).create(params)
}

const update = (id, params) => {
	return Repository(baseURL).update(id, params)
}

const remove = id => {
	return Repository(baseURL).delete(id)
}

const addCourse = (id, params, option) => {
	return axios.put(
		baseURL + `/add-course/${id}?vendorId=${vendor.state.vendor.vendorId}`,
		params,
		{
			headers: {
				Authorization: auth.state.auth.accessToken
			}
		},
		option
	)
}

const addBook = (id, params, option) => {
	return axios.put(
		baseURL + `/add-book/${id}?vendorId=${vendor.state.vendor.vendorId}`,
		params,
		{
			headers: {
				Authorization: auth.state.auth.accessToken
			}
		},
		option
	)
}

const addManyStudent = (id, params, option) => {
	return axios.put(
		baseURL + `/add-users/${id}?vendorId=${vendor.state.vendor.vendorId}`,
		params,
		{
			headers: {
				Authorization: auth.state.auth.accessToken
			}
		},
		option
	)
}

const removeCourse = (id, params, option) => {
	return axios.put(
		baseURL +
			`/remove-course/${id}?courseId=${params.courseId}&vendorId=${vendor.state.vendor.vendorId}`,
		{},
		{
			headers: {
				Authorization: auth.state.auth.accessToken
			}
		},
		option
	)
}

const removeBook = (id, params, option) => {
	return axios.put(
		baseURL +
			`/remove-book/${id}?bookId=${params.bookId}&vendorId=${vendor.state.vendor.vendorId}`,
		{},
		{
			headers: {
				Authorization: auth.state.auth.accessToken
			}
		},
		option
	)
}

const addUser = (id, params, option) => {
	return axios.put(
		baseURL + `/add-user/${id}?vendorId=${vendor.state.vendor.vendorId}`,
		params,
		{
			headers: {
				Authorization: auth.state.auth.accessToken
			}
		},
		option
	)
}

const removeUser = (id, params, option) => {
	return axios.put(
		baseURL +
			`/remove-user/${id}?userId=${params.userId}&vendorId=${vendor.state.vendor.vendorId}`,
		{
			vendorId: vendor.state.vendor.vendorId
		},
		{
			headers: {
				Authorization: auth.state.auth.accessToken
			}
		},
		option
	)
}

const addCourseToGroup = (id, params, option) => {
	return axios.put(
		baseURL + `/add-course-alpha/${id}?vendorId=${vendor.state.vendor.vendorId}`,
		params,
		{
			headers: {
				Authorization: auth.state.auth.accessToken
			}
		},
		option
	)
}

const addCourseToUserInGroup = (id, params, option) => {
	return axios.put(
		baseURL + `/add-course-user/${id}?vendorId=${vendor.state.vendor.vendorId}`,
		params,
		{
			headers: {
				Authorization: auth.state.auth.accessToken
			}
		},
		option
	)
}

export default {
	fetch,
	fetchOne,
	count,
	create,
	update,
	remove,
	addCourse,
	removeCourse,
	addUser,
	removeUser,
	addManyStudent,
	addBook,
	removeBook,
	addCourseToGroup,
	addCourseToUserInGroup
}
