import axios from 'axios'
import auth from '@/store/modules/auth'
import vendor from '@/store/modules/vendor'

const Repository = BASE_URL => ({
	count: (params, option) =>
		axios.get(
			BASE_URL + '-count',
			{
				params: {...params, vendorId: vendor.state.vendor.vendorId},
				headers: {
					Authorization: auth.state.auth.accessToken
				}
			},
			option
		),
	fetch: (params, option) =>
		axios.get(
			BASE_URL,
			{
				params: {...params, vendorId: vendor.state.vendor.vendorId},
				headers: {
					Authorization: auth.state.auth.accessToken
				}
			},
			option
		),
	fetchOne: (id, option) =>
		axios.get(
			BASE_URL + '/' + id,
			{
				params: {vendorId: vendor.state.vendor.vendorId},
				headers: {
					Authorization: auth.state.auth.accessToken
				}
			},
			option
		),
	create: (params, options) =>
		axios.post(
			BASE_URL,
			{...params, vendorId: vendor.state.vendor.vendorId},
			{
				headers: {
					Authorization: auth.state.auth.accessToken
				}
			},
			options
		),
	createMany: (params, options) =>
		axios.post(
			BASE_URL + '/create-many',
			{...params, vendorId: vendor.state.vendor.vendorId},
			{
				headers: {
					Authorization: auth.state.auth.accessToken
				}
			},
			options
		),
	update: (id, params, option) =>
		axios.put(
			BASE_URL + `/${id}?vendorId=${vendor.state.vendor.vendorId}`,
			params,
			{
				headers: {
					Authorization: auth.state.auth.accessToken
				}
			},
			option
		),
	delete: (id, option) =>
		axios.delete(
			BASE_URL + `/${id}?vendorId=${vendor.state.vendor.vendorId}`,
			{
				headers: {
					Authorization: auth.state.auth.accessToken
				}
			},
			option
		)
})

export default Repository
