const COURSE_LIST = {
	TITLE_ONLINE_COURSE: 'Online Course',
	TITLE_OFFLINE_COURSE: 'Offline Course',
	BUTTON_CREATE: 'Create Course',
	LABEL_FILTER_BY_NAME: 'Course name',
	PLACEHOLDER_FILTER_BY_NAME: 'Search by course name',
	//TABLE HEADER
	TH_NAME: 'Course name',
	TH_UPDATE_DATE: 'Update',
	TH_STATUS: 'Status',
	TH_ACTION: 'Action',
	TEXT_PUBLISED: 'Published',
	TEXT_NOT_PUBLISH: 'Unpublished',
	TOOLTIP_EDIT_COURSE: 'Edit course',
	//EMPTY DATA
	TITLE_EMPTY_DATA: 'No courses exist yet',
	SUBTITLE_EMPTY_DATA:
		"There are currently no courses available. Let's quickly create a course!",
	MSG_GETTING_DATA: 'Getting course data...',
	//CARD COURSE CONTENT
	LABEL_TEACHER_TO_UNDERTAKE: 'Lecturer in charge',
	LABEL_NOT_TEACHER_TO_UNDERTAKE: 'No trainer yet',
	LABEL_PUBLISHED: 'Published',
	LABEL_UNPUBLISH: 'Unpublished',
	LABEL_NOT_FOUND_RESULT: 'No match found!',
	LABEL_FREE: 'Free',
	LABEL_NOT_SELECT_TOPIC: 'No topic selected',
	LABEL_TEXT_CONTACT: 'Contact',
	LABEL_NOT_PRICE: 'No price yet',
	LABEL_UNIT_PRICE: 'đ',
	LABEL_UNIT_COURSE: 'course',
	TOOLTIP_GIVE_COURSE_TO_HIGHLIGHTS_LIST:
		'Put this course on your list of featured courses',
	TOOLTIP_CANCEL_COURSE_GET_OUT_HIGHLIGHTS_LIST:
		'Put this course on your list of featured courses',
	BUTTON_VIEW: 'View more courses'
}
const FORM_CREATE_NEW_COURSE = {
	TITLE: 'Create a new course',
	LABEL_INPUT_NAME: 'Course name',
	PLACEHOLDER_INPUT_NAME: 'Enter course name',
	HINT_INFO: 'Course information is always subject to change',
	MSG_CREATE_SUCCESS: 'New course created successfully!',
	MSG_CREATE_FAIL: 'New course creation failed'
}
const COURSE_OVERVIEW = {
	TAB_INFO: 'Information',
	TAB_SCHEDULE_AND_CLASS: 'Class and schedule',
	TAB_LEARNING_CONTENT: 'Learning content',
	//TAB
	TAB_BASIC_INFO: 'Basic Info',
	TAB_ADVANCE_INFO: 'Advanced Info',
	TAB_INTERACTION: 'Interactive settings',
	TAB_THUMBNAIL: 'Thumbnail',
	TAB_PRICING_AND_OWNERSHIP: 'Price and ownership',
	TAB_PUBLISHING: 'Publish',
	TAB_DELETION: 'Delete the course',
	BASIC_INFO: {
		ON_CARD: {
			TITLE: 'Basic information',
			SUBTITLE: 'Setting up the name, the category and assigning teachers for the course.',
			SUBTITLE_OFFLINE_COURSE: 'Setting up the name, the category, assigning teachers and total duration for this course.',
			LABEL_NAME: 'Course name (Up to 120 characters)',
			PLACEHOLDER_INPUT_NAME: 'Enter course name',
			LABEL_CATEGORY: 'Topic',
			PLACEHOLDER_SELECT_TOPIC: 'Select topic',
			BUTTON_CREATE_CATEGORY: 'Add categories',
			LABEL_ADD_TEACHER: 'Designated Instructor',
			PLACEHOLDER_SELECT_TEACHER: 'Select trainer',
			BUTTON_CREATE_TEACHER: 'Add Teacher',
			LABEL_EXPIRY_COURSE: 'Total duration',
			PLACEHOLDER_INPUT_EXPIRY_COURSE: 'Enter duration',
			OPTION_SHOW_TEACHER_FOR_STUDENT: 'Public the assigned teacher for students',
		},
		DURATION_SETTING: {
			TITLE: 'Duration',
			SUBTITLE: 'Provide course duration information',
			OPTION_SELF_INPUT: 'Input yourself',
			OPTION_SYSTEM_AUTO_CACULATE:
				'Allows automatic calculation itself based on lectures',
			LABEL_TIME: 'Total duration',
			PLACEHOLDER_TIME: 'Enter duration',
			MSG_GETTING_DATA_TIME: 'Getting duration data...'
		},
		DESCRIPTION: {
			TITLE: 'Short description',
			SUBTITLE:
				'Provide a description for a quick introduction to the course on the listing page',
			LABEL_INPUT_SHORT_DESCRIPTION: 'Short description (Recommended length: 200 characters)',
			PLACEHOLDER_INPUT_DESCRIPTION: 'Enter a description'
		}
	},
	ADVANCE_INFO: {
		DESCRIPTION_DETAIL: {
			TITLE: 'Detailed Description',
			SUBTITLE:
				'Provide a description so students can refer to and better understand the course on a private introduction page'
		},
		COURSE_FAKE_RATING: {
			TITLE: 'Default rating',
			SUBTITLE: 'Setting up course rating information',
			CHECKBOX_TURN_ON_SETTING: 'Enable Setup',
			TH_POINT: 'Review Score',
			TH_QUANTITY: 'Reviews Quantity',
			TEXT_VERY_BAD: 'Very bad',
			TEXT_NEED_TO_CHANGE: 'Need to change',
			TEXT_NOT_GOOD: 'Not good',
			TEXT_GOOD: 'Good',
			TEXT_VERY_GOOD: 'Very Good',
			TH_TOTAL_REVIEW: 'Total Reviews',
			TH_AVERAGE_OF_REVIEW: 'Average rating'
		},
		COURSE_FAKE_STUDENT_NUMBER: {
			TITLE: 'The default number of students',
			SUBTITLE:
				'Setting the number of students who have taken the course',
			LABEL_FIRST_NUMBER_STUDENTS: 'Initial number of students',
			PLACEHOLDER_STUDENT_TO_BUY: 'Import student quantity bought',
			LABEL_STUDENTS_INCREASED_BY_DAY: 'Number of students increasing by day',
			PLACEHOLDER_STUDENT_BUY_COURSE:
				'Import student quantity',
			LABEL_AVAILABLE_DATE: 'As of date',
			PLACEHOLDER_ANWSER: 'Enter answer'
		}
	},
	//------------COURSE_INTERACTION-----------
	TITLE_INTERACTION: 'Rating',
	SUBTITLE_INTERACTION: 'Configuring course rating rules',
	LABEL_SHOW_COURSE_SETTING:
		'The course is only visible to students who own this course',
	CHECKBOX_CAN_ENABLE_RATE:
		'Allow students, who owned this course, to write rating',
	CHECKBOX_MUST_APPROVE_RATE:
		'Only display approved ratings',

	DISPLAY_IMAGE: {
		ON_CARD: {
			TITLE: 'Thumbnail',
			SUBTITLE: 'Configure the thumbnail on the card. Recommended size: ',
			TEXT_RATIO: 'scale'
		},
		BACKGROUND: {
			TITLE: 'Course cover photo',
			SUBTITLE:
				'Configure the picture displayed on top of the course detail page. Recommended size: {0} ',
			TEXT_IMAGE_UNIT: 'photo'
		}
	},

	//DISPLAY_VIDEO_ON_CARD
	TITLE_VIDEO: 'Intro video',
	SUBTITLE_VIDEO:
		'Configure a short introductory video of the course, which appears when users click on the card.',
	SUBTITLE_VIDEO_HAS_CAPACITY_LIMIT:
		'Configure a short introductory video about the learning route. The video will appear instead of the avatar of the learning route when clicking on the card. (Upload limit is 1.5GB)',
	CHECKBOX_VIDEO_INTRO: 'Enable',
	LABEL_SELECT_VIDEO_FROM_LIBRARY: 'Select from library',
	LIMIT_CAPACITY: 'Upload limit is',
	LABEL_STATUS_VIDEO: 'Video Status',
	TITLE_VIDEO_THUMBNAIL: 'Video thumbnail',
	SUBTITLE_VIDEO_THUMBNAIL:
		'Recommended size: {0} (scale {1}). To optimize the speed of downloading thumbnails on videos, make sure the image is as small as possible (maximum size: {2}).',
	MSG_ADD_VIDEO_THUMBNAIL_SUCCESS: 'Added thumbnail for successful video!',

	TEXT_VIDEO_PROCESSED: 'Video is being processed',
	TEXT_READY: 'Ready',
	TITLE_DRAG_DROP: 'Drag and drop here',
	BUTOTN_SELECT_FILE_IN_MACHINE: 'Select Local File',
	MSG_WAITING_VIDEO_PROCESSED:
		'Video is being processed. Please wait a moment.',
	MSG_SUPPORT_VIDEO_PROCESSED:
		'If the problem persists for a long time, please contact the technical team for support.',
	MSG_FILE_NOT_NAME: 'Untitled file',
	//-----------COURSE_PRICING-----------
	TITLE_PRICING: 'Price',
	SUBTITLE_PRICING:
		'Configure price display for this course. Note: Students, who are granted permisison by administrator, can still enrol this course without purchasing.',
	LABEL_PRICE_CONTACT: 'Contact option',
	RADIO_PRICE_ALL_COURSE: 'One-time Pay option',
	TYPE_OF_TIME: {
		TITLE_MONTH: 'Month',
		TITLE_DATE: 'Date',
		TITLE_WEEK: 'Week',
		TITLE_SLOT: 'Session'
	},

	//Kiểm tra lại
	RADIO_PRICE_COURSE_HINT:
		'Student needs to pay the total price of this course to enrol',
	LABEL_PRICE: 'Price',
	LABEL_PRICE_BY_TIME: 'Milestone Pay option',
	LABEL_SELECT_ABOUT_TIME: 'Select particular time',
	ABOUT_TIME: 'Duration',
	LABEL_BILL_PRICE: 'Price',
	//LABEL_PRICE_CONTACT: 'Contact',
	MSG_REMOVE_CLASS_HINT: 'Deleted class cannot be recovered',

	CHECKBOX_COURSE_FREE: 'Free',
	HINT_COURSE_FREE: 'The course is free (no purchase required).',
	CHECKBOX_PAY_ONCE_FOR_COURSE: 'One Time Pay',
	HINT_PAY_ONCE_FOR_COURSE: 'Users pay once to own the course forever.',
	LABEL_PRICE_OFFICIAL: 'Official price',
	LABEL_CURRENCY_UNIT: 'VND',
	PLACEHOLDER_PRICE_OFFICIAL:
		'The e-Payment Gateway requires transaction from at least 10.000 VNĐ !',
	LABEL_PRICE_AFTER_DISCOUNT: 'Price before promotion',
	HINT_PRICE_AFTER_DISCOUNT: 'Leave blank if not displayed',
	CHECKBOX_DISALLOWANCE_PAY: 'No purchase or free admission',
	HINT_DISALLOWANCE_PAY:
		'The course is only taken by the group of students assigned to the course.',
	MSG_PRICE_AT_LEAST: 'Price cannot be less than',
	//------------COURSE_PUBLISHING-----------
	PUBLISHING: {
		TITLE_STOP_PUBLISH: 'Stop publishing this course',
		SUBTITLE_STOP_PUBLISH:
			'Stop publishing the course to hide the course on listing pages.',
		TITLE_PUBLISH_COURSE: 'Publish course',
		SUBTITLE_PUBLISH:
			'Publish this course and displayed on listing pages (This change does not apply to course with limited display).',
		BUTTON_PUBLISH: 'Publish',
		BUTTON_STOP_PUBLISH: 'Stop publishing',
		POPUP_CONFIRM_STOP_TITLE:
			'Are you sure you want to stop publishing this course?',
		POPUP_CONFIRM_STOP_MESSAGE:
			'Stop publishing the course to hide the course on listing pages. Note: students who already own the course can still access the course.'
	},
	DELETION: {
		TITLE: 'Delete Course',
		SUBTITLE: 'Delete the course. Note: Deleted course cannot be recovered',
		BUTTON: 'Delete Course',
		POPUP_CONFIRM_TITLE: 'Are you sure you want to delete this course?',
		POPUP_CONFIRM_MESSAGE:
			'Data after deletion cannot be recovered. The system will automatically delete all chapters and lessons belonging to this course. Are you sure you want to delete this course?'
	}
}
const OFFLINE_COURSE_DETAIL = {
	LINK_RETURN_OFFLINE_COURSE_LIST: 'Offline course list',
	CLASS: {
		TITLE: 'Class',
		LABEL_NEW: 'New Class',
		BUTTON_ADD: 'Add Class',
		MSG_CREATING: 'Creating a new class',
		MSG_CREATE_SUCCESS: 'Successful course creation',
		BASIC_INFO: {
			TITLE: 'Basic Info',
			LABEL_NAME: 'Class Name',
			PLACEHOLDER_INPUT_NAME: 'Enter class name',
			LABEL_DEPARTMENT: 'Department',
			PLACEHOLDER_INPUT_DEPARTMENT: 'Enter department name'
		},
		SCHEDULE: {
			TITLE: 'Class Schedule',
			LABEL_OPENING_CEREMONY: 'Starting Day',
			RADIO_WEEKLY: 'Weekly repeat schedule',
			RADIO_FREE: 'Free Calendar',
			MSG_CHECK_AGAIN: 'Please check the Free Schedule and repeat weekly!',
			BUTTON_ADD: 'Add schedule',
			PLACEHOLDER_DAY_IN_WEEK: 'Day of the week'
		}
	},
	DELETION: {
		TITLE: 'Delete the class',
		SUBTITLE: 'Deleted class cannot be recovered',
		BUTTON: 'Delete class',
		POPUP_CONFIRM_TITLE: 'Are you sure you want to delete this class?',
		POPUP_CONFIRM_MESSAGE: 'Deleted class cannot be recovered',

		MSG_LOADING: 'Deleting class...',
		MSG_SUCCESS: 'Delete the class successfully'
	},
	SYLLABUS: {
		LIST: {
			TITLE_LESSON_UNIT: 'Lesson',
			BUTTON_ADD_LESSON: 'Add Lesson'
		},
		DETAIL: {
			TITLE_LESSON_UNIT: 'Lesson',
			INFO: {
				TITLE: 'Lesson Information',
				LABEL_LESSON_HEADING: 'Lesson Title',
				PLACEHOLDER_LESSON_HEADING: 'Example: Lesson 1: Basic Opening',
				LABEL_DESCRIPTION_LESSON: 'Lesson Description',
				PLACEHOLDER_DESCRIPTION_LESSON:
					'Example: Introduce basic knowledge to most students...'
			},
			DELETION: {
				TITLE: 'Delete Lesson',
				SUBTITLE: 'Deleted lessons cannot be restored',
				BUTTON: 'Delete Lesson',
				POPDUP_CONFIRM_TITLE: 'Are you sure you want to delete this lesson?',
				POPDUP_CONFIRM_MESSAGE: 'Deleted lesson cannot be restored'
			}
		}
	},
	FEE: {
		PLACEHOLDER_FILTER_STUDENT_NAME: 'Search by student name',
		TH_STUDENT_NAME: 'Student Name',
		TH_DEADLINE: 'Deadline',
		TH_DATE_PAYMENT: 'Date of submission',
		TH_FEE: 'Tuition fee (đ)',
		TH_METHOD: 'Method',
		TH_STATUS: 'Status'
	},
	MSG_UPDATING_LESSON: 'Updating lesson...',
	MSG_UPDATE_LESSON_SUCCESS: 'Lesson update successful!',
	DAY_IN_WEEK: {
		TITLE_MONDAY: 'Monday',
		TITLE_TUESDAY: 'Tuesday',
		TITLE_WEDNESDAY: 'Wednesday',
		TITLE_THURSDAY: 'Thursday',
		TITLE_FRIDAY: 'Friday',
		TITLE_SATURDAY: 'Saturday',
		TITLE_SUNDAY: 'Sunday'
	}
}
const ONLINE_COURSE_DETAIL = {
	TOOLTIP_RETURN_TO_ONLINE_COURSE_LIST: 'List of Online Courses',
	TAB_INFO: 'Information',
	TAB_LEARNING_CONTENT: 'Learning content',
	TAB_RESULT: 'Result',
	TAB_PROGRESS: 'Progress',
	TAB_DISCUSS: 'Discussion'
}
//THÔNG TIN KHOÁ HỌC ONLINE
const ONLINE_COURSE_OVERVIEW = {
	//-----------COURSE_INTERACTION------------
	INTERACTION: {
		VIDEO_FREE: {
			TITLE: 'Free experience',
			SUBTITLE:
				'Configuration allows students preview some video lectures for free.',
			BUTTON_LIST: 'List of Preview Videos',
			//COURSE_INTERACTION_TRIAL_POPUP
			TRIAL_POPUP: {
				TITLE: 'List of preview videos',
				LABEL_PICK_LESSON: 'Select video lesson',
				PLACEHOLER_SELECT_LESSON: 'Please select video lecture',
				TEXT_NO_DATA: 'All video lectures have been added to preview',

				TITLE_EMPTY_DATA:
					'There are no video lectures yet. Please create more video lectures to be able to use this function.',
				BUTTON_CLOSE: 'Close',
				TEXT_UNCLEAR: 'Unknown'
			}
		}
	},
	//COURSE_VIDEO

	TH_VIDEO_LESSON: 'Video lecture',
	TH_CHAPTER_TITLE: 'Chapter',
	TH_ACTION: 'Action',
	//EMPTY DATA

	//STUDY_ROUTE
	TITLE_STUDY_ROUTE: 'Learning Progress',
	SUBTITLE_STUDY_ROUTE: 'Configure learning progress rules',
	CHECKBOX_STUDY_ROUTE_SETTING:
		'It is mandatory for students to complete the current lesson before moving on to the next lesson ',

	//SHOW_MY_COURSE_SETTING
	TITLE_SHOW_MY_COURE: 'Display limit',
	SUBTITLE_SHOW_MY_COURE: 'Configure the course so that only shows on page under certain conditions',
	CHECKBOX_SHOW_MY_COURE:
		'The course is only visible to students who owned a course',

	// EVALUATE: 'Evaluate',
	TITLE_EVALUATE: 'Rating',
	SUBTITLE_EVALUATE: 'Configure course rating rules',
	CHECKBOX_EVALUATE_SETTING_ALLOW:
		'Allow students, who owned this course, to write rating ',
	CHECKBOX_EVALUATE_SETTING_OBLIGATORY:
		'Only display approved ratings',

	//COMMENT_OFF_STUDENT
	TITLE_COMMENT: 'Discussion',
	SUBTITLE_COMMENT: 'Configure rules about the discussion in course',

	CHECKBOX_STUDENT_COMMENT: 'Allow students to discuss and share knowledge at lectures in the course',
	CHECKBOX_BROWSER_STUDENT_COMMENT:
		'Only display approved discussions and knowledge sharing',
	CHECKBOX_TURN_ON_COMMENT_FACEBOOK: 'Integrate Facebook comments on the private introduction page',
	LABEL_APPID_CONFIG: 'AppId',
	PLACEHOLDER_APPID_CONFIG: 'Example: 2221114335145',
    
	//NOTIFICATION_TO_STUDENT
	TITLE_NOTIFICATION: 'Notifications',
	SUBTITLE_NOTIFICATION: 'Configure rules for sending notifications to students',
	CHECKBOX_SEND_NOTIFICATION_TO_STUDENT: 'Allows sending notifications to students when the course is updated',

	//COURSE_PRICING
	TITLE_PRICING_AND_OWNERSHIP: 'Price and ownership',
	SUBTITLE_PRICING_AND_OWNERSHIP:
		'Configure displayed price and ownership for this course. Note: Students can still  own the course without purchasing if administrator grants them permission.',

	RADIO_LABEL_FREE_TITLE: 'Free option',
	RADIO_LABEL_FREE_SUBTITLE: 'Students can own without payment',

	RADIO_LABEL_PAY_TITLE: 'One-time pay option',
	RADIO_LABEL_PAY_SUBTITLE: 'Students need to purchase to own this course',
	LABEL_PRICE_OFFICIAL: 'Final price',
	SUFFIX_CURREN_UNIT: 'VND',
	LABEL_AFTER_DISCOUNT: 'Price before discount',
	HINT_AFTER_DISCOUNT: 'Leave blank if there is no discount',

	RADIO_LABEL_NOT_ALLOW_BUY_COURSE_TITLE:
		'Payment disabled option',
	RADIO_LABEL_NOT_ALLOW_BUY_COURSE_SUBTITLE:
		'Students cannot purchase this course. They can only enrol this course by using an active code or being granted permission.',
	MSG_SMALLEST_PRICE: 'Price cannot be less than {0} đ',
	TEXT_CURRENT_UNIT: 'đ'
}
//NỘI DUNG KHOÁ HỌC ONLINE
const ONLINE_COURSE_CONTENT = {
	TOTAL_SCORE_AND_TIME_PART: {
		TITLE_EDIT_TIME: 'Time edit table',
		TITLE_EDIT_MARK: 'Score correction',
		TITLE_TIME_AND_MARK: 'Time and Score',
		LABEL_QUESTION_GROUP: 'Group question',
		LABEL_TOTAL_SENTENCE: 'Total number of questions/points',
		LABEL_TOTAL_TIME: 'Total testing time',
		TEXT_SENTENCE: 'Question',
		TEXT_MARK: 'point',
		TEXT_MINUTE_UNIT: 'minutes',
		TEXT_SECOND_UNIT: 'seconds',
		TEXT_UNLIMITED: 'Unlimited',
		RADIO_UNLIMITED: 'Unlimited',
		LABEL_POINT: 'Score',
		RADIO_LIMITED: 'Limited',
		RADIO_LIMITED_BY_PART: 'Limited and partial',
		RADIO_LIMITED_BY_QUESTION: 'Limited and calculated per sentence',
		RADIO_CACULATOR_BY_TEN_MARK: 'On a 10-point scale',
		RADIO_CACULATOR_BY_QUESTION_MARK: 'According to the total question score'
	},
	DIALOG_SETTING_EXAM: {
		TITLE: 'Test Configuration',
		POPUP_CONFIRM_EDIT_EXAM_CONFIG_TITLE: 'Change test configuration',
		POPUP_CONFIRM_EDIT_EXAM_CONFIG_MESSAGE:
			'You are in the process of changing the test configuration. This may cause the test score or time to be out of date. Are you still sure to do this?',
		BUTTON_OK: 'Agree',
		PART_SETTING: {
			TITLE: 'Test Structure',
			RADIO_NO_DISTRIBUTION: 'No division',
			RADIO_DISTRIBUTED_IN_MULTIPLE_PARTS: 'Divided into parts'
		}
	},
	BUTTON_ADD_NEW_TOPIC: 'Add New Chapter',
	TITLE_LESSON_NUMBER: 'Lecture no.{0}',
	MSG_ADD_NEW_LESSON_SUCCESS: 'New lesson successfully added!',
	MSG_ADD_NEW_LESSON_FAIL: 'Adding new lesson failed!',
	LABEL_CHAPTER: 'Chapter',
	ENTITY_LESSON: 'Lecture',
	LABEL_CHILD_CHAPTER: 'Child chapter {0}',

	MSG_ADD_CHAPTER_SUCCESS: 'New chapter added successfully!',
	MSG_ADD_CHAPTER_FAIL: 'Adding new chapter failed!',
	MSG_ADD_CHILD_CHAPTER_SUCCESS: 'Add new child chapter successfully!',
	MSG_ADD_CHILD_CHAPTER_FAIL: 'Add new child chappter fail!',
	//COURSE_SECTION
	LABEL_COURSE_UNIT: 'Key',
	BUTTON_ADD_LECTURES: 'Add Lesson',
	BUTTON_ADD_CHILD_CHAPTER: 'Add Child Chapter',
	BUTTON_CREATE_LEARNING_AVAILABLE: 'Create an existing lesson',
	TITLE_ADD_NEW: 'Add New',
	TITLE_CREATE_LECTURES: 'Create an existing lesson',
	//EDIT_CHAPTER_TITLE
	TITLE_CHAPTER_INFO: 'Chapter Information',
	LABEL_TOPIC: 'Chapter Title',
	PLACEHOLDER_TOPIC: 'Enter chapter topic',
	TITLE_SORT_SECTION: 'Sort chapters',
	MSG_SORTING_LESSON: 'Reordering lesson...',
	MSG_CHANGE_ORDER_SORT: 'Successful order change',
	MSG_TEXT_DELETE: 'Delete',
	MSG_TEXT_DELETE_SUCCESS: 'success!',
	POPUP_CONFIRM_DELETE_CHAPTER_TITLE: 'Delete Chapter',
	POPUP_CONFIRM_DELETE_CHAPTER_MESSAGE:
		'Data after deletion cannot be recovered. The system will automatically delete all chapters and lessons that belong to this lesson. Are you sure you want to delete this lesson?',

	//EDIT_UNIT_BASIC_INFO
	TITLE_LESSON: 'Lesson information',
	LABEL_LESSON_NAME: 'Lesson Name',
	PLACEHOLDER_LESSON_NAME: 'Enter lecture name',
	CHECKBOX_DESCRIPTION_LESSON: 'Lesson Description',
	CHECKBOX_ATTACH_FILE: 'Attachment',
	PLACEHOLDER_PICK_FILE_UPLOAD: 'Select upload file',

	//COURSE_EDIT_UNIT_DISCUSSION
	TITLE_DISCUSSION: 'Discussion',
	CHECKBOX_DISCUSSION_IN_LESSON: 'Allow students to discuss in the lecture',
	CHECKBOX_ACCEPT_DISCUSSION: 'Required discussion for students',

	//COURSE_EDIT_UNIT_TIME_CONDITION
	TITLE_EXPIRED_TIME: 'Open and close time',
	CHECKBOX_TIME_CONDITION: 'Time to do the test',
	PLACEHOLDER_TIME_CONDITION_BY_PART: 'Time by section',
	LABEL_TIME_CONDITION_BY_MINUTE: 'Test time (in minutes)',
	PLACEHOLDER_TIME_CONDITION_BY_MINUTE: 'Enter time',
	LABEL_RANGE_TIME: 'Timer to open and close allow access',
	LABEL_RANGE_TIME_FROM_DATE: 'Avalable Date',
	LABEL_RANGE_TIME_FROM_HOUR: 'Start hour',
	LABEL_RANGE_TIME_TO_DATE: 'End date',
	LABEL_RANGE_TIME_TO_HOUR: 'End hour',

	LABEL_SETTING_RESULT_PUBLISH_TIME: "Setting result publish time",
	LABEL_PUBLISH_DATE: "Publish date",
	LABEL_PUBLISH_HOUR: "Publish hour",


	MSG_CHECK_TIME_CONDITION_AGAIN: 'Invalid access time!',
	MSG_CHECK_START_TIME_CONFITION_AGAIN:
		'The start time must be before the end time!',

	//COURSE_EDIT_UNIT_RULES
	TITLE_RULES: 'Rules',
	CHECKBOX_SUBMIT_ONCE: 'Only allow students to do the test once',
	CHECKBOX_RANKING_HIDDEN: 'Hide rank after completing the task',
	CHECKBOX_RESULT_HIDDEN: 'Hide scores and results after completing the test',
	CHECKBOX_ANSWER_HIDDEN: "Hide correct answer when viewing results",
	CHECKBOX_ENABLE_DETECT_FOCUSOUT: 'Track the number of times students leave the work',
	WARNING: "Warning",
	WARNING_MESSAGE_MIN_SCORE: "The minimum score configuration you just set will not apply to students who have completed the test before. Please pay close attention to the cases where students have completed the lesson.",
	ERROR_MESSAGE_MIN_SCORE: "The minimum score is not matching the scale and score of the test.",
	WARNING_MESSAGE_MIN_SCORE_ONLY_ONE: "This is a test with a minimum score and students can only take it once. If students fail, they will not be able to complete this test to move to the next test. Are you sure you want to continue?",

	//COURSE_EDIT_TEST_EXAM_RULES...
	POPUP_CONFIRM_CONFIGURATION_QUESTIONS_TITLE:
		'Transfer question configuration',
	POPUP_CONFIRM_CONFIGURATION_QUESTION_SUBTITLE:
		'Do you want to proceed with the configuration transfer to',
	POPUP_CONFIRM_SEPARATE_QUESTION: 'divide the question into sections.',
	POPUP_CONFIRM_MERGE_QUESTION: 'put all questions into a list.',
	POPUP_CONFIRM_CONFIGURATION_QUESTIONS_MESSAGE:
		'Operation may cause questions to be changed.',
	BUTTON_AGREE: 'Agree',

	POPUP_CONFIRM_SETTING_TIME_TITLE: 'Toggle time settings',
	POPUP_CONFIRM_SETTING_TIME_MESSAGE:
		'Do you want to move the time setting forward by section? You will have to reconfigure the time for each section.',

	LABEL_QUESTION_NOT_SELECT: 'No answer selected',
	//COURSE_EDIT_VIDEO_RULES
	TITLE_VIDEO_SETTING: 'Video lecture settings',
	LABEL_USING_VIDEO: 'Use the available video link',

	INTERACT_COURSE_CARD_SETTING: {
		TITLE: 'Direct access to the course',
		TOGGLE_DIRECT_ACCESS_TO_THE_COURSE:
			'Direct access to the course without popup'
	},

	//BUTTON
	BUTTON_ADD_QUESTION: 'Add question',
	IMPORT_QUESTION: {
		TRIGGER_BUTTON: 'Upload questions',
		TRIGGER_OPTION_TEMPLATE: 'Download sample',
		TRIGGER_OPTION_TUTORIAL: 'View tutorial',
		TRIGGER_OPTION_UPLOAD: 'Upload file from computer',
		MSG_UPLOAD_LOADING: 'Processing...',
		MSG_ERROR_NO_CONTENT: 'There is no data in your file. Please re-check your file again.',
		MSG_ERROR_WRONG_CONTENT: 'There are {0} filtered questions, in which {1} questions not in right format. Please re-check them: {2}',
		MSG_ERROR_BUSY: 'There is many requests at the moment. Please try again later.',
	},
}
const COURSE_UNIT_RESOURCE_SELECTION = {
	LABEL_PDF_SLIDE: 'PDF slide',
	LABEL_TEST: 'Test (self-created test)',
	LABEL_EXERCISE: 'Exam (available topics)',
	LABEL_MATRIX: 'Test (matrix)',
	LABEL_HOMEWORK: 'Homework',
	LABEL_EMBEDDED: 'Embedded',

	//QUESTION_SETTING
	QUESTION_SETTING: {
		TITLE: 'Test questions and rules',
		CHECKBOX_SHOW_RANDOM: 'Questions appear in random order',
		CHECKBOX_SORT_AUTO:
			'If the question is in the group then the order will be changed instead of the question.',
		CHECKBOX_SORT_GROUP_AUTO:
			'If the test is divided into multiple sections, the questions/groups of questions will only change the order in the section.',
		CHECKBOX_MIXING_ANWSER: 'Mix answers in sentences',
		CHECKBOX_RATIO_MARK: 'Questions with different scores',
		CHECKBOX_ON_CHANGE_ONE_BY_ONE:
			'Students work in order of sentences or groups of sentences',
		CHECKBOX_IS_DIABLE_PREVIOUS:
			'Students are not allowed to view and redo completed questions',
		CHECKBOX_HAS_MIN_SCORE:
			'Students are not allowed to view next lesson if score less than min',
		MIN_SCORE: "Min Score"
	},

	POPUP_CONFIRM_DELETE_LESSON_TITLE: 'Delete Lesson',
	POPUP_CONFIRM_DELETE_LESSON_MESSAGE:
		'Data after deletion cannot be recovered. The system will automatically delete all content belonging to this lecture. Are you sure you want to perform this operation?',
	MSG_DELETE_LESSON_SUCCESS: 'Lesson deletion successful!',
	//COURSE_EDIT_UNIT_WITH_TYPE_VIDEO....
	SUBTITLE_LIMIT_CAPACITY_FOR_VIDEO: 'Upload limit is 2.5GB',
	TITLE_VIDEO: 'Video',

	//COURSE_EDIT_UNIT_WITH_TYPE_VIDEO_EXTERNAL
	SUBTITLE_VIDEO_LINK_HINT:
		"Use the video embed link (usually 'https://...embed...')",
	LABEL_VIDEO_LINK: 'Video link',
	PLACEHOLDER_VIDEO_LINK: 'Enter video link',
	PLACEHOLDER_INPUT_LINK: 'Enter path',

	//COURSE_EDIT_UNIT_WITH_TYPE_PDF
	TEST_PDF: {
		TITLE_ANSWER: 'Answer',
		QUESTION_FORM: {
			BUTTON_ADD_ONCE_QUESTION: 'Add 1 sentence',
			BUTTON_ADD_MANY_QUESTION: 'Add more sentences'
		}
	},
	//COURSE_EDIT_UNIT_WITH_TYPE_TEST
	LABEL_TOTAL_SCORE: 'Total Score',
	LABEL_TOTAL_TITME: 'Time',
	BUTTON_ADD_PATH: 'Add Section',
	LABEL_UNTITLED: 'Untitled',
	LABEL_TIME_FOR_PART: 'Time for section',
	BUTTON_EDIT_PART: 'Edit Section',
	BUTTON_DELETE_PART: 'Delete section',
	//CREATE_QUESTION_TEST
	BUTTON_ADD_ONCE_QUESTION: 'Add 1 sentence',
	BUTTON_ADD_QUESTIONS: 'Add multiple sentences',
	//CREATE_QUESITON_DIALOG
	TITLE_ADD_MANY_QUESTION: 'Add many questions',
	SWITCH_QUESTION_MANY_ANSWER: 'Multiple answer question',
	LABEL_QUESTION_TYPE: 'Sentence Type',
	BUTTON_FILL_IN_BLANK: 'Fill in the blank',
	LABEL_TIME_FOR_QUESTION: 'Time for each question (in seconds)',
	PLACEHOLDER_TIME_FOR_QUESTION: 'Enter time',
	LABEL_MARK_FOR_QUESTION: 'Score for each question (scale of 10)',
	PLACEHOLDER_IMPORT_MARK: 'Enter Score',
	//EDIT_PART_DIALOG
	TITLE_EDIT_PART_DIALOG: 'Edit Section',
	TITLE_ADD_NEW_PART_DIALOG: 'Add New Section',
	LABEL_PART_TITLE: 'Title',
	PLACEHOLDER_IMPORT_PART: 'Enter a title for the question section',
	TITLE_SETTING_TIME_FOR_PART: 'Set the part time',
	RADIO_IMPORT_TIME: 'Enter the time yourself',
	RADIO_SYTEM_CACULATE_TIME:
		'Allows the system to calculate itself based on the questions',
	LABEL_DURATION_AUTO_CACULATE: 'Test time (in seconds)',
	PLACEHOLDER_IMPORT_TEST_TIME: 'Enter test time',
	POPUP_CONFIRM_DELETE_PART_QUESTION_TITLE: 'Delete the question section',
	POPUP_CONFIRM_DELETE_PART_QUESTION_MESSAGE:
		'Deleting the question section will delete all questions in the section, are you sure you want to delete this section?',
	MSG_CHECK_MART_TEST_AGAIN: 'The total score of the test must be 10!',
	MSG_CHECK_NUMBER_QUESTION: 'The number of questions must be greater than 0',
	//CREATE_QUESTION_TEST
	//LIST_QUESTION_TEST
	TITLE_QUESTION: 'Question',
	TITLE_QUESTION_CHILD: 'Subquestion',
	TITLE_QUESTION_CHILD_GROUP: 'subquestion - group',
	LABEL_QUESTION_GROUP: 'Group question',
	LABEL_IMPORT_QUESTION_CONTENT: 'Enter your answer...',
	LABEL_IMPORT_DESCRIPTION_QUESTION_CONTENT: 'Enter a description',
	BUTTON_SAVE_QUESTION_CONTENT: 'OK',

	POPUP_DELETE_QUESTION_TITLE: 'Are you sure to delete this question?',
	POPUP_DELETE_QUESTION_MESSAGE:
		'Data after deletion cannot be recovered. The system will automatically delete all content and answers belonging to this course. Are you sure you want to delete this question?',
	MSG_CHECK_ANSWER_FOR_QUESTION: 'Missing answer to question',

	//COURSE_EDIT_UNIT_WITH_TYPE_EXERCISE
	SUBTITLE_IMPORT_REQUIREMENT_OF_TEACHER: 'Describe teacher\'s requirements',
	LABEL_ATTACH_FILE: 'Attachment',
	PLACEHOLDER_PICK_UPLOAD_FILE: 'Select file to upload',
	LALBE_SECOND_UNIT: 'seconds',

	//COURSE_EDIT_UNIT_WITH_TYPE_HOMEWORK
	TITLE_ASSIGN_TEACHER: 'Assign teachers to mark papers',
	SUBTITLE_ASSIGN_TEACHER:
		'Assigned instructors can only view submissions from students and perform grading operations. Student information such as full name, email will be hidden to ensure objectivity in the grading process.',
	LABEL_CHANGES_NOT_SAVE_LESSON:
		'Your changes have not been saved. Please save the lecture to complete the changes.',
	LABEL_TEACHER_GRADING_LESSONS: 'Teacher graders',
	PLACEHOLDER_TEACHER_GRADING_LESSONS: 'Select a grader',
	TOOLTIP_TEACHER_MARK_LAST: 'Last grader',
	TOOLTIP_MARK_LAST_TIME: 'Last time of marking',
	TOOLTIP_COPY_SUBJECTS: 'Copy Submission',
	BUTTON_ASSIGN: 'Assign',
	MSG_DISTRIBUTION_TEACHER: 'Teacher assignment was successful!',
	MSG_PLEASE_TRY_DISTRIBUTION_TEACHER_AGAIN:
		'At present, it is not possible to assign lecturers. Please try again later.',
	MSG_CHECK_PICK_TEACHER_MARK: 'Please select a grading instructor',
	MSG_REMOVE_ASSIGN_TEACHER_SUCCESS:
		'Aborted the assignment of the lecturer to mark the test successfully!',
	MSG_CHECK_REMOVE_ASSIGN_TEACHER_AGAIN:
		'At present, it is not possible to cancel the assignment of lecturers. Please try again later.',
	MSG_PICK_TEACHER_WANT_REMOVE:
		'Please select the instructor you want to unassign',

	//COURSE_EDIT_UNIT_WITH_TYPE_MATRIX
	//DOWNLOAD_EXAM_POPUP
	TITLE_CONFIG_DOWNLOAD: 'Configure downloads',
	LABEL_THREADS_TITLE: 'Title of the title',
	PLACEHOLDER_IMPORT_EXAM_TITLE: 'Enter a title for the test',
	TITLE_TEST_TIME: 'Test time (minutes)',
	DOWNLOAD_SUBJECTS: 'Download theme',
	ENTITY_PAGE: 'page',
	TITLE_EXAM_CODE: 'Code',
	MSG_DATA_LOADING: 'Loading data',
	PLACEHOLDER_IMPORT_ANWSER: 'Enter answer',
	LABEL_MARK: 'Score',
	LABEL_TIME: 'Time',
	LABEL_ANWSER_UNIT: 'answer',
	MSG_CHECK_CREATE_DATA: 'No test data created',
	MSG_CHECK_EXAM_CODE_AGAIN: 'Please check the code again',
	MSG_DOWNLOAD_EXAM_SUCCESS: 'Title download successful!',
	MSG_CREATING_EXAM: 'Initializing...',
	//TABLE_MATRIX
	TH_QUESTION_LEVEL: 'Level (sentence/point)',
	TH_TOTLA_MARK: 'Total',
	BUTTON_ADD_TOPIC: 'Add Subject',
	LABEL_EXAM_CODE_QUANTITY: 'Number of tokens',
	PLACEHOLDER_EXAM_CODE_QUANTITY: 'Enter the number of topics to create',
	TH_TOPIC_QUESTION: 'question subject',
	TH_PLUSS_POINT: 'Plus',
	//ADD_TOPIC_MATRIX_DIALOG:""
	TITLE_MAXTRIX_CONTENT: 'Add matrix content',
	LABEL_TOPIC: 'Theme',
	PLACEHOLDER_TOPIC: 'Select topic',
	LABEL_LEVEL_QUESTION: 'Level:',
	PLACEHOLDER_IMPORT_MARK_NUMBER: 'Enter score',
	LABEL_QUESTION_UNIT: 'sentence',
	LABEL_POINT_UNIT: 'point',
	BUTTON_CREATE_TEST_MATRIX: 'Generate',

	MSG_IMPORT_INFO_ONCE_LEVEL: 'Enter the most complete information 1 level!',

	MSG_CHECK_QUESTION_QUANTITY_SETTING_AGAIN:
		'The number of sentences installed is larger than the number of sentences on the system, please check again!',
	MSG_CHECK_TOTAL_MARK_AGAIN: 'Total score must be 10!',
	MSG_CHECK_MARK_AGAIN: 'Check your score, time or answer{0}',
	MSG_CHECK_TOPIC_AGAIN: 'Theme already exists!',
	MATRIX_FUNC: {
		POPUP_CONFIRM_CREATE_NEW_TITLE: 'Generate new token',
		POPUP_CONFIRM_CREATE_NEW_MESSAGE:
			'The system will delete old codes and regenerate new ones according to the matrix you just updated. Are you sure you want to continue?',
		MESSAGE_CREATE_NEW:
			'The maximum number of threads that can be created is 1000',

		POPUP_CANCEL_RESULT_OF_STUDENT_TITLE: 'Cancel old student results',
		POPUP_CANCEL_RESULT_OF_STUDENT_MESSAGE:
			'There are students who did the old question and got results. Do you want the system to delete the results of those students?',
		MSG_REMOVE_RESULT_OF_STUDENT_SUCCESS: 'Delete result successful!',
		ENTITY_TEST: 'Exam',
		ENTITY_EXAM: 'Exam',
		ENTITY_QUESTION: 'question',
		MSG_CREATE_EXAM_SUCCESS: 'Exam successfully created'
	},
	EMBEDDED_LESSON: {
		TITLE: "Embedded Link",
		PLACEHOLDER: "Input Embedded Link Here"
	},
	TEST_MATRIX: {
		TITLE: 'Detailed Content',
		NO_PARTITION_MATRIX: {
			TOPIC_MATRIX: {
				TITLE_MATRIX_CONTENT: 'matrix content',
				LABEL_TOPIC: 'Theme',
				LABEL_DIFFICULTY_LEVELS: 'Difficulty Level',
				LABEL_LEVELS: 'Level',
				LABEL_NUMBER: 'Number of sentences',
				TOTAL_TIME: {
					LABEL_TOTAL_TIME: 'Total Time',
					LABEL_EVERY_QUESTION: 'Each sentence'
				},
				TOTAL_SCORE: {
					LABEL_TOTAL: 'Total Score',
					LABEL_EVERY_QUESTION: 'Each sentence'
				},
				RADIO_SELECT_QUESTION_RANDOM:
					'Randomly select questions and groups of questions',
				RADIO_SELECT_QUESTION_SIGNLE_RANDOM:
					'Randomize only individual questions',
				RADIO_SELECT_QUESTION_GROUP_RANDOM:
					'Randomly select question groups only',
				//MESSAGE
				MSG_INPUT_FULL_INFO_ONCE_LEVEL:
					'Enter the most complete information 1 level!'
			},
			MSG_ALREADY_EXIST: 'Theme already exists!'
		},
		PARTITION_MATRIX: {
			PART_HEADER: {
				LABEL: 'Title'
			}
		},
		LABEL_EXAM_CODE_QUANTITY: 'Number of tokens',
		PLACEHOLDER_INPUT_EXAM_CODE_QUANTITY: 'Enter the number of topics to create'
	},
	QUESTION_SELECT_TYPE: {
		LABEL_ADD_QUESTION_QUANTITY: 'Number of sentences to add',
		TOGGLE_QUESTION_MANY_ANWSER: 'Multiple answer question',
		LABEL_CONNECTIVE: "Connective",
		CHECKBOX_CREATE_QUESTION_IN_ONCE_GROUP:
			'The questions created are in 1 group',
		BUTTON_ANWSER: 'answer',
		BUTTON_FILL_BLANK: 'Fill in the blank'
	},
	//edit_unit_function
	ENTITY_TEST: 'test question',
	ENTITY_QUESTION: 'question',
	MSG_CRATE_TEST_SUCCESS: "'Test successfully created'",
	MSG_CONFIRM_DELETE_OLD_TEST_TITLE:
		'The system will delete the old thread and create a new one, are you sure you want to do this?',
	MSG_CONFIRM_DELETE_OLD_TEST_MESSAGE:
		'Data after deletion cannot be recovered',
	MSG_MAXIMUM_NUMBER_OF_TOPICS_CREATED:
		'The maximum number of topics that can be created is 1000',

	//CoursePopupSelectDuplicateUnit
	TITLE_COPY_OLD_LESSON: 'Copy from old lecture',
	LABEL_SELECT_LESSON: 'Select lesson',
	PLACEHOLDER_FILTER_LESSON: 'Search for lectures',
	MSG_CREATING_LESSON: 'Creating a lesson...',
	MSG_CREATE_LESSON_SUCCES: 'Lecture creation successful!',
	MSG_NOT_FULL_CONFIGURATION: 'This lesson is not fully configured!',
	MSG_COPY_LESSON_SUCCESS: "Copy lesson success!",
	LIST_ONLINE_COURSE: 'List of Online Courses',

	//CHECK_LIST_SETTING
	TITLE_STRUCTURE_OF_TEST_DIVISION:
		'The test structure is divided into several parts',
	TITLE_STRUCTURE_OF_THE_TEST_WITHOUT_DISTRIBUTION: 'Undivided test structure',
	CHECKBOX_QUESTIONS_ALREADY_ANSWERING: 'Questions appear in random order',
	CHECKBOX_DIFFERENT_POINT_RATE: 'Questions with different score rates',
	CHECKBOX_MIX_ANSWERS: 'Mix answers in sentences',
	CHECKBOX_QUESTION_ONE_BY_ONE:
		'Students do the work in order of sentences one by one or groups of sentences one by one',
	CHECKBOX_DISABLE_PREVIOUS:
		'Students are not allowed to view and redo completed questions',

	CHECKBOX_TIME_LIMIT_FOR_EXAM: 'Time limit',
	CHECKBOX_TIME_LIMIT_DISTRIBUTE_PARTS:
		'Time is limited and divided by parts',
	CHECKBOX_TIME_LIMIT_DISTRIBUTE_SENTENCE:
		'Time is limited and divided by sentences',
	CHECKBOX_UNLIMITED_TIME_FOR_EXAM: 'Unlimited test time',

	CHECKBOX_SCOPE_OF_TEN_POINTS: 'Score on a 10-point scale',
	CHECKBOX_POINT_FOR_SUMMARY_OF_QUESTIONS:
		'Score is calculated according to the total score of the questions'
}
const COURSE_SECTION_REPORT = {
	RESCORING: {
		ENTITY_TEST: 'Test',
		POPUP_CONFIRM_UPDATE_RESULT_OF_STUDENT_TITLE: 'Update student results',
		POPUP_CONFIRM_UPDATE_RESULT_OF_STUDENT_MESSAGE:
			'You just changed the content of the test. Some students have done the test and got results. The system will automatically update the scores for those students.',
		MESSAGE_UPDATE_SUCCESS: 'Update successful!'
	},
	COURSE_REPORT_WRAPPER: {
		LABEL_GROUP_NAME: 'Group Name',
		PLACEHOLDER_INPUT_GROUP: 'Enter group name and select',
		BUTTON_VIEW_FILTER: 'Filter and view',
		TEXT_HOMEWORK: 'homework',
		TEXT_EXAM: 'Test'
	},
	LINK_RETURN_TO_EXAM_LIST: 'Test List',
	BUTTON_VIEW_REQUIMENT: 'View topic',
	//----------COURSE_REPORT_HOMEWORK------------
	HOMEWORK_FILTER: {
		LABEL_FILTER_BY_GROUP: 'Filter by group',
		PLACEHOLDER_INPUT_AND_GROUP_NAME: 'Enter group name and select',
		LABEL_STUDENT_NAME: 'Student Name',
		PLACEHOLDER_FILTER_BY_STUDENT_NAME: 'Search by student name',
		LABEL_STATUS_SUBMIT: 'Submission Status',
		PLACEHOLDER_STATUS_SUBMIT: 'Status',
		OPTION_ALL: 'All',
		OPTION_SUBMITTED: 'Submitted',
		OPTION_APPROVED: 'Approved',
		OPTION_HAVED_REFUSED: 'Rejected',
		OPTION_NOT_SUBMITTED: 'Not yet submitted'
	},
	HOMEWORK_ACTION: {
		MSG_ACCEPT_SUCCESS: 'Successfully browsed',
		MSG_REFUSED_SUCCESS: 'Successfully refused',
		MSG_SAVE_SUCCESS: 'Saved successfully'
	},
	LABEL_FILTER_COURSE: 'Select a course',
	LABEL_ALL: 'All',
	LABEL_FILTER_TEST_BY_REQUEST: 'Select the required test',
	ASSIGNED_HOMEWORK: {
		TITLE: 'Test List',
		TH_COURSE: 'Course',
		TH_UNIT_TITLE: 'Test',
		TH_HOMEWORK_COUNT: 'Submissions',
		TH_IS_ALL_HOMEWORK_MARKER: 'Termed',
		TH_ACTION: 'Action'
	},
	POPUP_REQUIREMENT: {
		TITLE: 'Title',
		TITLE_INTRODUCT: 'About',
		TITLE_REQUIREMENT_OF_EXERCISE: 'Assignment requirement'
	},

	//HOMEWORK_EXCEL_EXPORT
	BUTTON_EXPORT_EXCEL: 'Export Excel',
	TH_STUDENT: 'Student',
	TH_EMAIL: 'Email',
	TH_STATUS: 'Status',
	TEXT_WATCHED: 'Watched',
	TEXT_NOT_SEEN_LEARNING: "Haven't watched the lecture",
	TH_TIME: 'Time',
	TH_POINT: 'Point',
	TEXT_NOT_POINT: 'No points',
	TH_EXERCISE_NAME: 'Assignment Name',

	//HOMEWORK_ARCHIVEALL
	BUTTON_DOWNLOAD_ALL_EXCEL: 'Download All',
	ENTITY_EXERCISE_STUDENT: 'Students assignment',
	MSG_GROUP_STUDENT_DOES_NOT_EXIST: 'Group does not exist',
	MSG_COMPRESSING: 'Compressing...',
	MSG_COMPRESSING_TO: "Compressing second student's work",
	//TABLE_HOMWORK
	BUTTON_DOWLOAD_FILE_SELECTED: 'Download selected file',
	TOOLTIP_TEACHER_ACCEPT_LAST_LESSON: 'Last time teacher',
	TOOLTIP_TIME_ACCEPT_LAST_LESSON: 'Last time of grading',
	TH_HOMEWORK_NAME: 'Post Name',
	TH_STUDENT_NAME: 'Student Name',
	TH_SUBMIT_TIME: 'Submission Time',
	//TH_STATUS:"Status",
	TH_MARK: 'Score',
	TH_TEACHER: 'Teacher',
	TH_MARK_AT: 'Grading time',
	TH_ACTION: 'Action',
	TEXT_NO_SCORE: 'Undotted',
	TEXT_SUBMITTED: 'Submitted',
	TEXT_APPROVED: 'Approved',
	TEXT_REFUSE: 'Deny',
	TEXT_UNSUBMIT: 'Unsubmitted',

	//POPUP_VIEW_HOMEWORK
	TITLE_VIEW_STUDENTS_LESSONS: "View student's work",
	LABEL_EXERCISE_TYPE_LINK: 'Exercise submitted as a link',
	LABEL_CLICK_LINK:
		"Click the link below to see the details of the student's assignment",
	BUTTON_MARKING: 'Grading',
	MSG_DOWNLOADING: 'Loading...',
	MSG_NOT_FOUND_LINK_PDF:
		'The pdf file path could not be found! Please try again.',

	//POPUP_REPORT_APPROVED_HOMEWORK
	TITLE_BROWSER_EXERCISE: 'Accept Assignment',
	SUBTITLE_BROWSER_EXERCISE:
		'Browsing this assignment will close the last lecture and the student cannot submit the assignment for this lesson again.',
	SUBTITLE_DISPLAY_RESULT:
		"The result will be displayed on the student's system. Besides, you can send comments to students by selecting 'Add Comment' below",
	LABEL_MARKING: 'Grading',
	LABEL_MARKING_BY_NUMBER: 'Grading by number',
	LABEL_COMMENT: 'Comment',
	PLACEHOLDER_IMPORT_COMMENT_FOR_HOMEWORK:
		'Enter comments for student homework',
	LABEL_ATTACH_COMMENT: 'Add a comment',
	MSG_ACCEPT_EXERCISE_SUCCESS: 'Accept assignment successfully',
	SUBJECT_COMMENT_HOMEWORK: 'Homework Comment',
	BUTTON_ACCEPT: 'Accept',
	MSG_CHECK_LINK_FILE_PDF:
		'The pdf file path could not be found! Please try again.',

	//POPUP_DENY_HOMEWORK
	TITLE_DENY_HOMEWORK: 'Deny assignment',
	SUBTITLE_DENY_HOMEWORK:
		'Rejecting this assignment means reopening the lecture and the student has the right to submit the assignment again.',
	SUBTITLE_DENY_HOMEWORK_NOTE:
		"Alternatively, you can submit a reason for requesting to be resubmitted by clicking 'Add Comment' below.",
	//LABEL_COMMENT:"Comment",
	PLACEHOLDER_COMMENT: 'Enter comments for student homework',

	MSG_TIME_SUBMIT_NOT_YET: 'The submission time is not over yet!',
	MSG_ACCEPT_EXERCISE: 'Successfully browsed',
	MSG_REFUSE_SUCCESS: 'Successfully refused',
	MSG_SELECT_GROUP: 'Please select a group',

	//----------COURSE_REPORT_DASHBOARD_EXAM -------------
	TITLE_REPORT_DASHBOARD_EXAM: 'Result of',
	LABEL_STUDENT_DONE: 'Number of students completed',
	TEXT_STUDENT: 'student',
	LABEL_STUDENT_TOP: 'Top ranked student',
	TEXT_NOT_NAME: 'No name',
	TEXT_NOT_EMAIL: 'No email',
	TEXT_DO_NOT_EXAM: 'Not done',
	LABEL_NO_ONE_SUBMIT: 'No student has completed this test',
	LABEL_MARK_DISTRIBUTION: 'Score distribution',
	LABEL_AVERAGE_SCORE: 'Grade Point Average',
	LABEL_MOST_INCORRECT_QUESTIONS: 'Most incorrect question',
	LABEL_NOT_DATA_YET: 'No data yet!',
	LABEL_INCORRECT_ANSWER: 'Wrong answer',
	LABEL_TOTAL_QUESTION: 'Over total',
	LABEL_REPORT_DETAIL: 'Detailed report',
	//POPUP_QUESTION_REPORT
	TITLE_QUESTION_REPORT: 'Detailed report',
	PLACEHOLDER_PICK_EMAIL_TYPE: 'Select email template',
	BUTTON_SHOW_PDF: 'View Title',
	LABEL_PAGE: 'Page',
	LABEL_NOT_DATA: 'No data available, please come back later!',
	TEXT_QUESTION: 'Sentence',
	TEXT_NOT_MARK: 'No score yet',
	//SHOW_ANALYTIC
	TITLE_QUESTION_CONTENT: 'question body',
	TITLE_STATISTIC_QUESTION: 'Answer Statistics',
	TEXT_QUESTION_COUNT_WRONG: 'Most Incorrect',
	TEXT_COUNT_WRONG: 'Least false',

	//-----------REPORT_DETAIL_EXAM-----------
	OPTION_LAEDER_BOARD: 'View report by rating',
	OPTION_REPORT_TABLE_EXAM_VIEW: 'View report by test results',
	TEXT_ALL: 'All',
	BUTTON_SUBMITED: 'Status: Completed',
	BUTTON_DOING: 'Status: Working',

	TOOLTIP_FILTER_REPORT_EXAM_BY_RESULTS:
		'When viewing the report according to the test results, the lecturer can see the status of the test and choose to view it according to the group of students participating in the test. check this',
	TOOLTIP_FILTER_REPORT_EXAM_BY_RATING:
		'When viewing the report according to the rankings, the lecturer can know the order of ranking the students performance',
	PLACEHOLDER_IMPORT_PICK_NAME: 'Enter a name and select',
	LABEL_FILTER_BY_STUDENT_NAME: 'Student Name',
	PLACEHOLDER_FILTER_BY_STUDENT_NAME: 'Search by student name',
	//REVIEW TEXT TABLE HEADER
	//TH_STATUS:"Status",
	TH_SUBMIT_COUNT: 'Submit times',
	TH_AVAILABEL_TIME: 'Start',
	MSG_CHECK_GROUP_LEARNING: 'Please select a study group',

	//TABLE REPORT LEADER BOARD
	//LABEL_FILTER_BY_STUDENT_NAME:"Search by student name",
	BUTTON_RELOAD: 'Reload',
	TH_RANK: 'Ranking',
	//TH_STUDENT:"Students",
	TH_LESSON_NAME: 'Post Name',
	TH_START_TIME_SUBMIT: 'Time for first submission',
	TH_LAST_TIME_SUBMIT: 'Last submission time',
	//TH_TIME:"Time",
	TH_OUT_FOCUS: 'Out of focus',
	TH_RESULT: 'Result',
	//TH_POINT:"Point",
	TH_DETAIL: 'Details',
	MSG_DELETE_EXAM_SUCCESS: 'Exam deletion successful!',

	LABEL_RESULT_EXERCISE_DETAIL: 'Exercise details - Result',
	BUTTON_CANCEL_EXAM: 'Cancel the test',
	TEXT_EMPTY: 'Leave blank',
	//-----------COURSE_REPORT_PDF_AND_VIDEO----------------
	//TH_STUDENT_NAME:"Student Name",
	TH_LAST_VIEW: 'Last view at',
	//TH_STATUS:"Status",

	//EXPORT_EXCEL_PDF_AND_VIDEO
	//TH_STUDENT:"Students",
	//TH_EMAIL:"Students",
	//TH_TIME:"Time",
	//TH_STATUS:"Status",
	TEXT_VIEWED: 'Viewed',
	TEXT_VIEWING: 'Watching',
	TEXT_NOT_VIEW: 'Not Viewed',
	TEXT_NOT_VIEW_LESSON: "Haven't watched the lecture",
	TEXT_TOTAL: 'total',

	MSG_CHECK_GROUP_STUDENT_AGAIN: 'Please select a study group',

	POPUP_CONFIRM_DELETE_EXAM_TITLE: 'Are you sure you want to delete your test',
	POPUP_CONFIRM_DELETE_EXAM_MESSAGE: 'Data after deletion cannot be recovered',

	MSG_GETTING_GROUP_DATA: 'Getting group data...',

	//-----------COURSE_REPORT_DEFAULT------------
	LABEL_FILTER_GROUP: 'Filter by group',
	PLACEHOLDER_FILTER_GROUP: 'Enter group name and select',
	CHECKBOX_ALL: 'All',
	BUTTON_VIEW_FILTER: 'Filter and view',
	LABEL_CHECK_PICK_LESSON:
		'Please select a Lecture & Group to filter the results',
	LABEL_REPORT_TABLE_SUBTITLE:
		'After selecting Results in the left sidebar & Filter the upper group, the student results will be displayed as shown below.',

	TH_GROUP: 'Group',
	//TH_STUDENT_NAME:"Student Name",
	//TH_EMAIL:"Email",
	TH_LAST_STUDY: 'Last time studying',
	//TH_LAST_STUDY:"Last lesson",
	TH_PROGRESS: 'Learning Progress',
	//TH_TIME:"Time",
	TH_POIN: 'Points',
	TEXT_DID: 'Done',

	TEXT_EXAMPLE_CLASS: 'Grade 7A1',
	TEXT_EXAMPLE_NAME: 'Nguyen Van A',
	TEXT_EXAMPLE_EMAIL: 'nguyenvana@gmail.com',
	TEXT_EXAMPLE_TIME: '13:30, 12/12/2020',
	TEXT_EXAMPLE_LESSON: 'Lesson 1: Motion',
	TEXT_IS_DONE: 'Done',
	//HOMEWORK
	LABEL_SUBJECTS_CODE: 'Submission Code',
	PLACEHOLDER_INPUT_SUBJECTS_CODE: 'Enter submission code',
	WATCHED: 'Watched',
	WATCHING: 'Watching',
	NOT_WATCH: "Haven't watched",
	TH_SUBMIT_CODE: 'Submission Code',
	TH_TIME_SUBMIT: 'Submission Time',
	TH_POINTING_TIME: 'Pointing Time',

	TITLE_VIEW_SUBJECTS_OF_STUDENT: 'View student submissions'
}
const COURSE_REPORT_EXPORT_EXAM = {
	BUTTON_EXPORT: 'Export Excel',
	TH_STUDENT: 'Students',
	TH_EMAIL: 'Email',
	TH_START_TIME: 'Start at',
	TH_SUBMIT_TIME: 'Submitted at',
	TH_COURSE_NAME: 'Course name',
	TH_LESSON_NAME: 'Lesson name',
	TH_DURATION: 'Complete in',
	TH_VIOLATION: 'Not focused',
	TH_RESULT: 'Result',
	TH_POINT: 'Incomplete',
	TH_FILE_NAME: 'Exam file name',
	TH_STATUS: 'Status',
	TH_TIME: 'Time',
	TH_MARK: 'Score',
	TEXT_INCOGNITO_NAME: 'No name',
	TEXT_INCOGNITO_EMAIL: 'No email',
	TEXT_TOTAL_VIOLATION_UNIT: 'times',
	TEXT_UNFINISHED: 'Incomplete'
}
//TIẾN ĐỘ KHOÁ HỌC ONLINE
const COURSE_PROGRESS = {
	LABEL_GROUP_STUDENT: 'Group of students',
	PLACEHOLDER_SELECT_GROUP_STUDENT: 'Select a group of students',
	OPTION_ALL: 'All',
	PLACEHOLDER_FILTER_BY_STUDENT_NAME: 'Student name',
	BUTTON_FILTER_AND_VIEW: 'Filter and view',
	BUTTON_EXPORT_EXCEL: 'Export Excel',

	TH_GROUP: 'Group',
	TH_STUDENT: 'Students',
	TH_EMAIL: 'Email',
	TH_LAST_STUDY: 'Last study',
	TH_LAST_VIEW_LESSON: 'Last lesson',
	TH_PROGRESS: 'Learning Progress',
	TH_ACTION: 'View details',
	TEXT_PROGRESS: 'Students academic progress',

	MSG_FILTERING_STUDENT: 'Filtering students...',
	MSG_CHECK_GROUP_STUDENT_AGAIN: 'Please select a study group',

	DETAIL: {
		HEADING_TEXT: 'Learning Progress',
		SUB_TEXT: 'Interactive learning last at'
	}
}
const COURSE_PROGRESS_DETAIL = {
	TITLE: 'Students',
	LABEL_STATUS: 'Status',
	PLACEHOLDER_STATUS: 'Submission Status',
	PLACEHOLDER_SELECT_STATUS: 'Select state',
	LABEL_LESSON_TYPE: 'Lecture Type',
	PLACEHOLDER_SELECT_LESSON_TYPE: 'Select lesson type',
	PLACEHOLDER_FILTER_LESSON_BY_NAME: 'Search by post title',
	OPTION_STATUST_ALL: 'All',
	OPTION_STATUST_DONE: 'Done',
	OPTION_STATUST_NOT_START: 'Not started',
	OPTION_NO_SCORE: 'Undotted',
	OPTION_APPROVED: 'Approved',
	BUTTON_FILTER_AND_VIEW: 'Filter and view',

	OPTION_TYPE_ALL: 'All',
	OPTION_TYPE_VIDEO: 'Video',
	OPTION_TYPE_PDF: 'pdf document',
	OPTION_TYPE_PDF_TEST: 'Exam with available PDF topics',
	OPTION_TYPE_ADVANCE_TEST: 'Advanced test with many customizations',
	OPTION_TYPE_MATRIX: 'Random matrix test',
	OPTION_TYPE_HOMEWORK: 'Homework',

	TH_LESSON: 'Article',
	TH_DURATION: 'Duration',
	TH_IS_DONE: 'Done',
	TH_STUDY_COUNT: 'Number of learning',

	TH_TOTAL_TIME: 'Total Time',
	TH_LAST_UPDATE_AT: 'Last study',
	TH_FIRST_SCORE: 'First time score',
	TH_LAST_SCORE: 'Last score',

	TITLE_SUBJECT_COMPLETE_PROGRESS: 'Complete progress',
	TITLE_EXAM_DONE: 'Test Done',
	TITLE_EXERCISE_DONE: 'Exercise done',
	TITLE_VIDEO_VIEWED: 'Watched video lectures',
	TITLE_PDF_VIEWED: 'Viewed pdf lecture'
}
//THẢO LUẬN KHOÁ HỌC ONLINE
const COURSE_DICUSION = {
	TEXT_STUDENT_NAMELESS: 'Anonymous',
	TEXT_NOT_THING: 'None',
	TEXT_REPLIES: 'Response',

	LABEL_TIME: 'Time',
	PLACEHOLDER_AVAILABLE_EXPIRED_DATE: 'Start Date - End Date',
	LABEL_STATUS: 'Status',
	OPTION_ALL_STATUS: 'All Status',
	OPTION_APPROVED: 'Approved',
	OPTION_PENDING: 'Waiting for approval',
	OPTION_WAITING: 'Waiting',
	BUTTON_BROWSER_ALL: 'Accept All',
	BUTTON_FILTER_AND_FIND: 'Filter and find',
	TH_FEEDBACK: 'Response',
	TH_FULL_NAME: 'First and last name',
	TH_EMAIL: 'Email',
	TH_CONTENT: 'Content',
	TH_LAST_UPDATE: 'Last Updated',
	TH_STATUS: 'Status',
	TH_ACTION: 'Action',

	TITLE_REP_COMMENT: 'Reply to comment',
	BUTTON_LOAD_MORE_COMMENT: 'Load more responses',
	BUTTON_VIEW: 'See more',
	BUTTON_HIDE: 'Hide',

	POPUP_CONFIRM_BROWSER_COMMENT_TITLE: 'Confirm action',

	POPUP_CONFIRM_BROWSER_RELIES_MESSAGE: 'Are you sure to browse this response?',
	MSG_BROWSER_COMMNET_SUCCESS: 'Accept Successful Response',

	POPUP_CONFIRM_DELETE_RELIES_MESSSAGE: 'Are you sure to delete this response?',
	MSG_DELETE_REPLIES_SUCCESS: 'Delete response successful',

	POPUP_CONFIRM_BROWSER_COMMENT_MESSAGE: 'Are you sure to browse this comment?',
	MSG_BROWSER_COMMENT_SUCCESS: 'Accept discussion successful',

	POPUP_CONFIRM_BROWSER_ALL_COMMENT_MESSAGE:
		'Are you sure to browse these comments?',
	MSG_BROWSER_ALL_COMMENT_SUCCESS: 'Accept all successful discussions',

	POPUP_CONFIRM_DELETE_COMMENT_MESSAGE: 'Are you sure to delete this comment?',
	MSG_DELETE_COMMENT_SUCCESS: 'Delete discussion successful',

	POPUP_CONFIRM_DELETE_ALL_COMMENT_MESSAGE:
		'Are you sure to delete these comments?',
	MSG_DELETE_ALL_COMMENT_SUCCESS: 'Delete all successful discussions',

	MSG_REPLIES_LOADING: 'Loading response...',

	MSG_GETTING: 'Receiving...',
	MSG_SUCCESS: 'Success!'
}
//BASE_PDF_QUESTION_RENDERER
const BASE_PDF_QUESTION_RENDERRE = {
	LABEL_TIME: 'Time to do the test',
	TEXT_MINUTE: 'minutes',
	LABEL_THREADS: 'Title',
	LABEL_QUESTION: 'Sentence',
	LABEL_ANSWER_THE_QUESTION: 'Answer',

	CHECKBOX_PICK_ONCE_CORRECT_ANSWER: '(Choose 1 correct answer)',
	CHECKBOX_PICK_MULTIPLE_ANSWERS: '(Choose multiple correct answers)',
	CHECKBOX_FILL_BLANK: '(Fill in the blank)'
}
const SECTION_REPORT = {
	// EXAM
	// VIDEO
	// PDF
	// HOMEWORK
	HOMEWORK: {
		POPUP_CORRECTION: {
			BTN_RETURN: 'Back',

			PROGRESS_BAR_ENTITY: 'cure',
			PROGRESS_BAR_MSG_LOADING: 'Saving the fix...',
			PROGRESS_BAR_MSG_ERROR:
				'Currently not able to save the cure. Your network connection is having problems. Try reloading the page!',
			PROGRESS_BAR_MSG_SUCCESS: 'Remedy saved successfully!',
			SECTION_EDITOR: {
				MSG_BACKGROUND_LOADING: 'Loading data',
				MSG_BACKGROUND_ERROR_TITLE: 'Error loading image',
				MSG_BACKGROUND_ERROR_DESCRIPTION:
					'The system is currently unable to load the image, this may be due to the cache on your device. Please clear cache & reload the page OR use an incognito browser. In case the condition persists, contact a support technician.',
				TEXT_MARKER_CONTENT: 'Content Text',
				POPUP_TEXT_EDIT: {
					TITLE_CREATE: 'Generate Text',
					TITLE_EDIT: 'Edit Text',
					CONTENT_PLACEHOLDER: 'Enter text here',
					MSG_CONTENT_VALIDATOR: 'Please enter text content'
				},
				BTN_ACTION_UNDO: 'Previous operation',
				BTN_ACTION_REDO: 'The operation just canceled',
				BTN_ACTION_CHOOSE: 'Select',
				BTN_ACTION_DRAW: 'Draw on image',
				BTN_ACTION_TEXT: 'Text',
				BTN_ACTION_ERASER: 'Eraser',
				BTN_ACTION_ROTATE: 'Rotate',
				ACTION_OPTIONS: {
					ROTATE_CLOCKWISE: 'Rotate 90 degree clockwise',
					ROTATE_COUNTER_CLOCKWISE: 'Rotate 90 degree counter-clockwise',
				}
			},
			SECTION_APPROVE: {
				SUBSECTION_ID_TITLE: 'Submission Code',
				SUBSECTION_MARK_TITLE: 'Grading',
				SUBSECTION_MARK_ACTION: 'Score',
				OPTION_CONTAIN_NOTE: 'Attach note',
				INPUT_NOTE_LABEL: 'Note',
				INPUT_NOTE_PLACEHOLDER: 'Fill your note for this homework',
				SUBSECTION_REMINDER_TITLE: 'Note',
				SUBSECTION_REMINDER_DESCRIPTION:
					'Score, note and corrections will be displayed for students after completing current action.\n\nRemember if the assignment has been approved, student can no longer re-submit.',
				BTN_APPROVE: 'Approve',
				BTN_DENY: 'Deny',
				WARNING_MARK_IS_NOT_NUMBER: 'Invalid number format',
				WARNING_MARK_IS_GREATER_OR_EQUAL_MIN:
					'Number must be greater than or equal to {0}',
				WARNING_MARK_IS_SMALLER_OR_EQUAL_MAX:
					'Number must be less than or equal to {0}',
				MSG_MARK_VALIDATOR: 'Please check the score again'
			},
			SECTION_DENY: {
				INPUT_NOTE_LABEL: 'Denial reason',
				INPUT_NOTE_PLACEHOLDER: 'Please fill in the reason so that student can view to improve next time',
			},
			CONFIRM_APPROVE: {
				TITLE: 'Approve homework',
				DESCRIPTION: 'After approving this homework, student can no longer re-submit.'
			},
			CONFIRM_DENY: {
				TITLE: 'Deny homework',
				DESCRIPTION: 'After denying this homework, student can re-submit his/her homework.'
			},
			CONFIRM_SAVE_BEFORE_CHANGE_FILE: {
				TITLE: 'Confirm saving current file',
				DESCRIPTION:
					'If you choose “Change file, don\'t save”, all of your correction will not be saved.',
				BTN_SAVE: 'Save',
				BTN_DONT_SAVE: 'Change file, don\'t save'
			},
			CONFIRM_SAVE_BEFORE_LEAVE: {
				TITLE: 'Confirm saving changes?',
				DESCRIPTION:
					'If you choose “Leave, don\'t save”, all of your grading, note and correction will not be saved.',
				BTN_SAVE: 'Save',
				BTN_DONT_SAVE: "Leave, don't save"
			},
		},
		EXCEL_EXPORT: {
			PREFIX_FILE_NAME: 'result',
			COLUMN_HEADER: {
				SUBMISSION_ID: 'Submission ID',
				STUDENT_NAME: 'Student',
				STUDENT_EMAIL: 'Email',
				SUBMISSION_STATUS: 'Status',
				SUBMIT_TIME: 'Submit Time',
				SUBMISSION_MARK: 'Mark',
				SUBMISSION_TITLE: 'Homework title'
			},
			SUBMISSION_STATUS: {
				APPROVED: 'Approved',
				DENIED: 'Denied',
				SUBMITTED: 'Submitted',
				NONE: 'Not viewed yet'
			},
			SUBMISSION_MARK: {
				DENIED: 'Denied',
				NONE: 'Not marked yet',
			},
		},
	}
}
const POPUP_EDIT_PART_DIALOG = {
	TITLE_EDIT_OR_DELETE: 'Edit or delete section',
	TITLE_ADD_NEW: 'Add New Section',
	LABEL_HEADER: 'Title',
	PLACEHOLDER_INPUT_HEADER: 'Enter a title for the question section',
	LABEL_DESCRIPTION: 'Description',
	POPUP_CONFIRM_DELETE_TITLE: 'Delete Section',
	POPUP_CONFIRM_DELETE_MESSAGE:
		'Deleting a question section deletes the entire question/group of questions in the section and cannot be undone. Are you sure you want to delete this section?'
}
///
const COURSE_PROGRESS_PDF_TABLE = {
	TITLE: 'Lecture pdf',
	TH_NAME: 'Post Name',
	TH_TIME: 'Complete Time'
}
const COURSE_PROGRESS_VIDEO_TABLE = {
	TITLE: 'Video lectures',
	TH_NAME: 'Post Name',
	TH_TIME: 'Complete Time'
}
const ONLINE_COURSE_WITH_TOPIC_MOBIE = {
	TITLE: 'Course card information',
	LABEL_TOPIC: 'Theme',
	PLACEHOLDER_SELECT_TOPIC: 'Select topic',
	LABEL_HEADER_CARD: 'Tag title',
	PLACEHOLDER_INPUT_HEADER_CARD: 'Enter card title',
	LABEL_BUTTON_DISPLAY: 'Display button',
	PLACHOLDER_BUTTON_INFO_DISPLAY: 'Button display information',
	LABEL_LINK: 'Path',
	PLACEHOLDER_LINK: "Example: https://example.com"
}
const BASE_UPLOAD_DRAGGER = {
	MSG_UPLOAD_LOADING: 'Uploaded, video in preparation',
	MSG_UPLOAD_SUCCESS: 'Upload successful',
	MSG_FILE_UPLOAD_OVER_THE_SIZE:
		'The file you uploaded has exceeded the allowed size. Please double check the file you uploaded!',
	MSG_FORMAT_FILE_UPLOAD_FALSE:
		'The file you uploaded is in the wrong format. Please double check the file you uploaded.',
	MSG_MAX_FILES_REACHED: 'You can only upload {0} file(s)',
}
const BASE_UPLOAD_IMAGE = {
	BUTTON_SELECT_FROM_LIBRARY: 'Select from library',
	BUTTON_UPLOAD_IMAGE: 'Upload photo',
	MODAL_LIBRARY: {
		TITLE: 'Select from library',
		LABEL_FILE_NAME: 'Filename',
		PLACEHOLDER_FILTER_FILE_NAME: 'Search by filename',
		LIST_IMAGE_THUMBNAIL: {
			TITLE_NOT_FOUND_IMAGE: 'Image file not found!',
			LABEL_PAGE: 'Page'
		},
		ATTACHMENT_DETAIL: {
			TITLE: 'File details',
			LABEL_SIZE: 'Size',
			LABEL_CREATE_AT: 'Generate Date',
			BUTTON_EDIT_IMAGE_INFO: 'Edit image information',
			LABEL_FORMAT: 'Format',
			PLACEHOLDER_INPUT_LINK: 'Enter path',
			LABEL_HEADER: 'Title',
			PLACEHOLDER_INPUT_HEADER: 'Enter title'
		},
		BUTTON_SELECT: 'Select'
	}
}

const COURSE_DUPLICATE = {
	ACTION_DUPLICATE: 'Duplicate',
	DUPLICATE_POPUP: {
		TITLE: 'Create duplicated course',
		COURSE_TITLE_PREFIX: 'Duplicate of {0}',
		MSG_INVALID_COURSE_TITLE: 'Course title has been used. Please change another title.'
	},
	MSG_PROGRESS: {
		DUPLICATING_COURSE: 'Duplicating course data...',
		DUPLICATING_COURSE_CONTENT: 'Duplicating course content data...',
		DUPLICATING_COURSE_CONTENT_CHAPTER: 'Duplicating section "{0}"',
		DUPLICATING_DONE: 'Course duplicate process is completed. Moving to duplicated one...',
	}
}

export default {
	COURSE_LIST,
	FORM_CREATE_NEW_COURSE,
	COURSE_OVERVIEW,
	ONLINE_COURSE_DETAIL,
	OFFLINE_COURSE_DETAIL,
	ONLINE_COURSE_CONTENT,
	COURSE_UNIT_RESOURCE_SELECTION,
	COURSE_PROGRESS,
	COURSE_DICUSION,
	BASE_PDF_QUESTION_RENDERRE,
	POPUP_EDIT_PART_DIALOG,
	COURSE_PROGRESS_PDF_TABLE,
	COURSE_PROGRESS_VIDEO_TABLE,
	ONLINE_COURSE_WITH_TOPIC_MOBIE,
	BASE_UPLOAD_DRAGGER,
	BASE_UPLOAD_IMAGE,
	ONLINE_COURSE_OVERVIEW,
	COURSE_SECTION_REPORT,
	COURSE_REPORT_EXPORT_EXAM,
	COURSE_PROGRESS_DETAIL,
	SECTION_REPORT,
	COURSE_DUPLICATE,
	//TITLE
	TITLE_EXAM: 'Test',
	TITLE_THREADS: 'Title',
	TITLE_EXERCISE: 'Exercise',
	TITLE_ACTION: 'Operation',
	TITLE_ANWSER_OF_STUDENT: "Student's answer",
	TITLE_OPERATION: 'Operation',
	//LABEL
	LABEL_SELECT_FILE: 'Select file',
	LABEL_QUESTION: 'Question',
	LABEL_QUESTION_TYPE: 'Question Type',
	LABEL_QUESTION_GROUP: 'Question group',
	LABEL_SENTENCE: 'Sentence',
	LABEL_MARK_UNIT: 'point',
	LABEL_ANWSER: 'Answer',
	LABEL_ANWSER_TRUE: 'Answer',
	LABEL_CURRENT_UNIT: 'VND',
	OPTION_ALL: 'All',
	PLACEHOLDER_INPUT_FILL_BLANK: 'Enter answer',
	//TEXT
	TEXT_UPDATING: 'Updating',
	TEXT_NOT_MARK: 'Not graded',
	//BUTTON
	BUTTON_CONFIRM: 'Confirm',
	BUTTON_CANCEL_TEXT: 'Cancel',
	BUTTON_CANCEL: 'Cancel',
	BUTTON_SAVE: 'Save',
	BUTTON_OK: 'OK',
	BUTTON_DELETE: 'Delete',
	BUTTON_VIEW_FILTER: 'Filter and view',
	BUTTON_SEARCH_FILTER: 'Filter and find',
	BUTTON_REFUSE: 'Deny',
	BUTTON_DOWNLOAD: 'Download',
	BUTTON_DOWNLOAD_ALL: 'Download All',
	BUTTON_ACCEPT: 'Accept',
	BUTTON_BACK: 'Back',
	BUTTON_RELOAD: 'Reload',
	BUTTON_CLOSE: 'Close',
	BUTTON_CREATE: 'Create',
	BUTTON_ADD: 'Add',
	BUTTON_EDIT: 'Edit',
	BUTTON_SEARCH: 'Search',
	BUTTON_DELETE_ANWSER: 'Delete answer',
	BUTTON_MARK: 'Grading',
	BUTTON_DONATE_MARK: 'Score',
	BUTTON_VIEW_DOWNLOAD: 'View and download',
	BUTTON_EXPORT_EXCEL: 'Export Excel',
	BUTTON_INPUT: 'Import',
	BUTTON_PREVIEW: 'Preview',
	//MESSAGE
	MSG_DATA_LOADING: 'Loading data...',
	MSG_UPDATING_DATA: 'Updating data...',
	MSG_UPDATE_DATA_SUCCESS: 'Data update successful!',
	MSG_UPDATING: 'Updating...',
	MSG_UPDATE_SUCCESS: 'Update successful!',
	MSG_UPDATE_FAIL: 'Update failed!',
	MSG_DELETE_SUCCESS: 'Delete successful!',
	MSG_DELETE_FAIL: 'Delete failed',
	MSG_CHECK_GROUP_LEARNING_AGAIN: 'Please select a study group',
	MSG_GETTING_DATA: 'Getting data...',
	MSG_REFUSE_SUCCESS: 'Rejection successful!',
	MSG_COPY_SUCCESS: 'Copy successful',
	MSG_COPY_FAIL: 'Copy failed. Please try again',
	MSG_EXPORTING_DATA: 'Exporting data...',
	//TABLE_HEADER
	TH_COURSE_NAME: 'Course name',
	TH_ACTION: 'Action',
	TEXT_INCOGNITO_NAME: 'No name',
	TEXT_INCOGNITO_EMAIL: 'No email',
	TEXT_STARTED_TIME: 'Started',
	TEXT_DO_NOT_EXAM: 'Not done',
	TEXT_TIMES: 'Times',
	TEXT_DID: 'Done',
	//STATUS DO EXAM
	CHECKBOX_STATUS_DOING: 'Working',
	CHECKBOX_DONE: 'Done',
	MSG_CHECK_INFO_AGAIN: 'Please check the information again!',
	MSG_CHECK_PICK_GROUP_AGAIN: 'Please select a study group',
	//--------------------
	TOOLTIP_ONLINE_COURSE_LIST: 'List of Online Courses',
	DEFAULT_PROGRESS_BAR: 'Is {0} {1} no.{2}', // 0: action, 1: entity, 2: entity number
	BTN_VIEW_DETAIL: 'View detail',
}
