import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const Notification = RepositoryFactory.get('notification')

const state = {}
const actions = {
  async sendToUser(_, params) {
    const res = await Notification.sendToUser(params)
    return res.data
  },
  async sendToGroup(_, params) {
    const res = await Notification.sendToGroup(params)
    return res.data
  },
  async sendToInterest(_, params) {
    const res = await Notification.sendToInterest(params)
    return res.data
  },
  async getCredentialSetting(_, params) {
    const res = await Notification.getCredentialSetting(params)
    return res.data
  },
  async saveSetting(_, params) {
    const res = await Notification.saveNotiSetting(params)
    return res.data
  }
}
const mutations = {}
const getters = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}