<template>
  <div>
    <div class="slick-list">
      <SlickList
        lockAxis="y"
        @sort-end="(event) => $emit('sort-end', event)"
        v-model="sectionNames"
        appendTo=".slick-list"
        lockToContainerEdges
      >
        <SlickItem
          class="section-item"
          v-for="(item, i) in sectionNames"
          :key="i"
          :index="i"
          :disabled="isSlickDisabled"
        >
          <v-icon class="section-item__icon">mdi-drag-vertical</v-icon>
          <v-icon class="mr-2">mdi-image-area</v-icon>
          <div class="full-width subheading">
            {{ generateNameSection(item, isMobile ? isMobile : false) }}
          </div>
          <v-icon @click="editSection(item)" class="section-item__icon mr-2"
            >mdi-cog-outline</v-icon
          >
          <v-icon
            @click="removeItem(item)"
            class="section-item__icon mr-2 action-delete-hover"
            >mdi-delete</v-icon
          >
        </SlickItem>
      </SlickList>
    </div>
    <div class="mt-4 d-flex justify-center">
      <BaseButton
        icon="mdi-plus"
        :text="
          $vuetify.lang.t('$vuetify.SITE_BUILDER.DETAIL.BTN_ADD_COMPONENT')
        "
        @click="addSection"
        outlined
      />
    </div>
  </div>
</template>
<script>
import helpers from '@/helpers'
import {SlickList, SlickItem} from 'vue-slicksort'
import {mapActions} from 'vuex'
export default {
  props: {
    viewData: Array,
    isSlickDisabled: Boolean,
    isMobile: Boolean,
  },
  components: {
    SlickItem,
    SlickList,
  },
  data() {
    return {
      sectionNames: [],
    }
  },
  filters: {
    generateNameSection(val, isMobile) {
      if (!val) return ''
      // eslint-disable-next-line
      let [name, id] = val.split(':')
      let listSections = isMobile
        ? helpers.LIST_SECTION_SCREENBUILDER
        : helpers.LIST_SECTION_SITEBUILDER
      const element = listSections.find((item) => item.value === name)
      return element && element.title ? element.title : ''
    },
  },
  methods: {
    ...mapActions({
      removeError: 'sitebuilder/removeMessageError',
    }),
    removeItem(item) {
      this.removeError()
      this.$confirm.show({
        title: this.$vuetify.lang.t(
          '$vuetify.SITE_BUILDER.DETAIL.CONFIRM_REMOVE_COMPONENT_TITLE'
        ),
        text: this.$vuetify.lang.t(
          '$vuetify.SITE_BUILDER.DETAIL.CONFIRM_REMOVE_COMPONENT_DESCRIPTION'
        ),
        okText: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.BUTTON_CONFIRM'),
        cancelText: this.$vuetify.lang.t('$vuetify.SITE_BUILDER.BUTTON_CANCEL'),
        done: () => {
          this.$emit('onRemoveItem', item)
        },
      })
    },
    editSection(item) {
      this.removeError()
      this.$emit('onEditSection', item)
    },
    addSection() {
      this.removeError()
      this.$emit('onAddSection')
    },
    generateNameSection(val, isMobile) {
      if (!val) return ''
      // eslint-disable-next-line
      let [name, id] = val.split(':')
      let listSections = isMobile
        ? helpers.LIST_SECTION_SCREENBUILDER
        : helpers.LIST_SECTION_SITEBUILDER
      const element = listSections.find((item) => item.value === name)
      return element && element.title ? this.$vuetify.lang.t(element.title) : ''
    },
  },
  watch: {
    viewData: {
      handler(val) {
        if (val && val instanceof Array) {
          this.sectionNames = val.map((item) => item.id)
        }
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.slick-list {
  box-shadow: 0px 1px 2px rgba(9, 30, 66, 0.25);
  > .section-item {
    left: 20px !important;
    background-color: #f8f8f8;
  }
}
.section-item {
  user-select: none;
  cursor: default;
  padding: 14px 5px 14px 2px;
  background: #ffffff;
  border-top: none;
  border-bottom: 1px solid #dddbda;
  display: flex !important;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    box-shadow: -3px 0 0 #4bca81;
  }
  &:active {
    color: black;
  }
  .section-item__icon {
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
  }
  &:hover {
    .section-item__icon {
      visibility: visible;
      opacity: 1;
    }
  }
}
.subheading {
  color: black !important;
}
</style>
