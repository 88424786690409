<template>
	<div>
		<BaseBuilderWrapper :title="$vuetify.lang.t('$vuetify.SITE_BUILDER.SITE_INFO.SITE_NAME_TITLE')" class="mb-3">
			<BaseInput
				:label="$vuetify.lang.t('$vuetify.SITE_BUILDER.SITE_INFO.SITE_NAME_LABEL')"
				:placeholder="$vuetify.lang.t('$vuetify.SITE_BUILDER.SITE_INFO.SITE_NAME_PLACEHOLDER')"
				v-model="data.siteName"
				hide-details
			/>
		</BaseBuilderWrapper>
		<BaseBuilderWrapper
			:title="$vuetify.lang.t('$vuetify.SITE_BUILDER.SITE_INFO.LOGO_TITLE')"
			:subtitle="$vuetify.lang.t('$vuetify.SITE_BUILDER.SITE_INFO.LOGO_DESCRIPTION')"
			class="mb-3"
		>
			<BaseUploadImage
				@onUploadSuccess="data => onUploadSuccess(data, 'logo_url')"
				:imageUrl="data.logo_url"
			/>
		</BaseBuilderWrapper>
		<BaseBuilderWrapper :title="$vuetify.lang.t('$vuetify.SITE_BUILDER.SITE_INFO.MAIN_COLOR_TITLE')" class="mb-3">
			<BaseColorPicker :init-data="data.color_primary" @save="saveColor" />
		</BaseBuilderWrapper>
		<BaseBuilderWrapper
			:title="$vuetify.lang.t('$vuetify.SITE_BUILDER.SITE_INFO.FAVICON_TITLE')"
			:subtitle="$vuetify.lang.t('$vuetify.SITE_BUILDER.SITE_INFO.FAVICON_DESCRIPTION')"
			class="mb-3"
		>
			<BaseUploadImage
				type="image"
				@onUploadSuccess="data => onUploadSuccess(data, 'favicon')"
				:imageUrl="data.favicon"
			/>
		</BaseBuilderWrapper>
		<BaseBuilderWrapper
			:title="$vuetify.lang.t('$vuetify.SITE_BUILDER.SITE_INFO.THUMBNAIL_TITLE')"
			:subtitle="$vuetify.lang.t('$vuetify.SITE_BUILDER.SITE_INFO.THUMBNAIL_DESCRIPTION')"
			:isDividerShown="false"
		>
			<BaseUploadImage
				type="image"
				@onUploadSuccess="data => onUploadSuccess(data, 'thumbnail')"
				:imageUrl="data.thumbnail"
			/>
		</BaseBuilderWrapper>
	</div>
</template>
<script>
import BaseBuilderWrapper from '@/templates/wrapper/BaseBuilderWrapper'
import {mapGetters} from 'vuex'
export default {
	components: {
		BaseBuilderWrapper
	},
	props: {
		viewData: {
			type: Object
		}
	},
	data() {
		return {
			data: {
				color_primary: '',
				favicon: '',
				logo_url: '',
				siteName: '',
				thumbnail: ''
			}
		}
	},
	computed: {
		...mapGetters({
			themeData: 'vendor/getThemedata'
		})
	},
	methods: {
		saveColor(color) {
			this.data.color_primary = color
		},
		onUploadSuccess(res, target) {
			const link = res[0].link
			const fullLink = this.$utils.combineLinkWithBucket(link)
			this.data[`${target}`] = fullLink
			this.$forceUpdate()
		},
		getData() {
			return this.data
		}
	},
	watch: {
		viewData: {
			handler(viewData) {
				if (viewData) {
					this.data = viewData
				} else {
					const currentTheme = JSON.parse(localStorage.getItem('iztech-theme'))
					this.data = currentTheme
				}
			},
			immediate: true
		}
	}
}
</script>
