import Repository from '@/api/core'
import utils from '@/helpers/utils.js'
const baseURL = process.env.VUE_APP_API_PAYMENT + '/active-code'

const fetch = params => {
	return Repository(baseURL).fetch(params)
}

const fetchOne = id => {
	return Repository(baseURL).fetchOne(id)
}

const create = params => {
	return Repository(baseURL).create(params)
}
const createMany = params => {
	return Repository(baseURL).createMany(params)
}

const update = (id, params) => {
	return Repository(baseURL).update(id, params)
}

const count = params => {
	return Repository(baseURL + '/count').fetch({...utils.cleanObject(params)})
}

const remove = id => {
	return Repository(baseURL).delete(id)
}

export default {
	fetch,
	fetchOne,
	createMany,
	create,
	update,
	remove,
	count
}
