function lazyLoad(view) {
  return () => import(`@/pages/${view}.vue`)
}
export default [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: lazyLoad('Dashboard'),
    meta: {
      title: '$vuetify.ROUTE.HIDDENT_ROUTES.TITLE',
    }
  },
  {
    path: "/event/:id",
    name: '$vuetify.ROUTE.HIDDENT_ROUTES.LABEL_EVENT_DETAIL',
    component: lazyLoad('EventDetail'),
  },
  {
    path: "/ebook/:id",
    name: '$vuetify.ROUTE.HIDDENT_ROUTES.LABEL_EBOOK_DETAIL',
    component: lazyLoad('EbookDetail'),
  },
  {
    path: "/topic/edit-topic/:id",
    name: '$vuetify.ROUTE.HIDDENT_ROUTES.LABEL_TOPIC_DEAIL',
    component: lazyLoad('TopicDetail'),
  },
  {
    path: "/topic/edit-book-topic/:id",
    name: '$vuetify.ROUTE.HIDDENT_ROUTES.LABEL_TOPIC_DEAIL',
    component: lazyLoad('TopicDetailEbook'),
  },
  {
    path: "/department/:id",
    name: '$vuetify.ROUTE.HIDDENT_ROUTES.LABEL_DEPARTMENT_DETAIL',
    component: lazyLoad('DepartmentDetail'),
  },
  {
    path: "/zooms",
    name: '$vuetify.ROUTE.HIDDENT_ROUTES.LABEL_MANAGE_ZOOM',
    component: lazyLoad('Zoom'),
  },
  {
    path: "/zooms/create",
    name: '$vuetify.ROUTE.HIDDENT_ROUTES.LABEL_CREATE_ZOOM',
    component: lazyLoad('CreateZoom'),
  }, {
    path: "/affiliate/:id",
    name: '$vuetify.ROUTE.HIDDENT_ROUTES.LABEL_AFFILIATE_DETAIL',
    component: lazyLoad('DetailAffiliate'),
  },
  {
    path: "/teachers/create",
    name: '$vuetify.ROUTE.HIDDENT_ROUTES.LABEL_CREATE_TEACHER',
    component: lazyLoad('CreateTeacher'),
  },
  {
    path: "/students/create",
    name: '$vuetify.ROUTE.HIDDENT_ROUTES.LABEL_CREATE_STUDENT',
    component: lazyLoad('CreateStudent'),
  },
  {
    path: "/detail-teacher/:id",
    name: '$vuetify.ROUTE.HIDDENT_ROUTES.LABEL_TEACHER_DETAIL',
    component: lazyLoad('DetailTeacher'),
  },
  {
    path: "/detail-student/:id",
    name: '$vuetify.ROUTE.HIDDENT_ROUTES.LABEL_STUDENT_DETAIL',
    component: lazyLoad('DetailStudent'),
  },
  {
    path: "/active-code/detail/:id",
    name: '$vuetify.ROUTE.HIDDENT_ROUTES.LABEL_ACTIVE_CODE_DETAIL',
    component: lazyLoad('ActiveCodeDetail'),
  },
  {
    path: "/active-code/add-code",
    name: '$vuetify.ROUTE.HIDDENT_ROUTES.LABEL_ACTIVE_CODE_CREATE',
    component: lazyLoad('ActiveCodeCreate'),
  },
  {
    path: "/product-coupon/add-code",
    name: '$vuetify.ROUTE.HIDDENT_ROUTES.LABEL_ACTIVE_CODE_CREATE',
    component: lazyLoad('CreatePhysicalProductCoupon'),
  },
  {
    path: "/coupon-code/detail/:id",
    name: '$vuetify.ROUTE.HIDDENT_ROUTES.LABEL_COUPON_DETAIL',
    component: lazyLoad('CouponDetail'),
  },
  {
    path: "/product-coupon/detail/:id",
    name: 'Coupon',
    component: lazyLoad('ProductCouponDetail'),
  },
  {
    path: "/coupon-code/add-code",
    name: '$vuetify.ROUTE.HIDDENT_ROUTES.LABEL_CREATE_COUPON',
    component: lazyLoad('CouponCreate'),
  },
  {
    path: "/combo/:id",
    name: '$vuetify.ROUTE.HIDDENT_ROUTES.LABEL_COMBO_DETAIL',
    component: lazyLoad('ComboDetail'),
  },
  {
    path: "/blog/:id",
    name: '$vuetify.ROUTE.HIDDENT_ROUTES.LABEL_BLOG_DETAIL',
    component: lazyLoad('BlogDetail')
  },
  {
    path:"/student-group/:id",
    name: '$vuetify.ROUTE.HIDDENT_ROUTES.LABEL_GROUP_STUDENT_DETAILL',
    component: lazyLoad('GroupDetail')
  },
  {
    path:"/certificate/create",
    name: '$vuetify.ROUTE.HIDDENT_ROUTES.LABEL_CREATE_CETIFICATE',
    component: lazyLoad('AddCertificate')
  },
  {
    path:"/certificate/:id",
    name: '$vuetify.ROUTE.HIDDENT_ROUTES.LABEL_CETIFICATE_DETAIL',
    component: lazyLoad('CertificateDetail')
  },
  {
    path:"/course-progress-detail/:courseId/:id",
    name: '$vuetify.ROUTE.HIDDENT_ROUTES.LABEL_COURSE_PROGRESS_DETAIL',
    component: lazyLoad('CourseProgressDetail')
  },
  {
    path:"/learning-path/:id",
    name: '$vuetify.ROUTE.HIDDENT_ROUTES.LABEL_LEARNING_PATH_DETAIL',
    component: lazyLoad('LearningPathDetail')
  },
  {
    path:"/product/:id",
    name: 'Chi tiết sản phẩm',
    component: lazyLoad('ProductDetail')
  },
]