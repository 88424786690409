import axios from 'axios'
import auth from '@/store/modules/auth'
const BASE_URL = process.env.VUE_APP_API_VENDOR
const NOTIFY_URL = process.env.VUE_APP_API_NOTIFY
import Repository from '@/api/core'

const APIHelper = () => ({
	setupAccountMail: (params, option) =>
		axios.put(
			BASE_URL + `/vendor-credential/${auth.state.auth.vendorId}`,
			{...params},
			{
				headers: {
					Authorization: auth.state.auth.accessToken
				}
			},
			option
		),
	getAccountMail: () =>
		Repository(
			BASE_URL + `/vendor-credential/${auth.state.auth.vendorId}`
		).fetch(),
	createOrUpdateTemplate: params =>
		Repository(NOTIFY_URL + `/template`).create({...params}),
	getTemplates: params =>
		Repository(NOTIFY_URL + `/template`).fetch({...params}),
	sendMail: params => Repository(NOTIFY_URL + `/email/send`).create(params),
	sendMailToGroup: params =>
		Repository(NOTIFY_URL + `/email/group`).create(params),
	getMail: params => Repository(NOTIFY_URL + `/email`).fetch(params)
})
export const Mail = APIHelper()
export default {
	...Mail
}
