import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const SectionRepository = RepositoryFactory.get('section')

const state = {
  sections: {},
  count: 0,
  childSections: {},
  currentSectionId: '',
};

const actions = {
  async createSection({ commit }, data) {
    const res = await SectionRepository.create(data);
    const section = res.data || {}
    if (!section.level) commit("addSection", section);
    else commit("addChildSection", section);
    return section;
  },
  async fetchSections({ commit }, params = {}) {
    const res = await SectionRepository.fetch({
      ...params,
    });
    commit("setSections", res.data || []);
    commit("setChildSections", res.data || []);
    return res.data;
  },
  async countSections({ commit }, params = {}) {
    const res = await SectionRepository.count({
      ...params,
      type: "section",
    });
    commit("setCount", res.data.Count || 0);
  },
  async updateSection({ commit }, { id, isChild = false, ...section }) {
    const res = await SectionRepository.update(id, section);
    if (!isChild) return commit("setSection", res.data);
    else return commit('setChildSection', res.data)
  },
  async updateSectionOrder({ commit }, { sections, isChild = false }) {
    sections.forEach(async (section, idxSection) => {
      section = {
        ...section,
        index: idxSection
      }
      await SectionRepository.update(section.id, { index: idxSection });
    });
    if (!isChild) commit('setSections', sections)
    else commit('setChildSections', sections)
  },
  async removeSection({ commit }, item) {
    await SectionRepository.remove(item.id)
    if (!item.level) return commit("removeSection", item.id);
    else return commit('removeChildSection', item.id)
  },
  async setSection({ commit }, section) {
    return commit("setSectionData", section);
  },
  /** @deprecated */
  async addSection({ commit }, section) {
    return commit("setSection", section);
  },
  async fetchSectionsIndependent(_, params = {}) {
    const res = await SectionRepository.fetch(params)
    return res.data
  }
};

const mutations = {
  setSection(state, section) {
    return (state.sections = {
      ...state.sections,
      [section.id]: section,
    });
  },
  setChildSection(state, section) {
    return (state.childSections = {
      ...state.childSections,
      [section.id]: section,
    });
  },
  setCount(state, count) {
    return (state.count = count);
  },
  addSection(state, section) {
    state.count = state.count + 1;
    state.sections = {
      [section.id]: section,
      ...state.sections,
    };
  },
  addChildSection(state, section) {
    state.count = state.count + 1;
    state.childSections = {
      [section.id]: section,
      ...state.childSections,
    };
  },
  setSectionData(state, section) {
    return (state.section = section);
  },
  appendSections(state, sections) {
    return (state.sections = {
      ...state.sections,
      ...sections.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}),
    });
  },
  setSections(state, sections) {
    return (state.sections = sections.filter(s => !s.level).reduce(
      (acc, cur) => ({ ...acc, [cur.id]: cur }),
      {}
    ));
  },

  removeSection(state, id) {
    delete state.sections[id];
    state.sections = { ...state.sections };
  },
  removeChildSection(state, id) {
    delete state.childSections[id];
    state.childSections = { ...state.childSections };
  },
  setChildSections(state, sections) {
    state.childSections = {
      ...state.childSections,
      ...sections.filter(s => s.level > 0).reduce(
        (acc, cur) => ({ ...acc, [cur.id]: cur }),
        {}
      ),
    }
  },
  setCurrentSectionId(state, currentSectionId) {
    state.currentSectionId = currentSectionId
  },
};

const getters = {
  sections: (state) => {
    return Object.values(state.sections)
      .sort((a, b) => {
        let aIndex = a.index ? a.index : 0;
        let bIndex = b.index ? b.index : 0;
        return aIndex - bIndex;
      })
  },
  count: (state) => {
    return state.count;
  },
  section: (state) => {
    return state.section;
  },
  childSections: (state) => {
    return Object.values(state.childSections)
      .sort((a, b) => {
        let aIndex = a.index ? a.index : 0;
        let bIndex = b.index ? b.index : 0;
        return aIndex - bIndex;
      })
  },
  getCurrentSectionId: (state) => {
    return state.currentSectionId
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
