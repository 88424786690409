<template>
  <div>
    <BaseTitleTable
      isBack
      :backText="$vuetify.lang.t('$vuetify.SITE_BUILDER.DETAIL.GO_BACK_TEXT')"
      @click="backToListSection"
      textClass="subtitle-blur action-hover"
    />
    <div class="text-h6 font-weight-medium mb-3">
      {{ $vuetify.lang.t(label) }}
    </div>
    <component
      ref="section"
      :initData="localOriginData"
      :is="currentComponent"
      :openPopup="openPopup"
      :isMobile="isMobile"
      @update="(data) => handleChangeData(data)"
    >
    </component>
  </div>
</template>
<script>
import helpers from '@/helpers'
import {mapActions, mapGetters} from 'vuex'
export default {
  props: {
    initData: {
      type: Object,
    },
    openPopup: {
      type: Function,
    },
    isMobile: Boolean,
  },

  watch: {
    initData: {
      handler(val) {
        if (val) {
          let [name, id] = val.name.split(':')
          this.name = name
          let listSections = helpers.LIST_SECTION_SITEBUILDER
          if (this.isMobile)
            listSections.filter((section) => section.value !== 'event')
          const section = listSections.find((item) => item.value === this.name)
          this.label = section ? section.title : ''
          this.id = id
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      getMessageError: 'sitebuilder/getMessageError',
    }),
    currentComponent() {
      if (this.name) {
        return () =>
          import(
            `@/modules/${
              this.isMobile ? 'ScreenBuilder' : 'SiteBuilder'
            }/sectionConfigure/${this.name}`
          )
      } else return ''
    },
  },
  data() {
    return {
      localOriginData: {},
      name: '',
      id: '',
      label: '',
    }
  },
  mounted() {
    if (this.initData) {
      this.localOriginData = JSON.parse(JSON.stringify(this.initData))
    } else this.setDefaultOriginData()
  },
  methods: {
	...mapActions({
		removeMessageError: 'sitebuilder/removeMessageError',
	}),
    async setDefaultOriginData() {
      let data = {}
      if (this.name) {
        data = await import(`@/modules/SiteBuilder/defaultData/${this.name}`)
        this.localOriginData = data.default
      }
    },
    backToListSection() {
        this.$confirm.show({
          title: 'Quay về danh sách khối nội dung',
          text: 'Những dữ liệu chưa lưu không thể khôi phục',
          okText: 'Xác nhận',
          cancelText: 'Hủy bỏ',
          done: () => {
			this.removeMessageError()
            this.$emit('backToListSection')
          },
        })
    },
    handleChangeData(data) {
      this.$emit('update', data)
    },
  },
}
</script>
