const LIST_ELEMENT_QUERY_HOMEPAGE = [
	{
		name: 'SectionCourseOnline',
		key: 'course-online'
	},
	{
		name: 'SectionCourseOffline',
		key: 'course-offline'
	},
	{
		name: 'SectionEventList',
		key: 'academic-event'
	},
	{
		name: 'SectionDepartment',
		key: 'academic-department'
	},
	{
		name: 'SectionEbook',
		key: 'book'
	}
]

const MENU_HEADER_HOMEPAGE = [
	{
		label: '$vuetify.MENU_SETTING.OPTIONS.PROGRAM_LABEL',
		children: [
			{
				label: '$vuetify.MENU_SETTING.OPTIONS.ONLINE_COURSE_LABEL',
				href: '/course-online/'
			},
			{
				label: '$vuetify.MENU_SETTING.OPTIONS.OFFLINE_COURSE_LABEL',
				href: '/course-offline/'
			},
			{
				label: '$vuetify.MENU_SETTING.LABEL_EBOOK',
				href: '/ebook/'
			},
			{
				label: '$vuetify.MENU_SETTING.OPTIONS.EVENT_LABEL',
				href: '/event-list/'
			}
		]
	},
	{
		label: '$vuetify.MENU_SETTING.LABEL_ACTIVE_COURSE',
		href: '/active-course/'
	},
	{
		label: '$vuetify.MENU_SETTING.OPTIONS.DEPARTMENT_LABEL',
		href: '/department/'
	}
]

const QUERY_HOMEPAGE = {
	'course-online': {
		ids: [],
		fields: [
			'id',
			'vendorId',
			'createdAt',
			'status',
			'topics',
			'authors',
			'previewImg',
			'title',
			'shortDescription',
			'isFree',
			'price',
			'disablePurchasing',
			'video'
		]
	},
	'course-offline': {
		ids: [],
		fields: [
			'id',
			'vendorId',
			'status',
			'topics',
			'authors',
			'metadata',
			'duration',
			'title',
			'price',
			'description',
			'shortDescription'
		]
	},
	'academic-event': {
		ids: [],
		fields: [
			'id',
			'vendorId',
			'createdAt',
			'previewImage',
			'runningStartDate',
			'title',
			'runningStartHour',
			'center'
		]
	},
	'academic-department': {
		ids: [],
		fields: ['id', 'vendorId', 'createdAt', 'previewImage', 'name']
	},
	book: {
		ids: [],
		fields: ['id', 'vendorId', 'createdAt', 'previewImage', 'authors', 'title']
	}
}

const AWS_S3_ROOT_BUCKET_URL = process.env.VUE_APP_AWS_BUCKET_S3

const commentEnums = {
	countTypeEnums: {
		TOTAL: 'TOTAL',
		COMMENT: 'COMMENT',
		REPLY: 'REPLY'
	}
}

const DEFAULT_IMAGE = {
	avatarProfile:
		'https://online-learning-izteach-3-test-aws-source-bucket.s3-ap-southeast-1.amazonaws.com/resource/images/23b0bfb0-c00b-11e9-a060-17d3861b7a5b/avatar.png',
	card:
		process.env.VUE_APP_AWS_BUCKET_S3_DEFAULT_DATA + '/SD-default-image.png',
	banner:
		process.env.VUE_APP_AWS_BUCKET_S3_DEFAULT_DATA + '/banner-default.jpg',
	avatar:
		process.env.VUE_APP_AWS_BUCKET_S3_DEFAULT_DATA + '/default-avatar.png',
	logo: process.env.VUE_APP_AWS_BUCKET_S3_DEFAULT_DATA + '/root-logo.png',
	certificate:
		'https://test-izteach-resource-bigdat.s3-ap-southeast-1.amazonaws.com/resource/documents/23b0bfb0-c00b-11e9-a060-17d3861b7dev/1605091622608'
}

const LIST_SELECTION_SCREENBUILDER = [
	{
		name: '$vuetify.SITE_BUILDER.SECTION.CARD_GROUP_NAME',
		children: [
			{
				name: 'online-course-with-topic-mobile',
				label: '$vuetify.SITE_BUILDER.LABEL_COURSE_CARD_BY_TOPIC',
				description: '$vuetify.SITE_BUILDER.HINT_COURSE_CARD_BY_TOPIC'
			},
			{
				name: 'online-course',
				label: '$vuetify.SITE_BUILDER.SECTION.CARD_ONLINE_COURSE_TITLE',
				description:
					'$vuetify.SITE_BUILDER.SECTION.CARD_ONLINE_COURSE_DESCRIPTION'
			},
			{
				name: 'my-online-course',
				label: '$vuetify.SITE_BUILDER.SECTION.CARD_MY_ONLINE_COURSE_TITLE',
				description:
					'$vuetify.SITE_BUILDER.SECTION.CARD_MY_ONLINE_COURSE_DESCRIPTION'
			},
		]
	},
	{
		name: '$vuetify.SITE_BUILDER.SECTION.BANNER_GROUP_NAME',
		children: [
			{
				name: 'banner-slider',
				label: '$vuetify.SITE_BUILDER.SECTION.BANNER_SLIDER_TITLE',
				description: '$vuetify.SITE_BUILDER.SECTION.BANNER_SLIDER_DESCRIPTION'
			},
		]
	}
]

const LIST_SELECTION_SITEBUILDER = [
	{
		name: '$vuetify.SITE_BUILDER.SECTION.BANNER_GROUP_NAME',
		children: [
			{
				name: 'banner-slider',
				label: '$vuetify.SITE_BUILDER.SECTION.BANNER_SLIDER_TITLE',
				description: '$vuetify.SITE_BUILDER.SECTION.BANNER_SLIDER_DESCRIPTION'
			},
			{
				name: 'banner-topic',
				label: '$vuetify.SITE_BUILDER.SECTION.BANNER_TOPIC_TITLE',
				description: '$vuetify.SITE_BUILDER.SECTION.BANNER_TOPIC_DESCRIPTION'
			},
			{
				name: 'single-image-slider',
				label: '$vuetify.SITE_BUILDER.SECTION.BANNER_SINGLE_IMAGE_SLIDER_TITLE',
				description:
					'$vuetify.SITE_BUILDER.SECTION.BANNER_SINGLE_IMAGE_SLIDER_DESCRIPTION'
			},
			{
				name: 'banner-text',
				label: '$vuetify.SITE_BUILDER.SECTION.BANNER_TEXT_TITLE',
				description: '$vuetify.SITE_BUILDER.SECTION.BANNER_TEXT_DESCRIPTION'
			},
			{
				name: 'lead-form',
				label: '$vuetify.SITE_BUILDER.SECTION.BANNER_LEAD_FORM_TITLE',
				description:
					'$vuetify.SITE_BUILDER.SECTION.BANNER_LEAD_FORM_DESCRIPTION'
			},
			{
				name: 'banner-download',
				label: '$vuetify.SITE_BUILDER.SECTION.BANNER_POPUP_TITLE',
				description: '$vuetify.SITE_BUILDER.SECTION.BANNER_POPUP_DESCRIPTION'
			}
		]
	},
	{
		name: '$vuetify.SITE_BUILDER.SECTION.CARD_GROUP_NAME',
		children: [
			{
				name: 'offline-course',
				label: '$vuetify.SITE_BUILDER.SECTION.CARD_OFFLINE_COURSE_TITLE',
				description:
					'$vuetify.SITE_BUILDER.SECTION.CARD_OFFLINE_COURSE_DESCRIPTION'
			},
			{
				name: 'online-course',
				label: '$vuetify.SITE_BUILDER.SECTION.CARD_ONLINE_COURSE_TITLE',
				description:
					'$vuetify.SITE_BUILDER.SECTION.CARD_ONLINE_COURSE_DESCRIPTION'
			},
			{
				name: 'my-online-course',
				label: '$vuetify.SITE_BUILDER.SECTION.CARD_MY_ONLINE_COURSE_TITLE',
				description:
					'$vuetify.SITE_BUILDER.SECTION.CARD_MY_ONLINE_COURSE_DESCRIPTION'
			},
			{
				name: 'event',
				label: '$vuetify.SITE_BUILDER.SECTION.CARD_EVENT_TITLE',
				description: '$vuetify.SITE_BUILDER.SECTION.CARD_EVENT_DESCRIPTION'
			},
			{
				name: 'department',
				label: '$vuetify.SITE_BUILDER.SECTION.CARD_DEPARTMENT_TITLE',
				description: '$vuetify.SITE_BUILDER.SECTION.CARD_DEPARTMENT_DESCRIPTION'
			},
			{
				name: 'ebook',
				label: '$vuetify.SITE_BUILDER.SECTION.CARD_EBOOK_TITLE',
				description: '$vuetify.SITE_BUILDER.SECTION.CARD_EBOOK_DESCRIPTION'
			},
			{
				name: 'learning-path',
				label: '$vuetify.SITE_BUILDER.SECTION.CARD_LEARNING_PATH_TITLE',
				description:
					'$vuetify.SITE_BUILDER.SECTION.CARD_LEARNING_PATH_DESCRIPTION'
			}
		]
	},
	{
		name: '$vuetify.SITE_BUILDER.SECTION.MEDIA_GROUP_NAME',
		children: [
			{
				name: 'image-description',
				label: '$vuetify.SITE_BUILDER.SECTION.MEDIA_IMAGE_DESCRIPTION_TITLE',
				description:
					'$vuetify.SITE_BUILDER.SECTION.MEDIA_IMAGE_DESCRIPTION_DESCRIPTION'
			},
			{
				name: 'gallery-picture',
				label: '$vuetify.SITE_BUILDER.SECTION.MEDIA_GALLERY_PICTURE_TITLE',
				description:
					'$vuetify.SITE_BUILDER.SECTION.MEDIA_GALLERY_PICTURE_DESCRIPTION'
			},
			{
				name: 'card-gallery',
				label: 'Khối thẻ',
				description: 'Hiển thị thẻ giới thiệu theo dạng khối có cột và hàng'
			}
		]
	},
	{
		name: '$vuetify.SITE_BUILDER.SECTION.MISC_GROUP_NAME',
		children: [
			{
				name: 'testimonial-slider',
				label: '$vuetify.SITE_BUILDER.SECTION.MISC_TESTIMONIAL_SLIDER_TITLE',
				description:
					'$vuetify.SITE_BUILDER.SECTION.MISC_TESTIMONIAL_SLIDER_DESCRIPTION'
			},
			{
				name: 'text-editor',
				label: '$vuetify.SITE_BUILDER.SECTION.MISC_TEXT_EDITOR_TITLE',
				description:
					'$vuetify.SITE_BUILDER.SECTION.MISC_TEXT_EDITOR_DESCRIPTION'
			},
			{
				name: 'teacher',
				label: '$vuetify.SITE_BUILDER.SECTION.MISC_TEACHER_TITLE',
				description: '$vuetify.SITE_BUILDER.SECTION.MISC_TEACHER_DESCRIPTION'
			},
			{
				name: 'blog-news',
				label: '$vuetify.SITE_BUILDER.SECTION.MISC_BLOG_NEWS_TITLE',
				description: '$vuetify.SITE_BUILDER.SECTION.MISC_BLOG_NEWS_DESCRIPTION'
			},
			{
				name: 'category',
				label: '$vuetify.SITE_BUILDER.SECTION.MISC_CATEGORY_TITLE',
				description: '$vuetify.SITE_BUILDER.SECTION.MISC_CATEGORY_DESCRIPTION'
			}
		]
	}
]

const LIST_SECTION_SCREENBUILDER = [
	{
		value: 'online-course-with-topic-mobile',
		title: '$vuetify.SITE_BUILDER.LABEL_ONLINE_COURSE_CARD_BY_TOPIC'
	},
	{
		value: 'banner-slider',
		title: '$vuetify.SITE_BUILDER.SECTION.BANNER_SLIDER_TITLE'
	},
	{
		value: 'my-online-course',
		title: '$vuetify.SITE_BUILDER.SECTION.CARD_MY_ONLINE_COURSE_TITLE'
	},
	{
		value: 'online-course',
		title: '$vuetify.SITE_BUILDER.SECTION.CARD_ONLINE_COURSE_TITLE'
	},
]

const LIST_SECTION_SITEBUILDER = [
	// BANNER
	{
		value: 'banner-slider',
		title: '$vuetify.SITE_BUILDER.SECTION.BANNER_SLIDER_TITLE'
	},
	{
		value: 'banner-topic',
		title: '$vuetify.SITE_BUILDER.SECTION.BANNER_TOPIC_TITLE'
	},
	{
		value: 'single-image-slider',
		title: '$vuetify.SITE_BUILDER.SECTION.BANNER_SINGLE_IMAGE_SLIDER_TITLE'
	},
	{
		value: 'banner-text',
		title: '$vuetify.SITE_BUILDER.SECTION.BANNER_TEXT_TITLE'
	},
	{
		value: 'lead-form',
		title: '$vuetify.SITE_BUILDER.SECTION.BANNER_LEAD_FORM_TITLE'
	},
	{
		value: 'banner-download',
		title: '$vuetify.SITE_BUILDER.SECTION.BANNER_POPUP_TITLE'
	},
	// CARD
	{
		value: 'offline-course',
		title: '$vuetify.SITE_BUILDER.SECTION.CARD_OFFLINE_COURSE_TITLE'
	},
	{
		value: 'online-course',
		title: '$vuetify.SITE_BUILDER.SECTION.CARD_ONLINE_COURSE_TITLE'
	},
	{
		value: 'my-online-course',
		title: '$vuetify.SITE_BUILDER.SECTION.CARD_MY_ONLINE_COURSE_TITLE'
	},
	{
		value: 'event',
		title: '$vuetify.SITE_BUILDER.SECTION.CARD_EVENT_TITLE'
	},
	{
		value: 'department',
		title: '$vuetify.SITE_BUILDER.SECTION.CARD_DEPARTMENT_TITLE'
	},
	{
		value: 'ebook',
		title: '$vuetify.SITE_BUILDER.SECTION.CARD_EBOOK_TITLE'
	},
	{
		value: 'learning-path',
		title: '$vuetify.SITE_BUILDER.SECTION.CARD_LEARNING_PATH_TITLE'
	},
	// MEDIA
	{
		value: 'image-description',
		title: '$vuetify.SITE_BUILDER.SECTION.MEDIA_IMAGE_DESCRIPTION_TITLE'
	},
	{
		value: 'gallery-picture',
		title: '$vuetify.SITE_BUILDER.SECTION.MEDIA_GALLERY_PICTURE_TITLE'
	},
	{
		value: 'card-gallery',
		title: 'Khối thẻ'
	},
	// MISC
	{
		value: 'testimonial-slider',
		title: '$vuetify.SITE_BUILDER.SECTION.MISC_TESTIMONIAL_SLIDER_TITLE'
	},
	{
		value: 'text-editor',
		title: '$vuetify.SITE_BUILDER.SECTION.MISC_TEXT_EDITOR_TITLE'
	},
	{
		value: 'teacher',
		title: '$vuetify.SITE_BUILDER.SECTION.MISC_TEACHER_TITLE'
	},
	{
		value: 'blog-news',
		title: '$vuetify.SITE_BUILDER.SECTION.MISC_BLOG_NEWS_TITLE'
	},
	{
		value: 'category',
		title: '$vuetify.SITE_BUILDER.SECTION.MISC_CATEGORY_TITLE'
	}
]

const PERMISSION = {
	ADMIN: {
		name: 'admin',
		getByCreatedBy: false,
		allScreens: true,
		screens: [],
		excludedScreens: [
			'$vuetify.ROUTE.TITLE_EXAM_ASSIGNED',
			'$vuetify.ROUTE.TITLE_SALES_TEACHER'
		]
	},
	TEACHER: {
		name: 'teacher',
		getByCreatedBy: true,
		allScreens: false,
		screens: [
			'$vuetify.ROUTE.HIDDEN_WITHOUT_MENU_ROUTES.LABEL_ONLINE_COURSE_DETAIL',
			'$vuetify.ROUTE.TITLE_MARK_MULTIPLE_CHOICE',
			'$vuetify.ROUTE.TITLE_EXERCISE',
			'$vuetify.ROUTE.TITLE_STUDY_REPORT',
			'$vuetify.ROUTE.TITLE_COURSE_CONTENT',
			'$vuetify.ROUTE.NAVIGATION_ROUTES.COURSE_CONTENT.LINK_TO_ONLINE_COURSE_LIST',
			'Detail Test & Quiz',
			'$vuetify.ROUTE.TITLE_EXAM_ASSIGNED',
			'$vuetify.ROUTE.TITLE_SALES_TEACHER',
			'$vuetify.ROUTE.NAVIGATION_ROUTES.COURSE_CONTENT.LINK_TO_QUESTION_BANK_LIST',
		],
		groups: [
			'$vuetify.ROUTE.NAVIGATION_ROUTES.COURSE_CONTENT.TITLE',
			'$vuetify.ROUTE.NAVIGATION_ROUTES.FINANCE_MANAGE.TITLE'
		]
	},
	KOL: {
		name: 'kol',
		screens: ['$vuetify.ROUTE.TITLE_AFFILIATE_DETAIL'],
		groups: ['$vuetify.ROUTE.TITLE_FINANCIAL_MANAGEMENT'],
		getByCreatedBy: true,
		allScreens: false
	}
}

const CHECKBOXS_HEADER = [
	{
		label: '$vuetify.MENU_SETTING.OPTIONS.ONLINE_COURSE_TOPIC_LABEL',
		subText: '$vuetify.MENU_SETTING.OPTIONS.ONLINE_COURSE_TOPIC_DESCRIPTION',
		type: 'topics',
		isDisplayMarketHeader: true
	},
	{
		label: '$vuetify.MENU_SETTING.OPTIONS.PROGRAM_LABEL',
		subText: '$vuetify.MENU_SETTING.OPTIONS.PROGRAM_DESCRIPTION',
		type: 'program'
	},
	{
		label: '$vuetify.MENU_SETTING.OPTIONS.ACTIVE_COURSE_LABEL',
		subText: '$vuetify.MENU_SETTING.OPTIONS.ACTIVE_COURSE_DESCRIPTION',
		type: 'activeCourse',
		isDisplayMarketHeader: true
	},
	{
		label: '$vuetify.MENU_SETTING.OPTIONS.SEARCH_BAR_LABEL',
		subText: '$vuetify.MENU_SETTING.OPTIONS.SEARCH_BAR_DESCRIPTION',
		type: 'searchBar',
		isDisplayMarketHeader: true
	},
	{
		label: '$vuetify.MENU_SETTING.OPTIONS.DEPARTMENT_LABEL',
		subText: '$vuetify.MENU_SETTING.OPTIONS.DEPARTMENT_DESCRIPTION',
		type: 'department'
	},
	{
		label: '$vuetify.MENU_SETTING.OPTIONS.BLOG_LABEL',
		subText: '$vuetify.MENU_SETTING.OPTIONS.BLOG_DESCRIPTION',
		type: 'blog'
	},
	{
		label: '$vuetify.MENU_SETTING.OPTIONS.ABOUT_US_LABEL',
		subText: '$vuetify.MENU_SETTING.OPTIONS.ABOUT_US_DESCRIPTION',
		type: 'aboutUs'
	},
	{
		label: '$vuetify.MENU_SETTING.OPTIONS.POLICY_LABEL',
		subText: '$vuetify.MENU_SETTING.OPTIONS.POLICY_DESCRIPTION',
		type: 'policy'
	},
	{
		label: '$vuetify.MENU_SETTING.OPTIONS.RECRUITMENT_LABEL',
		subText: '$vuetify.MENU_SETTING.OPTIONS.RECRUITMENT_DESCRIPTION',
		type: 'recruitment'
	}
]

const CHECKBOXS_FOOTER = [
	{
		label: '$vuetify.MENU_SETTING.OPTIONS.ONLINE_COURSE_LABEL',
		subText: '$vuetify.MENU_SETTING.OPTIONS.ONLINE_COURSE_DESCRIPTION',
		type: 'courseOnline'
	},
	{
		label: '$vuetify.MENU_SETTING.OPTIONS.OFFLINE_COURSE_LABEL',
		subText: '$vuetify.MENU_SETTING.OPTIONS.OFFLINE_COURSE_DESCRIPTION',
		type: 'courseOffline'
	},
	{
		label: '$vuetify.MENU_SETTING.OPTIONS.EVENT_LABEL',
		subText: '$vuetify.MENU_SETTING.OPTIONS.EVENT_DESCRIPTION',
		type: 'event'
	},
	{
		label: '$vuetify.MENU_SETTING.OPTIONS.EBOOK_LABEL',
		subText: '$vuetify.MENU_SETTING.OPTIONS.EBOOK_DESCRIPTION',
		type: 'ebook'
	},
	{
		label: '$vuetify.MENU_SETTING.OPTIONS.ACTIVE_COURSE_LABEL',
		subText: '$vuetify.MENU_SETTING.OPTIONS.ACTIVE_COURSE_DESCRIPTION',
		type: 'activeCourse'
	},
	{
		label: '$vuetify.MENU_SETTING.OPTIONS.DEPARTMENT_LABEL',
		subText: '$vuetify.MENU_SETTING.OPTIONS.DEPARTMENT_DESCRIPTION',
		type: 'department'
	},
	{
		label: '$vuetify.MENU_SETTING.OPTIONS.BLOG_LABEL',
		subText: '$vuetify.MENU_SETTING.OPTIONS.BLOG_DESCRIPTION',
		type: 'blog'
	},
	{
		label: '$vuetify.MENU_SETTING.OPTIONS.ABOUT_US_LABEL',
		subText: '$vuetify.MENU_SETTING.OPTIONS.ABOUT_US_DESCRIPTION',
		type: 'aboutUs'
	},
	{
		label: '$vuetify.MENU_SETTING.OPTIONS.POLICY_LABEL',
		subText: '$vuetify.MENU_SETTING.OPTIONS.POLICY_DESCRIPTION',
		type: 'policy'
	},
	{
		label: '$vuetify.MENU_SETTING.OPTIONS.PHONE_LABEL',
		subText: '$vuetify.MENU_SETTING.OPTIONS.PHONE_DESCRIPTION',
		type: 'phoneNumber'
	},
	{
		label: '$vuetify.MENU_SETTING.OPTIONS.EMAIL_LABEL',
		subText: '$vuetify.MENU_SETTING.OPTIONS.EMAIL_DESCRIPTION',
		type: 'email'
	},
	{
		label: '$vuetify.MENU_SETTING.OPTIONS.ADDRESS_LABEL',
		subText: '$vuetify.MENU_SETTING.OPTIONS.ADDRESS_DESCRIPTION',
		type: 'address'
	},
	{
		label: '$vuetify.MENU_SETTING.OPTIONS.SOCIAL_NETWORK_LABEL',
		subText: '$vuetify.MENU_SETTING.OPTIONS.SOCIAL_NETWORK_DESCRIPTION',
		type: 'socialNetwork'
	}
]

const DATATYPES_HEADER = [
	'program',
	'activeCourse',
	'searchBar',
	'department',
	'blog',
	'aboutUs',
	'policy',
	'topics'
]
const DATATYPES_FOOTER = [
	'courseOnline',
	'courseOffline',
	'event',
	'ebook',
	'activeCourse',
	'department',
	'blog',
	'aboutUs',
	'policy',
	'phoneNumber',
	'email',
	'address',
	'socialNetwork'
]
const TYPES_OF_TIME = [
	{
		title: '$vuetify.COURSE.COURSE_OVERVIEW.TYPE_OF_TIME.TITLE_MONTH',
		value: 'month'
	},
	{
		title: '$vuetify.COURSE.COURSE_OVERVIEW.TYPE_OF_TIME.TITLE_DATE',
		value: 'date'
	},
	{
		title: '$vuetify.COURSE.COURSE_OVERVIEW.TYPE_OF_TIME.TITLE_WEEK',
		value: 'week'
	},
	{
		title: '$vuetify.COURSE.COURSE_OVERVIEW.TYPE_OF_TIME.TITLE_SLOT',
		value: 'slot'
	}
]

const DAYS_IN_WEEK = [
	{
		title: '$vuetify.COURSE.OFFLINE_COURSE_DETAIL.DAY_IN_WEEK.TITLE_MONDAY',
		value: 'monday'
	},
	{
		title: '$vuetify.COURSE.OFFLINE_COURSE_DETAIL.DAY_IN_WEEK.TITLE_TUESDAY',
		value: 'tuesday'
	},
	{
		title: '$vuetify.COURSE.OFFLINE_COURSE_DETAIL.DAY_IN_WEEK.TITLE_WEDNESDAY',
		value: 'wednesday'
	},
	{
		title: '$vuetify.COURSE.OFFLINE_COURSE_DETAIL.DAY_IN_WEEK.TITLE_THURSDAY',
		value: 'thursday'
	},
	{
		title: '$vuetify.COURSE.OFFLINE_COURSE_DETAIL.DAY_IN_WEEK.TITLE_FRIDAY',
		value: 'friday'
	},
	{
		title: '$vuetify.COURSE.OFFLINE_COURSE_DETAIL.DAY_IN_WEEK.TITLE_SATURDAY',
		value: 'saturday'
	},
	{
		title: '$vuetify.COURSE.OFFLINE_COURSE_DETAIL.DAY_IN_WEEK.TITLE_SUNDAY',
		value: 'sunday'
	}
]

const DEFAULT_LABELS = [
	'A',
	'B',
	'C',
	'D',
	'E',
	'F',
	'G',
	'H',
	'I',
	'J',
	'K',
	'L',
	'M',
	'N',
	'O',
	'P',
	'Q',
	'R',
	'S',
	'T',
	'U',
	'V',
	'W',
	'X',
	'Y',
	'Z'
]

const BILL_STATE = {
	PENDING: 'pending',
	FAILED: 'failed',
	SUCCESS: 'success',
	RETURNED: 'returned',
	REJECTED: 'rejected',
	CONFIRM: 'confirm'
}

const PAYMENT_METHOD = {
	HAND_ON: 'handOn',
	VN_PAY: 'vnpayBank',
	DIRECT_PAY: 'directPay',
	BANK_TRANSFER: 'transfer',
	COD: 'cod',
	ADMIN: 'admin',
	ACTIVE_CODE: 'active-code'
}

const TYPES_OF_QUESTIONS = {
	SINGLE_CHOICE: 'single-choice',
	MULTIPLE_CHOICES: 'multiple-choice',
	FILL_BLANK: 'fill-blank',
	GROUP_QUESTION: 'group',
	SINGLE_CHOICE_CHILD: 'single-choice-child',
	MULTIPLE_CHOICES_CHILD: 'multiple-choice-child',
	FILL_BLANK_CHILD: 'fill-blank-child'
}

const PDF_HTML_TEMPLATE = `<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
  <style>
    body {
      font-family: 'Times New Roman', Times, serif;
      line-height: 1.3;
    }
    .container {
      max-width: 840pt;
      margin: 0 auto;
    }
    .exam-title {
			margin-top: 8pt;
      padding: 8pt 16pt;
      font-size: 16pt;
    }
    .exam-duration {
      padding: 4pt 16pt;
      font-size: 14pt;
      margin-bottom: 16pt;
    }
    .question-container {
      padding: 8pt 16pt;
    }
    .question-overview {
      margin-bottom: 12pt;
    }
    .question-overview__title {
      font-weight: bold;
      font-size: 14pt;
      display: inline-flex;
    }
    .question-overview__description {
      font-size: 14pt;
      display: inline-flex;
    }
    .question-overview__hint {
      font-size: 14pt;
      display: inline-flex;
      font-weight: bold;
    }
    .question-container__answer>div {
      font-size: 13pt;
      margin-bottom: 8pt;
    }
  </style>
</head>
<body>
  <div class="container">
    {{template}}
  </div>
</body>
</html>`

const HOMEWORK_IMAGE_EDITOR_ACTIONS = {
	CURSOR: 'cursor',
	MOVE: 'move',
	DRAW: 'draw',
	ERASE: 'erase',
	TEXT: 'text',
	ROTATE: 'rotate',
	// future method
	RESTORE: 'restore'
}

const VENDOR_SHOW_TEACHER_SALE = ['12bb4084-65ac-45ef-81af-ffd230a4ca64', '23b0bfb0-c00b-11e9-a060-17d3861b7a5b', 'VENDOR-ID-FOR-TEST', '3175a1c0-8a9e-11ea-abfd-09ae7174bd87']
const CLIENT_VENDOR = {
	luongthevinh: '49714800-4b02-11ea-a775-01bd84d6799a'
}

const VENDOR_SHOW_MULTI_LEVEL_SECTION = [
	'23b0bfb0-c00b-11e9-a060-17d3861b7a5b',
	'3175a1c0-8a9e-11ea-abfd-09ae7174bd87',
	'3573c750-6cd7-11ea-9def-511e35850d83',
	'e7bf5907-b192-439e-a092-5cbbc7dc69f6',
	'd85ad6e0-e8b4-11e9-8161-53a8368ab632',
	'270dd745-3ee0-43fc-9884-e53207fa31ce',
]

export default {
	VENDOR_SHOW_TEACHER_SALE,
	LIST_ELEMENT_QUERY_HOMEPAGE,
	QUERY_HOMEPAGE,
	MENU_HEADER_HOMEPAGE,
	DEFAULT_IMAGE,
	AWS_S3_ROOT_BUCKET_URL,
	commentEnums,
	PERMISSION,
	TYPES_OF_TIME,
	LIST_SECTION_SITEBUILDER,
	LIST_SECTION_SCREENBUILDER,
	LIST_SELECTION_SITEBUILDER,
	LIST_SELECTION_SCREENBUILDER,
	CHECKBOXS_FOOTER,
	CHECKBOXS_HEADER,
	DATATYPES_HEADER,
	DATATYPES_FOOTER,
	DAYS_IN_WEEK,
	DEFAULT_LABELS,
	BILL_STATE,
	PAYMENT_METHOD,
	PDF_HTML_TEMPLATE,
	TYPES_OF_QUESTIONS,
	HOMEWORK_IMAGE_EDITOR_ACTIONS,
	CLIENT_VENDOR,
	VENDOR_SHOW_MULTI_LEVEL_SECTION,
}
