<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{on, attrs}">
        <v-btn
          color="primary"
          outlined
          v-bind="attrs"
          v-on="on"
          class="text-none"
        >
            {{ $vuetify.lang.t("$vuetify.SYSTEM_SETTING.BUTTON_ENTER_EXPORT_HTML") }}
            <v-icon right>mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="$emit('importHtml')">
          <v-list-item-title>{{ $vuetify.lang.t("$vuetify.SYSTEM_SETTING.BUTTON_ENTER_FILE_HTML") }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="downloadHtml">
          <v-list-item-title>{{ $vuetify.lang.t("$vuetify.SYSTEM_SETTING.BUTTON_DOWNLOAD_FILE_SAMPLE") }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
const TEMPLATE_MAIL = {
  RegisterEmailTemplate: "<div>Bạn đã đăng ký thành công !!!!</div><div>Tài khoản: {{email}}</div><div>Mật khẩu: {{password}}</div><div>Ngày sinh: {{birthdate}}</div><div>SĐT: {{phone}}</div><div>Bấm vào link để kích hoạt tài khoản:</div><div>{{link}}</div>",
  ForgotPasswordEmailTemplate: "Mã xác nhận: {{code}}<br>",
  ChangePasswordEmailTemplate: "<div>Mật khẩu của bạn đã được đổi:</div><div>Họ và tên: {{username}}</div><div>Tài khoản: {{email}}</div><div>Mật khẩu: {{password}}</div>",
  approvedCourseEmailTemplate: "<div>Bạn đã sở hữu khóa học:</div><div>Tên khách hàng: {{customerName}}</div><div>SĐT khách hàng: {{customerPhone}}</div><div>Tài khoản: {{customerEmail}}</div><div>Tên sản phẩm: {{product}}</div><div>Loại: {{ownershipType}}</div><div>Giá tiền: {{price}}</div><div>Loại tiền: {{currency}}</div>",
}
export default {
  props: {
    template: Object,
  },
  methods: {
    downloadHtml() {
      let file = TEMPLATE_MAIL[this.template.value]
      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/html;charset=utf-8,' + encodeURIComponent(file));
      element.setAttribute('download', `${this.template.title}.html`);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  }
}
</script>