<template>
	<div class="border-radius grey lighten-4">
		<div class="pa-3" @click="changeEditing" :class="isEditing ? 'subtitle-2' : 'subtitle-2'">
			{{ template.title }}
		</div>
		<div class="pa-3" v-if="isEditing">
			<div
				class="pa-3 has-border"
				v-if="isEditing"
				contenteditable
				v-html="data.html"
				style="min-width: 200px; min-height: 30px; background-color: #fafafa"
				@input="changeHtml"
			></div>
			<div :id="'html-mail-' + template.value" v-else v-html="data.html">
				{{ $vuetify.lang.t("$vuetify.SYSTEM_SETTING.EMAIL_CONTENT_SETTING_FORM.TEXT_HTML")}}
			</div>
			<div class="d-flex justify-end mt-2">
				<BaseButton v-if="hasReset" class="mr-2" outlined 
					:text="$vuetify.lang.t('$vuetify.SYSTEM_SETTING.BUTTON_RESET')" 
					@click="resetEmail" />
				<div>
					<ActionFileHtml v-if="hasDownload" @importHtml="importHtml" :template="template"/>
					<BaseButton v-else outlined 
						text="Import file HTML" 
						@click="importHtml" />
				</div>
				<BaseButton
					class="ml-2"
					v-if="!notSave"
					:dark="false"
					:disabled="!isDataChange"
					:text="$vuetify.lang.t('$vuetify.SYSTEM_SETTING.BUTTON_SAVE')"
					@click="onSaveTemplate"
				/>
			</div>
		</div>
		<input
			ref="input-html-file"
			type="file"
			accept=".html"
			style="display: none"
		/>
	</div>
</template>
<script>
import ActionFileHtml from '@/modules/AdvanceSettings/components/ActionFileHtml'
export default {
	props: {
		hasDownload: Boolean,
		template: Object,
		notSave: {
			type: Boolean,
			default: () => false
		},
		hasReset: Boolean,
	},
	components: {
		ActionFileHtml,
	},
	data() {
		return {
			isEditing: false,
			tempHtml: '',
			isDataChange: false,
			data: {
				html: '',
				type: ''
			}
		}
	},
	mounted() {
		let functionUpdateHtml = this.setHtmlData
		this.$refs['input-html-file'].onchange = async e => {
			let file = e.target.files[0]
			if (file) {
				let reader = new FileReader()
				reader.onload = function(e) {
					let contents = e.target.result
					functionUpdateHtml(contents)
				}
				reader.readAsText(file)
			} else {
				alert('Failed to load file')
			}
			this.$refs['input-html-file'].value = ''
		}
	},
	methods: {
		changeEditing() {
			this.isEditing = !this.isEditing
		},
		getHTMLData() {
			return this.tempHtml ? this.tempHtml : this.data.html
		},
		changeHtml(e) {
			this.isDataChange = true
			this.tempHtml = e.srcElement && e.srcElement.innerHTML
		},
		setHtmlData(value) {
			this.isDataChange = true
			this.data.html = value
		},
		importHtml() {
			this.$refs['input-html-file'].click()
		},
		onSaveTemplate() {
			this.isDataChange = false
			this.$emit('onSaveTemplate', {
				name: this.template.value,
				template: this.tempHtml ? this.tempHtml : this.data.html,
				title: this.template.title
			})
			this.isEditing = false
		},
		resetEmail() {
			this.$confirm.show({
				title: this.$vuetify.lang.t("$vuetify.SYSTEM_SETTING.EMAIL_CONTENT_SETTING_FORM.POPUP_TITLE_RESET_EMAIL"),
				text: this.$vuetify.lang.t("$vuetify.SYSTEM_SETTING.EMAIL_CONTENT_SETTING_FORM.POPUP_SUBTITLE_RESET_EMAIL"),
				okText: this.$vuetify.lang.t("$vuetify.SYSTEM_SETTING.BUTTON_CONFIRM"),
				cancelText: this.$vuetify.lang.t("$vuetify.SYSTEM_SETTING.EMAIL_CONTENT_SETTING_FORM.BUTTON_CANCEL"),
				done: async () => {
					this.$emit('onSaveTemplate', {
						name: this.template.value,
						template: '',
						title: this.template.title
					})
					this.data.html = ''
					this.tempHtml = ''
				}
			})
		}
	},
	watch: {
		template: {
			handler(value) {
				if (value.html) this.data.html = value.html
			},
			immediate: true
		}
	}
}
</script>
<style scoped>
.border-radius {
	border-radius: 4px;
}
</style>
