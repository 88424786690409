import dayjs from 'dayjs'
import vuetify from '@/plugins/vuetify'
const PATTERN = {
  DATE: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[0-9]|[0-9]\d)?\d{2})$/,
  PHONE: /^[+]?[(]?[+]?[0-9]{1,3}[-\s.]?[(]?[0-9]{1,4}[)]?[)]?[-\s.]?[0-9]{2,4}[-\s.]?[0-9]{3,5}$/im,
  PRICE: /^[1-9]\d*(,\d{1,3})+?$|^0$/,
  EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  SPECIAL_CHARACTER: /[!@#$%^&*(),.?":{}|<>]/g,
  // eslint-disable-next-line
  URL: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
  DOMAIN: /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/gm,
  TIME: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
  NO_SPECIAL_CHARACTER: /[!@#$%^&*(),.?":{}/.'()|<>[\]]/g,
}

const inputRules = {
  confirmPassword: (currentPw, confirmPw) => {
    if (currentPw !== confirmPw) {
      return 'Mật khẩu không trùng'
    } else return true
  },
  date: val => {
    if (val.length) return PATTERN.DATE.test(val) || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_TIME_NOT_INVALID')
    return true
  },
  required: value => !!value || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_INFO_OBLIGATORY'),
  notSpace: value => value.replace(' ') === value || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_NO_WHITESPACES'),
  notSpecialCharacters: value => !value.match(PATTERN.SPECIAL_CHARACTER) || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_PERCENTAGE'),
  percentage: value => (Number(value) > 0 && Number(value) < 100) || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_PERCENTAGE'),
  atLeastOne: value => value.length > 0 || 'At least one.',
  maxLength: length => (value = '') => {
    if (!value) value = ''
    return value.length <= length || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_MAX_LENGTH', length)
  },
  minLength: length => (value = '') => {
    if (!value) value = ''
    return value.length >= length || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_MIN_LENGTH', length)
  },
  phone: value => {
    if (value.length) return PATTERN.PHONE.test(value) || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_PHONE_NUMBER_FALSE')
    return true
  },
  email: value => {
    return PATTERN.EMAIL.test(value) || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_EMAIL_FORMAT_FALSE')
  },
  url: value => {
    if (!value || value.length === 0) return true
    return PATTERN.URL.test(value) || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_URL_FORMAT_FALSE')
  },
  number: value => {
    return !isNaN(value) || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_NUMBER_FORMAT_FALSE')
  },
  price: value => {
    return PATTERN.PRICE.test(value) || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_PRICE_FORMAT_FALSE')
  },
  validPrice: value => {
    return Number(value.replace(/,/gi, "")) >= 10000 || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_VALID_PRICE')
  },
  greaterEqual: number => value => {
    return Number(value) >= Number(number) || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_NUMBER_GREATER_EQUAL', number)
  },
  greaterThan: number => value => {
    return Number(value) > Number(number) || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_NUMBER_GREATER_THAN', number)
  },
  lessThan: number => value => {
    return Number(value) < Number(number) || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_NUMBER_LESS_THAN', number)
  },
  lessEqual: number => value => {
    return Number(value) <= Number(number) || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_NUMBER_LESS_EQUAL', number)
  },
  clamp: (min, max) => value => {
    min = Number(min)
    max = Number(max)
    value = Number(value)
    return (value >= min && value <= max) || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_NUMBER_CLAMP', min, max)
  },
  dateGreaterThan: (date, format = 'hh:mm DD/MM/YYYY') => value => {
    return new Date(value).getTime() > new Date(date).getTime() || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_DATE_GREATER_THAN', dayjs(date).format(format))
  },
  dateLowerThan: (date, format = 'hh:mm DD/MM/YYYY') => value => {
    return new Date(value).getTime() < new Date(date).getTime() || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_DATE_LESS_THAN', dayjs(date).format(format))
  },
  dateGreaterOrEqual: (date, format = 'DD/MM/YYYY') => value => {
    return (!value || !date || (new Date(value).getTime() >= new Date(date).getTime())) || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_DATE_GREATER_THAN', dayjs(date).format(format))
  },
  dateLowerOrEqual: (date, format = 'DD/MM/YYYY') => value => {
    return (!value || !date || (new Date(value).getTime() <= new Date(date).getTime())) || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_DATE_LESS_THAN', dayjs(date).format(format))
  },
  time: value => PATTERN.TIME.test(value) || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_TIME_NOT_INVALID'),
  rangeTime: endTime => startTime => {
    return startTime.replace(':', '') < endTime.replace(':', '') || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_TIME_LESS_THAN')
  },
  domain: value => PATTERN.DOMAIN.test(value) || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_DOMAIN_FORMAT_FALSE'),
  greaterOrEqualThanStringNumber: number => value => {
    return Number(value) >= Number(number) || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_NUMBER_GREATER_EQUAL', Number(number).toLocaleString())
  },
  lessOrEqualThanStringNumber: number => value => {
    return Number(value) <= Number(number) || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_NUMBER_LESS_EQUAL', Number(number).toLocaleString())
  },
  noSpecialCharacter: value => !value.match(PATTERN.NO_SPECIAL_CHARACTER) || vuetify.framework.lang.translator('Chỉ chưa kí tự chữ và số'),
  requireObject: value => value.id !== '' || vuetify.framework.lang.translator('$vuetify.INPUT.HINT_INFO_OBLIGATORY')
}

export default inputRules
