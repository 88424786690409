<template>
  <div>
    <div :id="`toolbar-container${id}`">
      <span class="ql-formats">
        <select class="ql-font"></select>
        <span class="ql-format-group">
          <select title="Size" class="ql-size">
            <option value="10px">10px</option>
            <option value="12px">12px</option>
            <option value="14px">14px</option>
            <option value="16px">16px</option>
            <option value="18px">18px</option>
            <option value="20px">20px</option>
            <option value="24px">24px</option>
            <option value="32px">32px</option>
            <option value="36px">36px</option>
          </select>
        </span>
      </span>
      <span class="ql-formats">
        <button class="ql-bold"></button>
        <button class="ql-italic"></button>
        <button class="ql-underline"></button>
        <button class="ql-strike"></button>
      </span>
      <span class="ql-formats">
        <select class="ql-color"></select>
        <select class="ql-background"></select>
      </span>
      <span class="ql-formats">
        <button class="ql-script" value="sub"></button>
        <button class="ql-script" value="super"></button>
      </span>
      <span class="ql-formats">
        <button class="ql-header" value="1"></button>
        <button class="ql-header" value="2"></button>
        <button class="ql-blockquote"></button>
        <button class="ql-code-block"></button>
      </span>
      <span class="ql-formats">
        <button class="ql-list" value="ordered"></button>
        <button class="ql-list" value="bullet"></button>
        <button class="ql-indent" value="-1"></button>
        <button class="ql-indent" value="+1"></button>
      </span>
      <span class="ql-formats">
        <button class="ql-direction" value="rtl"></button>
        <select class="ql-align"></select>
      </span>
      <span class="ql-formats">
        <button class="ql-link"></button>
        <button class="ql-image"></button>
        <button class="ql-video"></button>
        <button class="ql-voice">♫</button>
      </span>
      <span class="ql-formats">
        <button :class="`ql-html${id}`" style="width: fit-content">HTML</button>
      </span>
      <span class="ql-formats">
        <button :id="`ql-math${id}`">
          <div style="width: 18px; height: 18px" class="ql-math-icon"></div>
        </button>
        <button :id="`ql-chemistry${id}`">
          <div style="width: 18px; height: 18px" class="ql-chemistry-icon"></div>
        </button>
        <span
          :class="`ql-mathtype${id}`"
          :id="`ql-mathtype${id}`"
          style="display: none"
        ></span>
      </span>
    </div>
    <div
      ref="quill-editor"
      :class="`quill-editor${id}`"
      :id="`quill-editor${id}`"
      :style="`min-height: ${minHeight}px; max-height: ${maxHeight}; overflow-y: auto`"
    ></div>
    <DialogInputUrl ref="dialog-url" @onSelect="(url) => embedLinkVideo(url)" />
    <BaseUploadDragger
      type="image"
      @onUploadSuccess="(item) => renderUploadedImage(item[0])"
    >
      <div ref="uploadImage"></div>
    </BaseUploadDragger>
    <BaseUploadDragger type="audio" @onUploadSuccess="renderUploadedAudio">
      <div ref="uploadAudio"></div>
    </BaseUploadDragger>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Quill from "quill";
import DialogInputUrl from "@/components/editor/DialogInputUrl";
import QuillImageDropAndPaste from "quill-image-drop-and-paste";
import ImageResize from "quill-image-resize-module";
import "@wiris/mathtype-generic/wirisplugin-generic";
var Size = Quill.import("attributors/style/size");
Size.whitelist = ["10px", "12px", "14px", "16px", "18px", "20px", "24px", "32px", "36px"];
Quill.register(Size, true);
Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageDropAndPaste", QuillImageDropAndPaste);

export default {
  components: {
    DialogInputUrl,
  },
  props: {
    theme: {
      type: String,
      default: "snow",
    },
    initData: {
      type: String,
      default: null,
    },
    minHeight: {
      type: [Number, String],
      default: 300,
    },
    maxHeight: {
      type: [Number, String],
      default: "60vh",
    },
  },
  data() {
    return {
      isLocal: location.hostname === "localhost",
      editor: null,
      id: Date.now(),
    };
  },
  async mounted() {
    this.initQuill();
    this.editor.on("text-change", () => {
      const content = this.removeResizeTags(this.getContent());
      this.$emit("text-change", content);
    });
    if (this.initData) {
      this.insertContent(this.initData);
    }
  },
  methods: {
    ...mapActions({
      createDocument: "document/createDocument",
    }),
    renderUploadedImage(resource) {
      const link = this.$utils.convertURL(
        this.$utils.combineLinkWithBucket(resource.link)
      );
      if (link) {
        var range = this.editor.getSelection();
        this.editor.insertEmbed(range.index, "image", link, Quill.sources.USER);
      } else {
        this.$message.error("Server internal error...");
      }
    },
    renderUploadedAudio(resource) {
      const link = this.$utils.convertURL(
        this.$utils.combineLinkWithBucket(resource[0].link)
      );
      if (link) {
        var range = this.editor.getSelection();
        this.editor.insertEmbed(range.index, "audio", { url: link }, Quill.sources.USER);
        this.editor.setSelection(length + 1);
      } else {
        this.$message.error("Server internal error...");
      }
    },
    pauseAudio() {
      const listAudio = document.getElementsByTagName("audio");
      if (listAudio && listAudio.length) {
        Array.from(listAudio).forEach((audio) => {
          audio.pause();
        });
      }
    },
    validateLink(url) {
      if (!url.includes("embed")) {
        const splitString = url.split("=");
        const youtubeId = splitString[splitString.length - 1];
        return `https://www.youtube.com/embed/${youtubeId}?showinfo=0`;
      } else return url;
    },
    embedLinkVideo(url) {
      const embedUrl = this.validateLink(url);
      var range = this.editor.getSelection();
      const currentIndex = range && range.index ? range.index : 0;
      this.editor.insertEmbed(
        currentIndex,
        "iframe",
        { url: embedUrl },
        Quill.sources.USER
      );
      this.editor.setSelection(length + 1);
    },

    triggerIframeBlot() {
      let BlockEmbed = Quill.import("blots/block/embed");
      class IframeBlot extends BlockEmbed {
        static create(value) {
          const node = super.create();
          node.setAttribute("src", value.url);
          node.setAttribute("frameborder", "0");
          node.setAttribute("allowfullscreen", true);
          node.setAttribute("scrolling", "0");
          node.setAttribute("width", "100%");
          node.setAttribute("height", "500px");
          return node;
        }
        static value(node) {
          return {
            url: node.getAttribute("src"),
          };
        }
      }
      IframeBlot.blotName = "iframe";
      IframeBlot.tagName = "iframe";
      Quill.register(IframeBlot);
    },
    triggerAudioBlot() {
      let BlockEmbed = Quill.import("blots/block/embed");
      class AudioBlot extends BlockEmbed {
        static create(value) {
          let node = super.create();
          node.setAttribute("src", value.url);
          node.setAttribute("controls", true);
          return node;
        }
        static value(node) {
          return {
            url: node.getAttribute("src"),
          };
        }
      }
      AudioBlot.blotName = "audio";
      AudioBlot.tagName = "audio";
      Quill.register(AudioBlot);
    },
    initQuill() {
      this.triggerIframeBlot();
      this.triggerAudioBlot();
      const imageHandler = async () => {
        if (this.$refs["uploadImage"]) this.$refs["uploadImage"].click();
      };

      const dropAndPasteHandler = async (imageDataUrl, type, imageData) => {
        const miniImageData = await imageData.minify({
          maxWidth: 320,
          maxHeight: 320,
          quality: 0.5,
        });
        const file = miniImageData.toFile();
        const resource = await this.createDocument({
          type: "image",
          file,
          onProgress: () => {
            this.$message.loading(`Đang tải ảnh lên ...`, 20000);
          },
        });
        this.renderUploadedImage(resource);
        this.$message.success(`Tải lên thành công`);
      };

      const audioHandler = async () => {
        if (this.$refs["uploadAudio"]) this.$refs["uploadAudio"].click();
      };

      const youtubeHandler = async () => {
        this.$refs["dialog-url"].openDialog();
      };

      Quill.prototype.getHtml = function () {
        return this.container.querySelector(`.ql-editor`).innerHTML;
      };

      const options = {
        theme: this.theme,
        modules: {
          imageResize: {},
          imageDropAndPaste: {
            handler: dropAndPasteHandler,
          },
          toolbar: {
            container: `#toolbar-container${this.id}`,
            handlers: {
              image: imageHandler,
              voice: audioHandler,
              video: youtubeHandler,
            },
          },
        },
      };
      this.editor = new Quill(this.$refs["quill-editor"], options);
      this.initHtmlButton();
      this.initMathtype();
      this.editor.container.onclick = () => {
        this.editor.container.focus();
      };
    },
    initMathtype() {
      this.initWiris();
      const mathIcon = document.querySelector(`#ql-math${this.id}`);
      const chemistryIcon = document.querySelector(`#ql-chemistry${this.id}`);
      const trueMathIcon = document.querySelector(
        `.ql-mathtype${this.id} > img#editorIcon`
      );
      const trueChemistryIcon = document.querySelector(
        `.ql-mathtype${this.id} > img#chemistryIcon`
      );
      mathIcon.addEventListener("click", function () {
        if (trueMathIcon) trueMathIcon.click();
      });
      chemistryIcon.addEventListener("click", function () {
        if (trueChemistryIcon) trueChemistryIcon.click();
      });
    },
    initHtmlButton() {
      var txtArea = document.createElement("textarea");
      txtArea.style.cssText =
        "width: 100%;margin: 0px;background: rgb(29, 29, 29);box-sizing: border-box;color: rgb(204, 204, 204);font-size: 15px;outline: none;padding: 20px;line-height: 24px;font-family: Consolas, Menlo, Monaco, &quot;Courier New&quot;, monospace;position: absolute;top: 0;bottom: 0;border: none;display:none";
      var htmlEditor = this.editor.addContainer("ql-custom");
      htmlEditor.appendChild(txtArea);
      var myEditor = document.querySelector(`#quill-editor${this.id}`);
      // eslint-disable-next-line
      this.editor.on("text-change", (delta, oldDelta, source) => {
        var html = myEditor.children[0].innerHTML;
        txtArea.value = html;
      });
      var customButton = document.querySelector(`.ql-html${this.id}`);
      const self = this;
      customButton.addEventListener("click", function () {
        let needToSave = false;
        if (txtArea.style.display === "") {
          needToSave = true;
        }
        txtArea.style.display = txtArea.style.display === "none" ? "" : "none";
        if (needToSave) {
          var html = txtArea.value;
          self.insertContent(html);
        }
      });
    },
    getContent() {
      const firstChild = this.editor.container.firstChild;
      const divChild = firstChild.cloneNode(true);
      divChild.removeAttribute("contenteditable");
      divChild.style.padding = "0";
      const div = document.createElement("div");
      div.appendChild(divChild);
      div.style.minHeight = "inherit";
      div.style.maxHeight = "inherit";
      div.style.border = "0";
      const result = div.outerHTML;
      divChild.remove();
      div.remove();
      return result;
    },
    getHtml() {
      return this.editor.container.firstChild.innerHTML;
    },
    insertContent(content) {
      this.editor.clipboard.dangerouslyPasteHTML(content);
    },
    reset(content) {
      if (content) {
        this.insertContent(content);
      }
    },
    initWiris() {
      var genericIntegrationProperties = {};
      // genericIntegrationProperties.target = document.getElementsByClassName(`ql-editor`)[0];
      genericIntegrationProperties.target = this.editor.container.firstChild;
      genericIntegrationProperties.toolbar = document.getElementById(
        `ql-mathtype${this.id}`
      );
      var genericIntegrationInstance = new window.WirisPlugin.GenericIntegration(
        genericIntegrationProperties
      );
      genericIntegrationInstance.init();
      genericIntegrationInstance.listeners.fire("onTargetReady", {});
    },
    removeResizeTags(htmlStr) {
      if (typeof htmlStr === "string" && htmlStr.length) {
        const resizeTagIndex = htmlStr.indexOf(
          `<div style="position: absolute; box-sizing: border-box;`
        );
        if (resizeTagIndex > -1) return htmlStr.substring(0, resizeTagIndex);
      }
      return htmlStr;
    },
  },
};
</script>
<style lang="scss" scoped>
:root {
  --admin-static: window.lo;
}
::v-deep .quill-editor {
  height: 1px;
  .ql-editor {
    height: 100%;
    > p {
      height: 100%;
    }
    img {
      vertical-align: inherit !important;
    }
  }
}
.ql-container,
.ql-editor {
  height: auto; /* auto-growth */
}

*[quill__html] {
  display: none;

  width: 100%;
  margin: 0;
  background: rgb(29, 29, 29);
  box-sizing: border-box;
  color: rgb(204, 204, 204);
  outline: none;
  padding: 12px 15px;
  line-height: 24px;
  font-family: Consolas, Menlo, Monaco, "Courier New", monospace;
  position: absolute;
  top: 0;
  bottom: 0;
  border: none;
}

*[quill__html*="-active-"] {
  display: initial;
}

.ql-math-icon {
  background-image: url("/admin-static/math-icon.svg");
}
.ql-chemistry-icon {
  background-image: url("/admin-static/chemistry-icon.svg");
}
</style>
