import Repository from '@/api/core'
import axios from 'axios'
import vendor from '@/store/modules/vendor'
import auth from '@/store/modules/auth'
const baseURL = process.env.VUE_APP_API_GROUP + '/user-permission'

const fetch = params => {
	return Repository(baseURL).fetch(params)
}

const fetchOne = id => {
	return Repository(baseURL).fetchOne(id)
}

const count = params => {
	return Repository(baseURL + '/count').fetch(params)
}

const grantRead = (params, option) => {
	return axios.put(
		baseURL +
		`/grant-read?vendorId=${vendor.state.vendor.vendorId}&userId=${params.userId
		}&relationId=${params.relationId}&relationType=${params.relationType
		}&role=${params.role ? params.role : 'student'}`,
		{},
		{
			headers: {
				Authorization: auth.state.auth.accessToken
			}
		},
		option
	)
}
const setLimitedTime = (id, params, option) => {
	return axios.put(
		baseURL +
		`/set-limited-time/${id}`,
		params,
		{
			headers: {
				Authorization: auth.state.auth.accessToken
			}
		},
		option
	)
}
const check = params => {
	return Repository(baseURL + `/${params.userId}`).fetch(params.payload)
}
const revokeRead = (params, option) => {
	return axios.put(
		baseURL +
		`/revoke-read?vendorId=${vendor.state.vendor.vendorId}&userId=${params.userId}&relationId=${params.relationId}`,
		{},
		{
			headers: {
				Authorization: auth.state.auth.accessToken
			}
		},
		option
	)
}

export default {
	fetch,
	fetchOne,
	grantRead,
	revokeRead,
	check,
	count,
	setLimitedTime
}
