import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const StudentProfile = RepositoryFactory.get('student')
const namespaced = true

const state = {
	profile: {},
	students: [],
	totalStudents: 0,
	totalStatistic: {},
	newStudents: [],
	total: 0,
	refresh: null
}

const actions = {
	async updateProfile({ commit }, { id, ...params }) {
		const res = await StudentProfile.update(id, params)
		commit('setProfile', res.data || {})
		return res.data
	},
	async deleteTokenIp({ state, dispatch }, { id, ...params }) {
		const res = await StudentProfile.removeIP(id, params)
		dispatch('fetchStudent', state.profile.id)
		return res.data
	},
	async create({ commit }, params) {
		const res = await StudentProfile.create({
			...params,
			createdFrom: 'admin'
		})
		commit('setProfile', res.data || {})
		return res.data
	},
	async changePassword(_, params) {
		const res = await StudentProfile.changePassword(params)
		return res.data
	},
	async fetchStudent({ commit }, id) {
		const res = await StudentProfile.fetchOne(id)
		commit('setProfile', res.data)
	},
	async fetchAllStudents({ commit }, params) {
		const res = await StudentProfile.fetch(params)
		commit('setStudents', res.data)
		return res.data
	},
	async countStudents({ commit }, params) {
		const res = await StudentProfile.count(params)
		commit('setCount', res.data)
		return res.data
	},

	async deleteStudent({ commit }, id) {
		await StudentProfile.remove(id)
		commit('removeStudent', id)
	},
	async fetchCountDetailStatistic({ commit }, params = {}) {
		const res = await StudentProfile.countDetail({
			...params,
		});
		commit('setTotalStatistic', res.data || {})
		commit('setTotal', res.data.total || 0)
		commit('setNewStudents', res.data.newStudents || [])

		return res.data;
	},
}
const mutations = {
	setProfile(state, profile) {
		return (state.profile = profile)
	},
	setStudents(state, students) {
		state.students = students
	},
	setRefresh(state, refresh) {
		state.refresh = refresh
	},
	removeStudent(state, id) {
		state.students = state.students.filter(student => student.id !== id)
	},
	setCount(state, count) {
		state.totalStudents = count
	},
	//count-detail
	setTotalStatistic(state, totalStatistic) {
		state.totalStatistic = totalStatistic
	},
	setNewStudents(state, newStudents) {
		state.newStudents = newStudents.sort((a, b) => b.createdAt - a.createdAt)
	},
	setTotal(state, total) {
		state.total = total
	}
}
const getters = {
	getProfile(state, profile) {
		return state, profile
	},
	getStudent: state => {
		return state.profile
	},
	getStudents: state => {
		return state.students
	},
	getTotalStudents: state => {
		return state.totalStudents
	},
	//count-detail
	getTotalStatistic: state => {
		return state.totalStatistic
	},
	getNewStudents: state => {
		return state.newStudents
	},
	getTotal: state => {
		return state.total
	},
	getRefresh: state => {
		return state.refresh
	}
}
export default {
	namespaced,
	state,
	actions,
	mutations,
	getters
}
