import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const Resource = RepositoryFactory.get('resource')
import s3Uploader from '@/plugins/s3Uploader.js'
import vendor from '@/store/modules/vendor'
import auth from '@/store/modules/auth'
import mimeTypes from 'mime-types'
import utils from '@/helpers/utils'
const namespaced = true
const UPLOAD_STATUS_OK = 200

const state = {
  documents: {},
  document: {},
  count: 0
}

const actions = {
  async createDocument({commit}, {type, file, onProgress}) {
    let splitNames = file.name.split('.')
    let typeOfFile = splitNames[splitNames.length - 1]
    const parsedFileName = `${utils.clearUnicode(file.name)}.${typeOfFile}`
    const link = `resource/documents/${
      vendor.state.vendor.vendorId
    }/${new Date().getTime()}-${parsedFileName}`
    const res = await s3Uploader.uploadFile(file, link, ({percent}) => {
      onProgress({percent})
    })
    if (res.status === UPLOAD_STATUS_OK) {
      const document = await Resource.create(
        generateDocument(file, {
          link,
          subType: type,
          type: 'document',
          provider: 'S3',
          bucket: process.env.VUE_APP_AWS_BUCKET_S3
        })
      )
      commit('addDocument', document.data)
      return document.data
    } else {
      throw new Error('Upload Failed')
    }
  },
  async createSyllabus({commit}, {file, onProgress, subType}) {
    const link = `resource/syllabus/${
      vendor.state.vendor.vendorId
    }/${new Date().getTime()}`
    const res = await s3Uploader.uploadFile(file, link, ({percent}) => {
      onProgress({percent})
    })
    if (res.status === UPLOAD_STATUS_OK) {
      const document = await Resource.create(
        generateDocument(file, {
          link,
          subType: subType || 'other',
          type: 'syllabus',
          provider: 'S3',
          bucket: process.env.VUE_APP_AWS_BUCKET_S3
        })
      )
      commit('addDocument', document.data)
      return document.data
    } else {
      throw new Error('Upload Failed')
    }
  },
  async createCorrectionHomeworkResource(
    _,
    {base64StrForImage, homeworkResourceId, onProgress}
  ) {
    const buffer = Buffer.from(
      base64StrForImage.replace(/^data:image\/\w+;base64,/, ''),
      'base64'
    )
    const link = `resource/correction-homework/${vendor.state.vendor.vendorId}/correction-${homeworkResourceId}-jpeg`
    const res = await s3Uploader.uploadImageInBase64Str(
      buffer,
      link,
      ({percent}) => onProgress({percent})
    )
    if (res.status === UPLOAD_STATUS_OK) {
      const resource = await Resource.create(
        generateDocument(
          {
            name: `correction_${homeworkResourceId}.jpeg`,
            size: buffer.byteLength
          },
          {
            link,
            subType: 'homework',
            type: 'correction',
            provider: 'S3',
            bucket: process.env.VUE_APP_AWS_BUCKET_S3
          }
        )
      )
      return {
        id: homeworkResourceId,
        data: resource.data
      }
    } else {
      throw new Error('Upload Failed')
    }
  },
  async fetchDocuments({commit}, params = {}) {
    const res = await Resource.fetch({
      ...params
    })
    commit('setDocuments', res.data || [])
    return res.data
  },
  async countDocuments({commit}, params = {}) {
    const res = await Resource.count({
      ...params
    })
    commit('setCount', res.data || 0)
  },
  async updateDocument({commit}, {id, ...document}) {
    const res = await Resource.update(id, document)
    return commit('setDocument', res.data)
  },
  async removeDocument({commit}, item) {
    await Resource.remove(item.id, {
      vendorId: item.vendorId
    })

    return commit('removeDocument', item.id)
  },
  async setDocument({commit}, document) {
    return commit('setDocumentData', document)
  },
  async addDocument({commit}, document) {
    return commit('setDocument', document)
  },
  async saveLoadTimes(_, params) {
    const res = await Resource.saveLoadTimes(params)
    return res.data
  }
}

const mutations = {
  setDocument(state, document) {
    return (state.documents = {
      ...state.documents,
      [document.id]: document
    })
  },
  setCount(state, count) {
    return (state.count = count)
  },
  addDocument(state, document) {
    state.count = state.count + 1
    return (state.documents = {
      [document.id]: document,
      ...state.documents
    })
  },
  setDocumentData(state, document) {
    return (state.document = document)
  },
  appendDocuments(state, documents) {
    return (state.documents = {
      ...state.documents,
      ...documents.reduce((acc, cur) => ({...acc, [cur.id]: cur}), {})
    })
  },
  setDocuments(state, documents) {
    return (state.documents = documents.reduce(
      (acc, cur) => ({...acc, [cur.id]: cur}),
      {}
    ))
  },
  removeDocument(state, id) {
    delete state.documents[id]
    state.documents = {...state.documents}
  }
}

const getters = {
  documents: state => {
    return Object.values(state.documents)
  },
  count: state => {
    return state.count
  },
  document: state => {
    return state.document
  }
}

const generateDocument = (
  file,
  {link, subType, provider, html, bucket, type}
) => {
  return {
    title: file.name,
    size: file.size,
    createdBy: auth.state.auth.id,
    dataType: 'resource',
    link,
    html,
    provider,
    type,
    origin: {
      link,
      size: file.size,
      type,
      html,
      mimeType: mimeTypes.lookup(file.name),
      bucket
    },
    subType
  }
}

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters
}
