import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const Group = RepositoryFactory.get('group')
const namespaced = true

const state = {
	groups: [],
	groupsByUser: [],
	groupsByCourse: [],
	group: {},
	count: 0
}

const actions = {
	async fetchGroups({ commit }, params = {}) {
		const res = await Group.fetch({
			...params
		})
		commit('setGroups', res.data || [])
		return res.data
	},
	async countGroup({ commit }, params) {
		const res = await Group.count(params)
		commit('setCount', res.data)
		return res.data
	},
	async fetchGroupsByUserId({ commit }, params = {}) {
		if (params.users) {
			const res = await Group.fetch({
				...params
			})
			commit('setGroupsForUser', res.data || [])
			return res.data
		}
	},
	async fetchGroupsByCourseId({ commit }, params = {}) {
		const res = await Group.fetch({
			...params
		})
		commit('setGroupsForCourse', res.data || [])
		return res.data
	},
	async fetchGroupById({ commit }, id) {
		const res = await Group.fetchOne(id)
		commit('setOneGroup', res.data)
		return res.data
	},
	async createGroup({ commit }, data) {
		const res = await Group.create(data)
		commit('addGroup', res.data)
		return res.data
	},
	async deleteGroup({ commit }, id) {
		await Group.remove(id)
		return commit('removeGroup', id)
	},
	async addStudent({ dispatch }, { groupId, userData, dontFetchGroup }) {
		await Group.addUser(groupId, userData)
		if (!dontFetchGroup) dispatch('fetchGroupById', groupId)
	},
	async addManyStudent(_, { id, params }) {
		await Group.addManyStudent(id, params)
	},
	async onRemoveStudent({ dispatch }, { groupId, userData }) {
		await Group.removeUser(groupId, userData)
		dispatch('fetchGroupById', groupId)
	},
	async addCourse(_, { groupId, courseData }) {
		await Group.addCourse(groupId, courseData)
	},
	async addCourseToGroup(_, { groupId, courseData }) {
		await Group.addCourseToGroup(groupId, courseData)
	},
	async addCourseToUserInGroup(_, { groupId, ...params }) {
		await Group.addCourseToUserInGroup(groupId, params)
	},
	async onRemoveCourse({ dispatch }, { groupId, courseData }) {
		await Group.removeCourse(groupId, courseData)
		dispatch('fetchGroupById', groupId)
	},
	async addBook(_, { groupId, bookData }) {
		await Group.addBook(groupId, bookData)
	},
	async onRemoveBook({ dispatch }, { groupId, bookData }) {
		await Group.removeBook(groupId, bookData)
		dispatch('fetchGroupById', groupId)
	},
	async updateGroup({ commit, state }, { id, group }) {
		const res = await Group.update(id, group)
		commit('setOneGroup', { ...res.data, courses: state.group.courses, students: state.group.students })
		return res.data
	}
}

const mutations = {
	setGroups(state, groups) {
		return (state.groups = groups)
	},
	setGroupsForUser(state, groups) {
		return (state.groupsByUser = groups)
	},
	setCount(state, count) {
		return (state.count = count)
	},
	setGroupsForCourse(state, groups) {
		return (state.groupsByCourse = groups)
	},
	addGroup(state, group) {
		state.groups.unshift(group)
		return state.groups
	},
	setGroup(state, newGroup) {
		state.groups = state.groups.map(group => {
			if (group._id === newGroup._id) {
				return newGroup
			} else return group
		})
		return state.groups
	},
	removeGroup(state, id) {
		state.groups = state.groups.filter(group => group.id !== id)
	},
	setOneGroup(state, group) {
		state.group = group
	}
}

const getters = {
	getGroups: state => {
		return state.groups.sort((a, b) => {
			return b.updatedAt - a.updatedAt
		})
	},
	getGroupsByUser: state => {
		return state.groupsByUser.reverse()
	},
	getGroupsByCourse: state => {
		return state.groupsByCourse.reverse()
	},
	getGroup: state => {
		return state.group
	},
	getCountGroup: state => {
		return state.count
	}
}

export default {
	namespaced,
	state,
	actions,
	mutations,
	getters
}
