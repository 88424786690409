<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{on, attrs}">
        <div v-if="hasCustomActivator" v-bind="attrs" v-on="on">
          <v-tooltip top close-delay="1000">
            <template v-slot:activator="{on, attrs}">
              <v-btn
                color="primary"
                outlined
                v-bind="attrs"
                v-on="on"
                class="text-none"
              >
                {{$vuetify.lang.t("$vuetify.MANAGE_FINANCE.ACTIVATION_CODE_CAMPAIGN_LIST.BUTTON_IMPORT_EXPORT_DATA_BY_EXCEL")}}
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <slot name="tooltip" />
          </v-tooltip>
        </div>
        <v-btn
          v-else
          color="primary"
          outlined
          v-bind="attrs"
          v-on="on"
          class="text-none"
        >
          {{$vuetify.lang.t("$vuetify.MANAGE_FINANCE.ACTIVATION_CODE_CAMPAIGN_LIST.BUTTON_IMPORT_EXPORT_DATA_BY_EXCEL")}}
          <v-icon right>mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in listActionExcel"
          :key="index"
          @click="selectActionExcel(item.value)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <input
      ref="input-excel-file"
      type="file"
      accept=".xlsx, .xls"
      class="d-none"
    />
    <BaseProgressBar ref="progress-bar" :entity="progressBarEntity" />
  </div>
</template>
<script>
import get from 'lodash-es/get'
export default {
  props: {
    listSelectionExcel: {
      type: Array,
      default: () => [],
    },
    excelTemplate: Object,
    excelFileName: String,
    exportObjects: Array,
    hasCustomActivator: Boolean,
    richCellRangeStart: {
      type: Number,
      default: 0,
    },
    richCellRangeEnd: {
      type: Number,
      default: 0,
    },
    hasCustomSelectActions: Boolean,
    progressBarEntity: String,
  },
  computed: {
    listActionExcel() {
      if (Array.isArray(this.listSelectionExcel) && this.listSelectionExcel.length > 0) {
        return this.listSelectionExcel
      }
      return [
        {title: this.$vuetify.lang.t('$vuetify.MANAGE_FINANCE.ACTIVATION_CODE_CAMPAIGN_LIST.BUTTON_IMPORT_DATA_BY_EXCEL_FILE'), value: 'importExcel'},
        {title: this.$vuetify.lang.t('$vuetify.MANAGE_FINANCE.ACTIVATION_CODE_CAMPAIGN_LIST.BUTTON_DOWNLOAD_EXCEL_TEMPLATE'), value: 'downloadTemplate'},
        {title: this.$vuetify.lang.t('$vuetify.MANAGE_FINANCE.ACTIVATION_CODE_CAMPAIGN_LIST.BUTTON_EXPORT_DATA_TO_EXCEL_FILE'), value: 'exportExcel'},
      ]
    }
  },
  data() {
    return {
      failedItems: [],
    }
  },
  mounted() {
    this.$refs['input-excel-file'].onchange = async (e) => {
      let isUploadFileSuccessful = true
      const file = get(e, 'target.files.0')
      if (!file) return
      let dataExcel = await this.$utils
        .importFileExcelAndGetData(
          file,
          Object.keys(this.excelTemplate).length - 1,
          this.richCellRangeStart,
          this.richCellRangeEnd
        )
        .catch((e) => {
          isUploadFileSuccessful = false
          if (e.type === 'wrong_template') this.$message.error(e.message)
          else this.$message.error(this.$vuetify.lang.t('$vuetify.MANAGE_FINANCE.ACTIVATION_CODE_CAMPAIGN_LIST.MSG_IMPORT_FAIL_READING_FILE_FAIL'))
        })
      this.$refs['input-excel-file'].value = ''
      if (isUploadFileSuccessful) {
        if (dataExcel && dataExcel.length) this.createObjects(dataExcel)
        else this.$message.error(this.$vuetify.lang.t('$vuetify.MANAGE_FINANCE.ACTIVATION_CODE_CAMPAIGN_LIST.MSG_IMPORT_FAIL_EMPTY_FILE'))
      }
    }
  },
  methods: {
    selectActionExcel(value) {
      if (!this.hasCustomSelectActions) {
        switch(value) {
          case 'importExcel':
            return this.importExcel()
          case 'downloadTemplate':
            return this.downloadTemplate()
          case 'exportExcel':
            return this.exportExcel()
          default:
            return
        }
      }
      this.$emit('selectActionExcel', value)
    },
    importExcel() {
      this.$refs['input-excel-file'].click()
    },
    downloadTemplate() {
      const template = {}
      Object.values(this.excelTemplate).forEach(key => template[key] = '')
      this.$utils.exportFileExcel(
        [template],
        `template-${this.excelFileName}`
      )
    },
    async exportExcel() {
      await this.$emit('fetchDataToExport')
      this.$utils.exportFileExcel(
        this.exportObjects,
        `export-${this.excelFileName}`
      )
    },
    async createObjects(listObject) {
      const progressBar = this.$refs['progress-bar']
      for await (const {rowIndex, ...object} of listObject) {
        if (!object) return
        progressBar.openLoading()
        progressBar.changePercentage('update', rowIndex + 1, listObject.length)
        await this.$emit('create', object, rowIndex + 2)
        await this.$utils.delay(500)
      }
      if (this.failedItems.length) {
        this.$message.error(this.$vuetify.lang.t('$vuetify.MANAGE_FINANCE.ACTIVATION_CODE_CAMPAIGN_LIST.MSG_IMPORT_FAIL_INCORRECT_ROWS', this.failedItems.join(', ')), 15000)
        this.failedItems = []
      }
      await setTimeout(() => this.$emit('postCreate'), 7000)
      progressBar.closeLoading()
    },
    updateFailedItems(rowIndex) {
      this.failedItems.push(rowIndex)
    },
  },
}
</script>