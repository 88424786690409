import Question from '@/api/repository/questionRepository.js'
import vuetify from '@/plugins/vuetify'

const caculateScoreAndTimeMatrix = (matrix) => {
  if (!matrix) return
  let totalTime = 0
  let totalScore = 0
  let count = 0
  matrix.map(mx => Object.keys(mx.listLevels).map(key => {
    totalScore += Number(mx.listLevels[key].score)
    totalTime += Number(mx.listLevels[key].time)
    count += Number(mx.listLevels[key].quantity)
  }))
  return { totalTime, totalScore: totalScore.toFixed(2), count }
}

const caculateScoreAndTimeMatrixPart = (parts) => {
  let totalTime = 0
  let totalScore = 0
  let count = 0
  Object.keys(parts).forEach(key => {
    if (parts[key].matrix) {
      parts[key].matrix.forEach(mx => {
        if (mx.time) totalTime += Number(mx.time) * Number(mx.quantity)
        if (mx.factor) totalScore += Number(mx.factor) * Number(mx.quantity)
        count += Number(mx.quantity)
      })
    }
  })
  return { totalTime, totalScore: totalScore.toFixed(2), count }
}

const getUpdatedMatrix = async (matrics) => {
  let newMatrix = []
  await matrics.forEach(mx => {
    Object.keys(mx.listLevels).forEach(level => {
      newMatrix.push({
        factor: Number((Number(mx.listLevels[level].score) / Number(mx.listLevels[level].quantity)).toFixed(3)),
        knowledge: mx.knowledge.id,
        'knowledge-title': mx.knowledge.title,
        level: level,
        quantity: Number(mx.listLevels[level].quantity),
        time: Number(mx.listLevels[level].time) / Number(mx.listLevels[level].quantity)
      })
    })
  })
  return newMatrix
}

const getKnowlegdesBeforeSave = (detailInfo) => {
  let listKnowledges = {}
  if (detailInfo.isPartition) {
    const partition = detailInfo.partition
    Object.keys(partition).map(key => {
      partition[key].matrix.map(mx => {
        listKnowledges[mx.knowledge] = 1
      })
    })
  } else {
    detailInfo.matrix.map((item) => listKnowledges[item.knowledge] = 1)
  }
  return Object.keys(listKnowledges).map(key => { return key })
}

const parseScore = (score, number) => {
  return score ? Number(score.toFixed(number)) : 0
}

const transformBeforeSave = (data) => {
  return Object.keys(data.listLevels).map(key => {
    const level = parseScore(Number(key), 2)
    const item = data.listLevels[key]
    const quantity = parseScore(Number(item.quantity), 2)
    const totalScore = parseScore(Number(item.score), 2)
    return {
      typeQuestion: data.typeQuestion,
      knowledge: data.knowledge.id,
      'knowledge-title': data.knowledge.title,
      level: level,
      quantity: quantity,
      factor: Number((totalScore / quantity).toFixed(3)),
      time: Number(item.time) / quantity
    }
  })
}

const getQuantityAndScore = (matrix) => {
  return {
    quantity: matrix.quantity,
    score: Number(matrix.quantity) * Number(matrix.factor),
    time: Number(matrix.time || 0) * Number(matrix.quantity)
  }
}

const transformInitData = (exam) => {
  let mergeObj = {}
  if (!exam || !exam.matrix) return
  exam.matrix.map(matrix => {
    if (mergeObj[matrix.knowledge]) {
      mergeObj[matrix.knowledge].listLevels[
        matrix.level
      ] = getQuantityAndScore(matrix)
    } else {
      const knowledgeDetail = exam.knowledge.find(
        item => item.id === matrix.knowledge
      )
      mergeObj[matrix.knowledge] = {
        knowledge: {
          id: matrix.knowledge,
          title: knowledgeDetail
            ? knowledgeDetail.title
            : matrix['knowledge-title']
        },
        listLevels: {
          [`${matrix.level}`]: getQuantityAndScore(matrix)
        }
      }
    }
  })
  return Object.values(mergeObj)
}

const transformPartsForMatrix = (exam) => {
  if (!exam.partition) return {}
  Object.keys(exam.partition).forEach(key => {
    exam.partition[key] = {
      ...exam.partition[key],
      knowledge: exam.knowledge,
      id: key
    }
  })
  return exam.partition
}

const validateOneMatrix = async (mx, settings) => {
  // if (!mx.factor || !mx.quantity) return 'Kiểm tra lại điểm và số lượng câu'
  // const splitFactor = String(mx.factor).split('.')
  // if (splitFactor[1] && splitFactor[1].length > 3) return vuetify.framework.lang.translator('$vuetify.HINT_MARK_NOTE')
  if (settings.selectedTime === 'one-by-one-limited') {
    if (mx.time && !Number.isInteger(mx.time)) return vuetify.framework.lang.translator('$vuetify.HINT_TIME_FOR_QUESTION')
  }
  if (!mx.quantity) return
  const res = await Question.count({
    knowledge: mx.knowledge,
    level: mx.level
  })
  if (!res.data || Number(res.data) < Number(mx.quantity)) {
    return `${vuetify.framework.lang.translator('$vuetify.TITLE_TOPIC')} ${mx['knowledge-title']} ${vuetify.framework.lang.translator('$vuetify.HINT_UNDER_QUESTION_IN_QUESTION_BANK')}`
  }
}

const validateMatrixBeforeSave = async (data, settings) => {
  let errorMessage = ''
  let totalScore = 0
  if (!data.partition) {
    for await (const entry of data.matrix.entries()) {
      const mx = entry[1]
      const error = await validateOneMatrix(mx, settings)
      if (error) errorMessage = error
      if (mx.factor && mx.quantity)
        totalScore += (mx.factor * mx.quantity)
    }
  } else {
    for await (const firstEntry of Object.keys(data.partition).entries()) {
      const key = firstEntry[1]
      for await (const secondEntry of data.partition[key].matrix.entries()) {
        const mx = secondEntry[1]
        const error = await validateOneMatrix(mx, settings)
        if (error) errorMessage = error
        if (mx.factor && mx.quantity)
          totalScore += (mx.factor * mx.quantity)
      }
    }
  }
  totalScore = Number(totalScore.toFixed())
  if (settings.selectedTypeScore === '10-mark' && totalScore !== 10) errorMessage = 'Tổng điểm phải bằng 10'
  return errorMessage
}

export default {
  caculateScoreAndTimeMatrix, transformBeforeSave,
  transformInitData, transformPartsForMatrix, caculateScoreAndTimeMatrixPart,
  getUpdatedMatrix, getKnowlegdesBeforeSave, validateMatrixBeforeSave
}