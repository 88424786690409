import jp from '@/plugins/locale.jp.js'
import AUTHENTICATION from './jp/authentication'
import DASHBOARD from './jp/dashboard'
import COURSE from './jp/course'
import COURSE_CONTENT from  './jp/courseContent'

import MANAGE_FINANCE from './jp/manageFinance'
import MENU_SETTING from './jp/menuSetting'
import SITE_BUILDER from './jp/siteBuilder'

import ROUTE from './jp/route'
import BLOG from  './jp/blog'

import SYSTEM_SETTING from  './jp/systemSetting'
import MY_ACCOUNT from  './jp/myAccount'
import USER_MANAGE from './jp/userManage'
import DEFAULT_DATA from './jp/defaultData'
import INPUT from './jp/input'

export default {
  AUTHENTICATION,
	DASHBOARD,
  COURSE,
  COURSE_CONTENT,
  MANAGE_FINANCE,
  MENU_SETTING,
  SITE_BUILDER,
  ROUTE,
  BLOG,
  SYSTEM_SETTING,
  MY_ACCOUNT,
  USER_MANAGE,
  DEFAULT_DATA,
  INPUT,
  BUTTON_SEARCH: "検索",
  LABEL_PAGE: "ページ",
  LABEL_TO: "から",
  LABEL_SUM: "合計",
  TEXT_SUM: "合計",
  LABEL_NOT_YET:'まだ',
//
  TITLE_HOME_PAGE: "ホームページ",
  BUTTON_CANCEL: "キャンセル",
  BUTTON_CONFIRM: "確認",
  //kiểm tra lại
  TOOLTIP_FILE_NOT_HEADER: "ファイルにヘッダーがありません",
  //
  STUDENTS:'ユーザー',
  TITLE_TOPIC: "トピック",
  TEXT_GROUP: "グループ",
  TEXT_QUESTION: "質問",
  TEXT_QUESTION_CHILD: "子供の質問",
  TEXT_NOT_NAME: "名称無し",
  TEXT_NOT_EMAIL: "メール無し",
  TEXT_HOUR: "時間",
  TEXT_MINUTE: "分",
  TEXT_SECOND: "秒",
  LABEL_VIDEO:"動画",

  LABEL_PDF_SLIDE: "pdf資料",
  LABEL_TEST: "テスト（自己作成）",
  LABEL_EXERCISE: "テスト（既存）",
  LABEL_HOMEWORK: "宿題",
  LABEL_MATRIX: "試験問題（問題マトリックス）",
  REJECTED:'拒否',
  SUCCESS:'完了',
  PENDING:'待つ',
  FAILED:'不合格',
  RETURNED:'戻ってきた',
  LABEL_TRANSFER: "振込",
  LABEL_DIRECT_PAY: "直接支払い",
  LABEL_VN_PAY: "VNPAY",
  LABEL_ADMIN_CREATED: "管理者が作成しました",
  LABEL_ACTIVATION_CODE: "アクティベーションコード",
  TEXT_WHEN: "時に",

  TEXT_ALMOST: "{0}時間近く",
  TEXT_MORE_THAN: "{0}時間以上",

  LABEL_TEXT_TEST: "プレビューの終了",
  // BASELOADINGALLSCREEN

  //kiểm tra
  HINT_MARK_NOTE: "スコアは3桁を超えて奇数にすることはできません",
  HINT_TIME_FOR_QUESTION: "各文の時間は存在する必要があり,奇数であってはなりません",
  HINT_UNDER_QUESTION_IN_QUESTION_BANK: "テスト項目の文の数が不十分です",
  MSG_FILE_FOMAT_FALSE: "ファイル形式が無効です。サンプルテンプレートを確認するか,再試行してください！",
  // EMPTY_DATA
  TITLE_EMPTY_DATA: "データが存在しません",
  SUBTITLE_EMPTY_DATA: "このリストに表示する新しいデータを作成してください",
  BUTTON_EMPTY_DATA: "データの生成",
  LABEL_NAME_CATEGORY_CHILD: "サブカテゴリ名",
  PLACEHOLDER_INPUT_NAME_CATEGORY_CHILD: "追加するサブカテゴリの名前を入力してください",
  MSG_FILTERING_CONTACT: "連絡先をフィルタリングしています。",

  TEXT_YES:'はい',
  TEXT_NO: "いいえ",

  //kiểm tra lại
  MSG_NOTICATION_NOT_DOWNLOAD_FILE: "現在,ファイルをダウンロードできません。キャッシュをクリアするか,シークレットブラウザを使用してください",

  BUTTON_POST: "役職",
  BUTTON_VIEW_ACCEPT: "表示および参照",
  LABEL_DATE: "日",
  BUTTON_COPY: "コピー",
  INFO_GENERAL: "基本情報",
  LABEL_ON:"の上",
  MSG_ERROR_SYSTEM: "システムエラー",
  ...jp
}