/* eslint-disable no-unused-vars */
import { Vendor } from '@/api/repository/vendorRepository.js'
import get from 'lodash-es/get'
const state = {
	domain: '',
	vendor: {},
	settings: {},
	dataHomePage: [],
	totalStudents: 0,

	newStudents: [],

	statistic: [],
	totalStatistic: {},
	statisticVerify: [],
	statisticUnverified: [],
}
const actions = {
	async fetchVendor({ commit }) {
		const res = await Vendor.getVendorIdFromOrigin()
		await commit('setVendor', res)
		return res
	},
	async updateVendorCredential(_, params) {
		const res = await Vendor.updateVendorCredential(params)
		return res
	},
	async fetchVendorCredential(_) {
		const result = await Vendor.fetchVendorCredential()
		return result
	},
	async updateAdminSiteName({ state, commit }, siteName) {
		let settings = state.settings
		if (!settings.admin) settings.admin = {}
		Object.assign(settings.admin, siteName)
		const res = await Vendor.updateSettings(settings)
		commit('setSettings', res.data)
		return res
	},
	async updateBankInfo({ state, commit }, bankInfo) {
		let settings = state.settings
		if (!settings.bankInfo) settings.bankInfo = {}
		Object.assign(settings.bankInfo, bankInfo)
		const res = await Vendor.updateSettings(settings)
		commit('setSettings', res.data)
		return res
	},
	async getSettingsVendor({ commit, state }) {
		const res = await Vendor.getSettingsVendor(state.vendor)
		_updateTheme(get(res.settings, 'learn.themeConfig.themeData'))
		commit('setSettings', res.settings)
		commit('setDomain', get(res, 'learn.hostname'))
		return res.settings
	},
	async updateSettingsVendor({ commit, state }, params) {
		let settings = state.settings
		if (!settings.learn.themeConfig) settings.learn.themeConfig = {}
		Object.assign(settings.learn.themeConfig, params)
		const res = await Vendor.updateSettings(settings)
		commit('setSettings', res.data)
		return res
	},
	async updateMobileTheme({ commit, state }, params) {
		let settings = state.settings
		if (!settings.learn.mobileThemeConfig) settings.learn.mobileThemeConfig = {}
		Object.assign(settings.learn.mobileThemeConfig, params)
		const res = await Vendor.updateSettings(settings)
		commit('setSettings', res.data)
		return res
	},
	async updateContact({ commit, state }, params) {
		let settings = state.settings
		if (!settings.contact) settings.contact = {}
		Object.assign(settings.contact, params)
		const res = await Vendor.updateSettings(settings)
		commit('setSettings', res.data)
		return res
	},
	async updateLearnSettings({ commit, state }, learn) {
		let settings = state.settings
		if (!settings.learn) settings.learn = {}
		Object.assign(settings.learn, learn)
		if (!settings.learn.themeConfig) return
		const res = await Vendor.updateSettings(settings)
		commit('setSettings', res.data)
		return res
	},
	async updateDisplayMenuSetting({ state, commit }, displayMenuSetting) {
		state.settings.learn[`${displayMenuSetting.displayMenuSetting}`] =
			displayMenuSetting.isDisplayMenuSetting
		let settings = {
			...state.settings,
			learn: {
				...state.settings.learn
			}
		}
		const res = await Vendor.updateSettings(settings)
		commit('setSettings', res.data)
		return res
	},
	async fetchStatistic({ commit }, params = {}) {
		const res = await Vendor.statistic({
			...params
		})
		commit('setTotalStatistic', res.data || {})
		commit('setTotalStudents', res.data.total || 0)
		commit('setNewStudents', res.data.newStudents || [])
		commit('setStatistic', res.data.statistic || [])
		commit('setStatisticVerify', res.data.statisticVerify || [])
		commit('setStatisticUnverified', res.data.statisticUnverified || [])
		return res.data
	}
}
const mutations = {
	setVendor(state, vendor) {
		state.vendor = vendor
	},
	setDomain(state, domain) {
		state.domain = domain
	},
	setSettings(state, settings) {
		state.settings = settings
	},
	setDataHomePage(state, dataHomePage) {
		state.dataHomePage = dataHomePage || []
	},
	setTotalStudents(state, total) {
		state.totalStudents = total
	},
	setNewStudents(state, newStudents) {
		state.newStudents = newStudents.sort((a, b) => b.createdAt - a.createdAt)
	},
	setStatistic(state, statistic) {
		state.statistic = statistic
	},
	setTotalStatistic(state, totalStatistic) {
		state.totalStatistic = totalStatistic
	},
	setStatisticVerify(state, statisticVerify) {
		state.statisticVerify = statisticVerify
	},
	setStatisticUnverified(state, statisticUnverified) {
		state.statisticUnverified = statisticUnverified
	}
}
const getters = {
	getContact(state) {
		return get(state.settings, 'contact')
	},
	getLearningDomain(state) {
		return `${state.domain}`
	},
	getHeaderSetting(state) {
		return get(state.settings, 'learn.isDisplayMarketHeader')
	},
	getisAccessCourseDirectly(state) {
		return get(state.settings, 'learn.isAccessCourseDirectly')
	},
	getExternalScript(state) {
		return get(state.settings, 'learn.externalScript')
	},
	getIsDisplayProfile: state => {
		return get(state.settings, 'learn.isDisplayProfile')
	},
	getSocialNetwork: state => {
		return get(state.settings, 'learn.socialNetwork')
	},
	getNotificationSetting: state => {
		return get(state.settings, 'learn.notificationSetting')
	},
	getExceedWatchVideo: state => {
		return {
			countExceedWatchVideo: get(state.settings, 'learn.countExceedWatchVideo'),
			isCountExceedWatchVideo: get(
				state.settings,
				'learn.isCountExceedWatchVideo'
			)
		}
	},
	getBaitNotificationSettings: state => {
		return {
			isNotificationBaited: get(
				state.settings,
				'learn.isNotificationBaited',
				false
			),
			baitNotifications: get(state.settings, 'learn.baitNotifications', [])
		}
	},
	getCurrentLanguage: state => {
		return get(state.settings, 'learn.currentLanguage', 'vi')
	},
	getIsDisplayMarketHeader(state) {
		return get(state.settings, 'learn.isDisplayMarketHeader')
	},
	getIsDisplayMenuHeaderSetting: state => {
		return get(state.settings, 'learn.isDisplayMenuHeaderSetting')
	},
	getIsDisableRegistration: state => {
		return get(state.settings, 'learn.isDisableRegistration')
	},
	getIsDisplayMenuFooterSetting: state => {
		return get(state.settings, 'learn.isDisplayMenuFooterSetting')
	},
	getHeaderNav(state) {
		return get(state.settings, 'learn.isDisplayMenuHeaderSetting')
	},
	getFooterItem(state) {
		return get(state.settings, 'learn.isDisplayMenuFooterSetting')
	},
	getThemedata(state) {
		return get(state.settings, 'learn.themeConfig.themeData')
	},
	getViewHomepage(state) {
		return get(state.settings, 'learn.themeConfig.viewData')
	},
	getViewAboutus(state) {
		return get(state.settings, 'learn.themeConfig.aboutMe')
	},
	getViewPrivacyPolicy(state) {
		return get(state.settings, 'learn.themeConfig.privacyPolicy')
	},
	getViewRecruitment(state) {
		return get(state.settings, 'learn.themeConfig.recruitment')
	},
	getSiteName(state) {
		return get(state.settings, 'admin.siteName')
	},
	getDataHomePage(state) {
		return state.dataHomePage
	},
	getIsDisplayZoom: state => {
		return get(state.settings, 'learn.isDisplayZoom')
	},
	getBlockLimitDevice: state => {
		return get(state.settings, 'learn.blockLimitDevice')
	},
	getVendor(state) {
		return state.vendor
	},
	getBankInfo(state) {
		return get(state.settings, 'bankInfo')
	},
	getTotalStudents: state => {
		return state.totalStudents
	},
	getNewStudents: state => {
		return state.newStudents
	},
	getStatistic: state => {
		return state.statistic
	},
	getTotalStatistic: state => {
		return state.totalStatistic
	},
	getMobileHomepage: state => {
		return get(state.settings, 'learn.mobileThemeConfig.viewData')
	},
	getPermissionAllZoom: state => {
		return get(state.settings, 'learn.isPermissionAccessAllZoom')
	},
	getStatisticVerify: state => {
		return state.statisticVerify
	},
	getStatisticUnverified: state => {
		return state.statisticUnverified
	},
	getIsDisplayCustomTopic: state => {
		return get(state.settings, 'learn.isDisplayCustomTopic')
	},
	getCustomTopic: state => {
		return get(state.settings, 'learn.customTopic')
	}
}

const _updateTheme = themeConfig => {
	const old = localStorage.getItem('iztech-theme')
	const newTheme = JSON.stringify(themeConfig)
	if (!old) {
		localStorage.setItem('iztech-theme', newTheme)
	}
	if (newTheme !== old) {
		localStorage.setItem('iztech-theme', newTheme)
		return window.location.reload()
	}
}

export default {
	state,
	actions,
	getters,
	mutations,
	namespaced: true
}
