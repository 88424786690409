import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const UserPermission = RepositoryFactory.get('userPermission')

const state = {
	userPermissions: [],
	userPermissionsBook: [],
	count: 0
}

const actions = {
	async fetchUserPermissions({ commit }, userId) {
		if (userId) {
			const res = await UserPermission.fetch({ userId })
			commit('setUserPermissions', res.data.courses)
			commit('setUserPermissionsBook', res.data.books)
			return res.data
		}
	},
	async count({ commit }, params) {
		const res = await UserPermission.count(params)
		commit('updateCount', res.data)
	},
	async checkValidateUserPermission(_, { userId, courseId }) {
		const res = await UserPermission.check({
			userId,
			payload: {
				relationId: courseId
			}
		})
		return res.data
	},
	async revokePermission({ commit }, params = {}) {
		const res = await UserPermission.revokeRead(params)
		commit('filterCourse', params.relationId)
		commit('filterBook', params.relationId)
		return res.data
	},
	async grandReadPermission(_, params = {}) {
		const res = await UserPermission.grantRead(params)
		return res.data
	},
	async setLimitedTime({ dispatch }, { id, payload }) {
		const res = await UserPermission.setLimitedTime(id, payload)
		dispatch('fetchUserPermissions', res.data.userId)
		return res.data
	}
}

const mutations = {
	setUserPermissions(state, userPermissions) {
		state.userPermissions = userPermissions
	},
	setUserPermissionsBook(state, userPermissionsBook) {
		state.userPermissionsBook = userPermissionsBook
	},
	pushCourse(state, newCourse) {
		state.userPermissions = state.userPermissions.unshift(newCourse)
	},
	filterCourse(state, id) {
		state.userPermissions = state.userPermissions.filter(item => item.id !== id)
	},
	updateCount(state, count) {
		state.count = count
	},
	filterBook(state, id) {
		state.userPermissionsBook = state.userPermissionsBook.filter(
			item => item.id !== id
		)
	}
}

const getters = {
	getUserPermissions: state => {
		return state.userPermissions
	},
	getUserPermissionsBook: state => {
		return state.userPermissionsBook
	},
	getCount: state => {
		return state.count
	}
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}
