<template>
	<div>
		<div class="d-flex align-center">
			<BaseResourceAutoComplete hideDetails 
				:label="$vuetify.lang.t('$vuetify.COURSE.COURSE_OVERVIEW.LABEL_SELECT_VIDEO_FROM_LIBRARY')" 
				v-model="resource" 
				:filter="filterResource()"
				class="full-width"
			/>
			<v-tooltip color="grey darken-3" top v-if="isResourceDownloadAllowed && resource && type === 'pdf'">
				<template v-slot:activator="{on, attrs}">
					<v-btn icon outlined v-bind="attrs" v-on="on" class="ml-2 action-hover rounded" @click="onDownloadResource">
						<v-icon>mdi-download</v-icon>
					</v-btn>
				</template>
				<span>{{$vuetify.lang.t('$vuetify.COURSE.BUTTON_DOWNLOAD')}}</span>
			</v-tooltip>
		</div>
		<div class="mt-2 d-flex align-center" v-if="type === 'video' && resource">
			<span class="mr-2">{{$vuetify.lang.t("$vuetify.COURSE.COURSE_OVERVIEW.LABEL_STATUS_VIDEO")}}:</span>
			<span :class="videoStatus === 'done' ? 'green--text' : 'red--text'">
				{{videoStatus === 'done' ? $vuetify.lang.t("$vuetify.COURSE.COURSE_OVERVIEW.TEXT_READY") : $vuetify.lang.t("$vuetify.COURSE.COURSE_OVERVIEW.TEXT_VIDEO_PROCESSED")}}
			</span>
			<v-icon
				v-if="videoStatus !== 'done'"
				@click="getvideoStatus(resource)"
				class="ml-2"
				:class="{spin: loadingStatus}"
			>
				mdi-autorenew
			</v-icon>
		</div>
		<BaseUploadWithButton
			class="mt-2"
			:maxFilesize="maxFilesize"
			:type="type"
			@onUploadSuccess="file => onUploadSuccess(file)"
			:relation="relation"
		/>
		<div v-if="type === 'video' && resource" class="mt-4">
			<div class="subtitle-2">
				{{
					$vuetify.lang.t(
						'$vuetify.COURSE.COURSE_OVERVIEW.TITLE_VIDEO_THUMBNAIL'
					)
				}}
			</div>
			<div class="body-2" mt-1>
				{{
					$vuetify.lang.t(
						'$vuetify.COURSE.COURSE_OVERVIEW.SUBTITLE_VIDEO_THUMBNAIL',
						'1280x720px',
						'16:9',
						'2MB'
					)
				}}
			</div>
			<BaseUploadImage
				:maxFilesize="2"
				:imageUrl="thumbnailImage"
				@onUploadSuccess="res => onUploadThumbnailImage(res[0].link)"
				class="mt-2"
			/>
		</div>
	</div>
</template>
<script>
import {mapActions} from 'vuex'
import get from 'lodash-es/get'
export default {
	props: {
		initResource: Object,
		maxFilesize: Number,
		type: String,
		isResourceDownloadAllowed: {
			type: Boolean,
			default: false
		},
		relation: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			resource: undefined,
			loadingStatus: false,
			videoStatus: '',
			thumbnailImage: '',

		}
	},
	methods: {
		...mapActions({
			fetchOneResourceIndependent: 'cinema/fetchOneResourceIndependent',
			updateVideoIndependent: 'cinema/updateVideoIndependent'
		}),
		async getvideoStatus(resource) {
			await this.$utils.delay(1500)
			this.loadingStatus = true
			try {
				const videoId = resource.id
				const dbResource = await this.fetchOneResourceIndependent(videoId)
				this.videoStatus = dbResource ? dbResource.status : ''
				return dbResource
			} catch (error) {
				this.videoStatus = ''
			}
			this.loadingStatus = false
		},
		filterResource() {
			if (this.type === 'video' || this.type === 'vimeo') {
				return {type: 'video'}
			} else {
				return this.type ? {subType: this.type} : {}
			}
		},
		onUploadSuccess(file) {
			this.resource = file[0]
		},
		getValue() {
			return this.resource
		},
		async onUploadThumbnailImage(link) {
			this.$message.loading(
				this.$vuetify.lang.t(
					'$vuetify.COURSE.MSG_DATA_LOADING'
				),
				0
			)
			try {
				const res = await this.updateVideoIndependent({
					id: this.resource.id,
					'origin.thumbnail': link
				})
				this.resource.origin.thumbnail = link
				this.getThumbnailImage(res)
				this.$message.success(
					this.$vuetify.lang.t(
						'$vuetify.COURSE.COURSE_OVERVIEW.MSG_ADD_VIDEO_THUMBNAIL_SUCCESS'
					)
				)
			} catch (error) {
				this.$message.error(error)
			}
		},
		getThumbnailImage(resource) {
			const thumbnail = get(resource, 'origin.thumbnail', '')
			if (typeof thumbnail === 'string' && thumbnail.length) {
				this.thumbnailImage = this.$utils.combineLinkWithBucket(thumbnail)
			} else {
				this.thumbnailImage = ''
			}
		},
		onDownloadResource() {
			let fileLink = get(this.resource, 'origin.link', '')
			if (typeof fileLink === 'string' && fileLink.length) {
				fileLink = this.$utils.convertURL(this.$utils.combineLinkWithBucket(fileLink))
				window.open(fileLink, '_blank', 'noopener noreferrer')
			} else {
				this.$message.error(this.$vuetify.lang.t('$vuetify.MSG_NOTICATION_NOT_DOWNLOAD_FILE'))
			}
		}
	},
	watch: {
		initResource: {
			handler(initResource) {
				if (initResource && Object.keys(initResource).length) {
					this.resource = initResource
				} else this.resource = undefined
			},
			immediate: true
		},
		resource: {
			async handler(resource) {
				if (this.type === 'video') {
					const res = await this.getvideoStatus(resource)
					if (res) this.getThumbnailImage(res)
					else this.getThumbnailImage(resource)
				}
				this.$emit('change', resource)
			},
			immediate: true
		}
	}
}
</script>
<style lang="scss" scoped>
@keyframes spin-animation {
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
.spin {
	-webkit-animation: spin-animation 2s linear infinite;
	animation: spin-animation 2s linear infinite;
}
</style>
