import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const Exam = RepositoryFactory.get('exam')

const state = {
  exams: {},
  exam: {},
  count: 0,
  isPartition: false,
  settings: {},
  isLoading: false,
  openScorePart: false,
  openTimePart: false,
  typeScore10Mark: false,
  isUpdated: false,
  timeUpdatePart: null
};

const actions = {
  async createExam({ commit }, data) {
    const exam = await Exam.create(data);
    commit("addExam", exam.data);
    commit("setExamData", exam.data);
    return exam.data;
  },
  async createExamMatrix(_, examId) {
    const exam = await Exam.createExamMatrix({
      exam: examId
    });
    return exam.data;
  },
  createOrUpdateExam({ dispatch }, { exam, data }) {
    if (exam) {
      return dispatch('updateExam', { id: exam, exam: data });
    } else {
      return dispatch('createExam', data);
    }
  },
  resetExam({ state }) {
    state.exams = {};
    state.exam = {};
    state.count = 0;
  },
  async updateExam({ commit }, { id, exam }) {
    const res = await Exam.update(id, exam);
    commit("setExamData", res.data);
    return res.data
  },
  async fetchExamById({ commit }, id) {
    const res = await Exam.fetchOne(id);
    commit("setExamData", res.data);
    commit("updateTypeScore10Mark", false);
    return res.data
  },
  async fetchExams({ commit }, params = {}) {
    const res = await Exam.fetch({
      ...params,
    });
    commit("setExams", res.data || []);
    return res.data;
  },
  async generateExam(_, params = {}) {
    const res = await Exam.generateExam(params)
    return res.data || []
  },
  async updateDialogScorePart({ state }, value) {
    state.openScorePart = value
  },
  async updateDialogTimePart({ state }, value) {
    state.openTimePart = value
  },
};

const mutations = {
  addExam(state, exam) {
    state.count = state.count + 1;
    state.exams = {
      [exam.id]: exam,
      ...state.exams,
    };
  },
  setIsPartition(state, value) {
    state.isPartition = value
  },
  setExams(state, exams) {
    return state.exams = exams;
  },
  setLoading(state, isLoading) {
    return state.isLoading = isLoading
  },
  setExamData(state, exam) {
    state.settings = exam.settings
    state.isPartition = exam.settings.isPartition
    return (state.exam = exam);
  },
  setSettings(state, settings) {
    state.settings = settings
  },
  updateTypeScore10Mark(state, value) {
    state.typeScore10Mark = value
  },
  async setUpdated(state, value) {
    state.isUpdated = value
  },
  async setTimeUpdatePart(state, value) {
    state.timeUpdatePart = value
  },
};

const getters = {
  exams: (state) => {
    return state.exams;
  },
  exam: (state) => {
    return state.exam
  },
  isPartition: (state) => {
    return state.isPartition
  },
  getSettings: (state) => {
    return state.settings
  },
  isLoading: (state) => {
    return state.isLoading
  },
  openScorePart: (state) => {
    return state.openScorePart
  },
  openTimePart: (state) => {
    return state.openTimePart
  },
  typeScore10Mark: (state) => {
    return state.typeScore10Mark
  },
  isUpdated: (state) => {
    return state.isUpdated
  },
  timeUpdatePart: (state) => {
    return state.timeUpdatePart
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
