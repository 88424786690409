import axios from 'axios'
import get from 'lodash-es/get'
const USER_BASE_URL = process.env.VUE_APP_API_USER
const TEACHER_BASE_URL = process.env.VUE_APP_API_TEACHER
const RESOURCE_BASE_URL = process.env.VUE_APP_API_RESOURCE
const AUTH_USER_STORAGE_NAME = 'ol-credentials-auth-user'

const APIHelper = () => ({
	signIn: async body => {
		try {
			const payload = await axios.post(TEACHER_BASE_URL + '/signin', body)
			if (payload.data.accessToken && payload.data.refreshToken) {
				return payload.data
			} else {
				throw new Error(
					`[SignIn] Invalid sign in response ${JSON.stringify(payload.data)}`
				)
			}
		} catch (error) {
			throw _readAxiosError(error)
		}
	},
	changePassword: async (type, userInfo, vendor, accessToken) => {
		try {
			let { data: payload } = await axios.post(
				USER_BASE_URL + `/${type}/change-password`,
				{ ...userInfo, vendorId: vendor.vendorId },
				{
					headers: {
						Authorization: accessToken
					}
				}
			)
			if (payload.id) {
				return payload
			} else {
				throw new Error(
					`[SignUp] Invalid sign up payload '${JSON.stringify(payload)}'`
				)
			}
		} catch (error) {
			const codeError = get(error, 'response.data.code')
			if (codeError === '2005') {
				throw new Error('$vuetify.MY_ACCOUNT.PASSWORD_CHANGE_FORM.MSG_WRONG_OLD_PASSWORD')
			} else throw _readAxiosError(error)
		}
	},
	refreshToken: async (authUser, accessToken) => {
		let params = {
			refreshToken: authUser.refreshToken
		}
		try {
			const payload = await axios.get(
				TEACHER_BASE_URL + `/refresh/${authUser.id}`,
				{
					params: { ...params, vendorId: authUser.vendorId },
					headers: {
						Authorization: accessToken
					}
				}
			)
			return payload.data
		} catch (error) {
			const codeError = get(error, 'response.data.code')
			if (codeError === '2005') {
				window.localStorage.removeItem(AUTH_USER_STORAGE_NAME)
				window.location.reload()
				throw new Error('Tài khoản đã được đăng trên một thiết bị khác !')
			} else throw _readAxiosError(error)
		}
	},
	getS3SignedToken: async (params, accessToken,  urlPostfix = '/resource/signed-url') => {
		try {
			if (!params.key) throw new Error(`[GetS3SignedUrl] file 'key' required!`)
			if (!params.contentType) throw new Error(`[GetS3SignedUrl] file 'contentType' required!`)
			let body = { key: params.key, contentType: params.contentType }
			let res = await axios.get(RESOURCE_BASE_URL + urlPostfix, {
				params: body,
				headers: {
					Authorization: accessToken
				}
			})
			if (!res.data) throw new Error(`[GetS3SignedToken] Invalid payload!`)
			return res.data
		} catch (err) {
			throw _readAxiosError(err)
		}
	},
	requestTranscodingVideo: async ({resourceId, vendorId, extension}, accessToken) => {
		try {
			await axios.post(
				RESOURCE_BASE_URL + '/resource/request-transcode', 
				{resourceId, vendorId, extension}, 
				{headers: {Authorization: accessToken}}
			)
		} catch(err) {
			throw _readAxiosError(err)
		}
	}
})

function _readAxiosError(err) {
	let errorMessage = get(err, 'response.data.errorMessage')
	return errorMessage ? new Error(errorMessage) : err
}

export const Auth = APIHelper()
export default {
	Auth
}
