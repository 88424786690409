import {Auth} from '@/api/repository/authRepository.js'
import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const Resource = RepositoryFactory.get('resource')
import s3Uploader from '@/plugins/s3Uploader.js'
import auth from '@/store/modules/auth'
import get from 'lodash-es/get'
import mimeTypes from 'mime-types'
const namespaced = true
import vendor from '@/store/modules/vendor'

const state = {
	videos: {},
	video: {},
	count: 0,
  lastEvaluatedKey: null,
  resourceStatistic: {},
	resourceStatisticOfMonth: [],
	editedIndex: -1,
	filesEdited: []
}
const actions = {
	async createHLSVideo({commit}, {file, relation = {}, onProgress}) {
		const video = await Resource.create(
			generateVideo(file, relation, {
				link: null,
				subType: 'video',
				type: 'video',
				provider: 'VSTORAGE',
				html: null,
			})
		)
		const vendorId = vendor.state.vendor.vendorId
		let env = 'prod'
		if (vendorId == '23b0bfb0-c00b-11e9-a060-17d3861b7a5b') {
			env = 'test'
		}
		const resourceId = video.data.id
		const extension = file.name.split('.').pop()

		const link = `source-videos/${env}/${vendorId}/${resourceId}/source.${extension}`
		await s3Uploader.uploadFile(
			file,
			link,
			({percent}) => {
				onProgress({percent})
			},
			'/resource/signed-url-video'
		)
		Auth.requestTranscodingVideo({resourceId, vendorId, extension}, auth.state.auth.accessToken)
		commit('addVideo', video.data)
		return video.data
	},
	async fetchVideos({commit}, params = {}) {
		const res = await Resource.fetch({
			...params,
			type: 'video'
		})
		commit('setVideos', res.data || [])
		return res.data
	},
	async countVideos({commit}, params = {}) {
		const res = await Resource.count({
			...params,
			type: 'video'
		})
		commit('setCount', res.data || 0)
	},
	async updateVideo({commit}, {id, ...video}) {
		const res = await Resource.update(id, video)
		return commit('setVideo', get(res, 'data.Attributes', {}))
	},
	async removeVideo({commit}, item) {
		await Resource.remove(item.id, {
			vendorId: item.vendorId
		})

		return commit('removeVideo', item.id)
	},
	async setVideo({commit}, video) {
		return commit('setVideoData', video)
	},
	async addVideo({commit}, video) {
		return commit('setVideo', video)
  },
  async resourceStatistic({commit}, params ={}){
    const res = await Resource.fetchStatistic({...params})
    commit('setResourceStatistic', res.data  || {})
    return res.data
  },
	async resourceStatisticOfMonth({commit}, params ={}){
    const res = await Resource.fetchStatisticOfMonth({
			...params,
		})
    commit('setResourceStatisticOfMonth', res.data || [])
    return res.data
  },
	async fetchOneResourceIndependent(_, id) {
		const res = await Resource.fetchOne(id)
		return res.data || {}
	},
	async updateVideoIndependent(_, {id, ...params}) {
		const res = await Resource.update(id, params)
		return res.data || {}
	},
	setEditedIndex({commit}, index) {
		commit('setEditedIndex', index)
	},
	removeEditedIndex({commit}) {
		commit('removeEditedIndex')
	},
	setFileEdited({commit},files) {
		commit('setFileEdited', files)
	},
}

const mutations = {
	setVideo(state, video) {
		return (state.videos = {
			...state.videos,
			[video.id]: video
		})
	},
	setCount(state, count) {
		return (state.count = count)
	},
	addVideo(state, video) {
		state.count = state.count + 1
		return (state.videos = {
			[video.id]: video,
			...state.videos
		})
	},
	setVideoData(state, video) {
		return (state.video = video)
	},
	appendVideos(state, videos) {
		return (state.videos = {
			...state.videos,
			...videos.reduce((acc, cur) => ({...acc, [cur.id]: cur}), {})
		})
	},
	setVideos(state, videos) {
		return (state.videos = videos.reduce(
			(acc, cur) => ({...acc, [cur.id]: cur}),
			{}
		))
	},

	removeVideo(state, id) {
		delete state.videos[id]
		state.videos = {...state.videos}
	},
	setLastEvaluatedKey(state, key) {
		state.lastEvaluatedKey = key
  },
  setResourceStatistic(state, data){
    state.resourceStatistic = data
  },
	setResourceStatisticOfMonth(state, data){
		state.resourceStatisticOfMonth = data
	},
	setEditedIndex(state, index) {
		state.editedIndex = index
	},
	removeEditedIndex(state) {
		state.editedIndex = -1
	},
	setFileEdited(state, files) {
		state.filesEdited = files
	}
}
const getters = {
	videos: state => {
		return Object.values(state.videos)
	},
	count: state => {
		return state.count
	},
	video: state => {
		return state.video
  },
  resourceStatistic: state => {
    return state.resourceStatistic
  },
	getResourceStatisticOfMonth: state => {
		return state.resourceStatisticOfMonth
	},
	getEditedIndex: state => state.editedIndex,
	getFileEdited: state => state.filesEdited,
}

const generateVideo = (file, relation = {}, {link, subType, provider, html, bucket}) => {
	return {
		title: file.name,
		size: file.size,
		dataType: 'resource',
		createdBy: auth.state.auth.id,
		link,
		html,
		provider,
		type: 'video',
		origin: {
			link,
			size: file.size,
			type: 'video',
			html,
			mimeType: mimeTypes.lookup(file.name),
			bucket,
		},
		subType,
		relation
	}
}
export default {
	namespaced,
	state,
	actions,
	mutations,
	getters
}
