import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const Product = RepositoryFactory.get('ProductRepository')

const state = {
  products: [],
  product: {},
  newProduct: {},
  count: 0,
  previewImage: [],
  ListImage: [],
  errorMessage: ''
}
const actions = {
  async fetchProducts({commit}, params = {}) {
    const res = await Product.fetch({
      ...params,
      _populate: 'categoryId variants',
    })
    commit('setProducts', res.data || [])
    return res.data || []
  },
  async fetchProduct({commit}, id) {
    const res = await Product.fetchOneWithParams(id, {_populate: 'variants'})
    commit('setProduct', res.data || [])
    return res.data || {}
  },
  async createProduct(_, params) {
    const res = await Product.create(params)
    return res.data || {}
  },
  async updateProduct({commit}, {id, ...params}) {
    const res = await Product.update(id, params)
    commit('setProduct', res.data || {})
    return res.data
  },
  async removeProduct({commit}, {id}) {
    const res = await Product.remove(id)
    commit('removeProduct', id)
    return res.data
  },
  async countProducts({commit}, params = {}) {
    const res = await Product.count({
      ...params,
    })
    commit('setCount', res.data || [])
    return res.data
  },
  setErrorMessage({commit}, error) {
    commit('setErrorMessage', error)
  },
}
const mutations = {
  setProducts(state, list) {
    state.products = list
  },
  setProduct(state, product) {
    state.product = product
  },
  setCount(state, count) {
    return (state.count = count)
  },
  removeProduct(state, id) {
    let deleteIndex = state.products.findIndex((listItem) => listItem.id === id)
    if (deleteIndex > -1) {
      state.products = state.products.filter((listItem) => listItem.id !== id)
    }
  },
  setNewProduct(state, product) {
    state.newProduct = product
  },
  clearNewProduct(state) {
    state.newProduct = {}
  },
  setPreviewImage(state, img) {
    state.previewImage.push(img)
  },
  setListImage(state, data) {
    state.ListImage.push(data)
  },
  clearAllProduct(state) {
    state.product = {}
  },
  clearPreviewImage(state) {
    state.previewImage = []
    state.ListImage = []
  },
  setErrorMessage(state, error) {
    state.errorMessage = error
  },
}
const getters = {
  getProducts: (state) => state.products,
  getProduct: (state) => state.product,
  count: (state) => state.count,
  getNewProduct: (state) => state.newProduct,
  getPreviewImage: state => state.previewImage,
  getListImage: state => state.ListImage,
  getErrorMessage: state => state.errorMessage
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
