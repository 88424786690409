import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_RESOURCE + '/resource'
const resourceStatisticUrl = process.env.VUE_APP_API_RESOURCE + '/resource-statistic'

const fetch = params => {
	return Repository(baseURL).fetch(params)
}
const count = params => {
	return Repository(baseURL + '/count').fetch(params)
}

const fetchOne = id => {
	return Repository(baseURL).fetchOne(id)
}

const create = params => {
	return Repository(baseURL).create(params)
}

const update = (id, params) => {
	return Repository(baseURL).update(id, params)
}

const remove = id => {
	return Repository(baseURL).delete(id)
}
const fetchStatistic = params => {
	return Repository(baseURL + '/statistic').fetch(params)
}
const fetchStatisticOfMonth = params => {
	return Repository(resourceStatisticUrl).fetch(params)
}

const saveLoadTimes = params => {
  return Repository(resourceStatisticUrl).create(params)
}

export default {
	fetch,
	fetchOne,
	create,
	update,
	remove,
	count,
	fetchStatistic,
	fetchStatisticOfMonth,
	saveLoadTimes,
}
