import axios from "axios"
import auth from '@/store/modules/auth'
import vendor from '@/store/modules/vendor'
import helpers from '@/helpers'

/**
 * Upload .docx file to Remote Server
 * @param {*} file 
 * @param {string} unitId 
 * @param {function} onProgress 
 * @returns List Question read from file
 */
function uploadDocxToServer(file, unitId, onProgress) {
  const data = new FormData()
  const vendorId = vendor.state.vendor.vendorId
  data.append('file', file)
  data.append('vendorId', vendorId)
  data.append('unitId', unitId)
  const uploaderURL = 'https://ee.izteach.vn/api/v1/upload'
  return axios.post(
    uploaderURL,
    data,
    {
      headers: {
        Authorization: auth.state.auth.accessToken
      },
      onUploadProgress: progressEvent => {
        const percentage = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(2)
        onProgress({
          loaded: parseFloat(progressEvent.loaded),
          total: parseFloat(progressEvent.total),
          percent: parseFloat(percentage),
        })
      }
    }
  )
}
/**
 * Map data returned from remote server to IZ schema
 * @param {*} listData Questions returned from Remote Upload
 * @returns Questions with IZ schema
 */
function mapQuestionData(listData = []) {
  const listQuestion = []
  if (Array.isArray(listData) && listData.length) {
    for (let i = 0; i < listData.length; i++) {
      const data = listData[i]
      const config = { time: 1 }
      if (data.Explain) {
        config.explain = data.Explain
        config.isShowExplain = true
      }
      const question = {
        id: data.Id,
        html: data.Content || "",
        answers: [],
        correct: '',
        type: helpers.TYPES_OF_QUESTIONS.SINGLE_CHOICE,
        config,
        factor: 1,
      }
      if (data.Correct && Array.isArray(data.Correct)) {
        if (data.Correct.length === 1) {
          question.correct = data.Correct[0]
          question.type = helpers.TYPES_OF_QUESTIONS.SINGLE_CHOICE
        } else if (data.Correct.length > 1) {
          question.correct = [...data.Correct]
          question.type = helpers.TYPES_OF_QUESTIONS.MULTIPLE_CHOICES
        }
        // handle FILL_BLANK case; ETA N/A
      }
      const listDataAnswers = data.Answers || []
      if (Array.isArray(listDataAnswers) && listDataAnswers.length) {
        for (let j = 0; j < listDataAnswers.length; j++) {
          const dataAnswer = listDataAnswers[j]
          question.answers.push({
            type: 'html',
            value: dataAnswer.Content,
            label: helpers.DEFAULT_LABELS[j],
            key: dataAnswer.Id,
          })
        }
      }
      listQuestion.push(question)
    }
  }
  return listQuestion
}

export default {
  uploadDocxToServer,
  mapQuestionData,
}