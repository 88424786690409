import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const OnlineCourseRepository = RepositoryFactory.get('onlineCourse')

const state = {
  courses: {},
  course: {},
  count: 0,
  statistic: [],
  coursesStatistics: {},
  countParticipant: 0,
  totalCount: 0
}
const actions = {
  async fetchCourses({ commit }, params = {}) {
    const res = await OnlineCourseRepository.fetch(params);
    commit("setCourses", res.data || []);
    return res.data;
  },
  async fetchCourseById({ commit }, id) {
    const res = await OnlineCourseRepository.fetchOne(id);
    commit("setCourse", res.data || {});
    return res.data;
  },
  async createCourse({ commit }, data) {
    const res = await OnlineCourseRepository.create(data);
    commit("addCourse", res.data);
    return res.data;
  },
  async updateCourse({ commit }, { id, ...course }) {
    const res = await OnlineCourseRepository.update(id, course)
    commit('setCourse', res.data)
    return res.data
  },
  async removeCourse({ commit }, id) {
    await OnlineCourseRepository.remove(id)
    return commit('removeCourse', id)
  },
  async countAllCourse({ commit }, params = {}) {
    const res = await OnlineCourseRepository.count({ ...params })
    commit('setCount', res.data || 0)
    return res.data
  },
  async setCourse({ commit }, course) {
    return commit("replaceCourse", course);
  },
  async fetchStatistic({ commit }, params = {}) {
    const res = await OnlineCourseRepository.statistic({
      ...params,
    }); 
    commit("setCoursesStatistics", res.data || {})
    commit("setStatistic", res.data.courses || []);
    commit("setCountOfParticipant", res.data.totalCount || 0);
    commit("setTotalCountCourse", res.data.allCourses || 0)
    return res.data;
  },
  async countCourseIndependent(_, params = {}) {
    const res = await OnlineCourseRepository.count({ ...params })
    return res.data
  }
}

const mutations = {
  setCourses(state, courses) {
    return (state.courses = courses.reduce(
      (acc, cur) => ({ ...acc, [cur.id]: cur }),
      {}
    ));
  },
  setCount(state, count) {
    return (state.count = count);
  },
  replaceCourse(state, course) {
    return (state.courses = {
      ...state.courses,
      [course.id]: course,
    });
  },
  setCourse(state, course) {
    state.course = course
  },
  addCourse(state, course) {
    state.count = state.count + 1;
    return (state.courses = {
      [course.id]: course,
      ...state.courses,
    });
  },
  removeCourse(state, id) {
    delete state.courses[id];
    state.courses = { ...state.courses };
  },
  setStatistic(state, statistic) {
    return state.statistic = statistic
  },
  setCountOfParticipant(state, count) {
    return state.countParticipant = count
  },
  setCoursesStatistics(state, statistics){
    return state.coursesStatistics = statistics
  },
  setTotalCountCourse(state, totalCount){
    return state.totalCount = totalCount
  }
}
const getters = {
  getCount: state => {
    return state.count
  },
  getCourses: state => {
    return Object.values(state.courses);
  },
  getCourse: state => {
    return state.course
  },
  getCountParticipant: (state) => {
    return state.countParticipant
  },
  getAllStatistic: (state) => {
    return state.statistic
  },  
  getCoursesStatistics: (state) => {
    return state.coursesStatistics
  }, 
  getStatistic: (state) => {
    if (state.statistic.length >= 4) {
      return state.statistic.slice(0, 4)
    } else return state.statistic
  },
  getTotalCountCourse: (state) => {
    return state.totalCount
  }
}
export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}