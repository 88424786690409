import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const KOLRepository = RepositoryFactory.get('kol')
import dayjs from 'dayjs'

const state = {
  kols: {},
  kol: {},
};

const actions = {
  async createKOL({ dispatch }, data) {
    const kol = await KOLRepository.create(data)
    dispatch("fetchKOls")
    return kol.data;
  },
  async fetchKOls({ commit }, params = {}) {
    const res = await KOLRepository.fetch({
      ...params,
    });
    res.data.sort((a, b) => {
      return dayjs(b.updatedAt).format('YYYYMMDDHHmmss') - dayjs(a.updatedAt).format('YYYYMMDDHHmmss')
    })
    commit("setKOLs", res.data || []);
    return res.data;
  },
  async fetchKOL({ commit }, id) {
    const res = await KOLRepository.fetchOne(id);
    commit("setKOL", res.data || []);
    return res.data;
  },
  async updateKOL({ commit }, { id, ...kol }) {
    const res = await KOLRepository.update(id, kol);
    return commit("setKOL", res.data);
  },
  async removeKOL({ commit }, item) {
    await KOLRepository.remove(item.id);
    return commit("removeKOL", item.id);
  },
};

const mutations = {
  setKOL(state, kol) {
    return (state.kol = kol);
  },
  setKOLs(state, kols) {
    return (state.kols = kols.reduce(
      (acc, cur) => ({ ...acc, [cur.id]: cur }),
      {}
    ));
  },
  removeKOL(state, id) {
    delete state.kols[id];
    state.kols = { ...state.kols };
  },
};

const getters = {
  kols: (state) => {
    return Object.values(state.kols);
  },
  kol: (state) => {
    return state.kol;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
